import React from "react";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./AddAdress.css";

const AddAdress = () => {
  return (
    <>
      <section className="fantacy-sidebar add-adress-sec">
        <div className="sidebar-header">
          <Link to="/fantacy-sport/myaccount">
            <FontAwesomeIcon
              icon="fa-solid fa-arrow-left"
              className="bar-icon"
            />
          </Link>
          <img
            className="headlogo"
            src={process.env.PUBLIC_URL + "/assets/images/logo/logo.png"}
            alt="Logo"
          />
          <Link to="/fantacy-sport/addcash">
            <button type="button" class="header_btn">
              <span class="px-1">Add Cash</span>{" "}
              <span className="plus-div">+</span>
            </button>
          </Link>
        </div>
        <div>
          <div className="heding">
            <h1>Play Responsibly!</h1>
            <p>
              To continue playing cash games, you need to provide Address
              details
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="col-xxl-10">
              <Form className="px-3">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control type="text" placeholder="name@example.com" />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control type="text" placeholder="name@example.com" />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Pincode</Form.Label>
                  <Form.Control type="text" placeholder="name@example.com" />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>City</Form.Label>
                  <Form.Control type="text" placeholder="name@example.com" />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Apprtment Name</Form.Label>
                  <Form.Control type="text" placeholder="name@example.com" />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>street</Form.Label>
                  <Form.Control type="text" placeholder="name@example.com" />
                </Form.Group>
              </Form>
              <button>Sumbit</button>
            </div>
          </div>

          <div className="need-help-sec">
          <span className="need-text">Need Help?</span>
          <img class="icon-img" src="/assets/images/fantacy/mail.png" alt="icon-img"/>
          <span className="mail">betzone@gmail.com</span>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddAdress;
