import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./App.css";
import "./index.css";

import { Route, Routes } from "react-router-dom";
import Header from "./Components/header/Header";
import Footer from "./Components/footer/Footer";
import Home from "./Components/home/Home";
import Login from "./Components/Login/Login";
import SignUp from "./Components/SignUp/SignUp";
import Game_Details from "./Components/common-components/Game_Details/Game_Details";
import AllTournament from "./Components/common-components/AllTournament/AllTournament";
import VirtualSport from "./Components/VirtualSport/VirtualSport";
import FirstFootabllgame from "./Components/VirtualSport/FirstFootabllgame/FirstFootabllgame";
import FantacySport from "./Components/FantacySport/FantacySport";
// import AddCash from "./Components/FantacySport/AddCash/AddCash";
import Contest from "./Components/FantacySport/Contest/Contest";
import PrivateContest from "./Components/FantacySport/ContestPrivate/PrivateContest/PrivateContest";
import ContestPrivate from "./Components/FantacySport/ContestPrivate/ContestPrivate";
import CreateNewTeam from "./Components/FantacySport/CreateNewTeam/CreateNewTeam";
import Inner_private_contest from "./Components/FantacySport/ContestPrivate/PrivateContest/Inner-private-contest/Inner_private_contest";
import AccountDetails from "./Components/FantacySport/AddCash/AccountDetails/AccountDetails";
import FantacySportmain from "./Components/FantacySport/FantacySportmain/FantacySportmain";
import ExchangeRoutesMain from "./Components/Exchange/ExchangeRoutesMain/ExchangeRoutesMain";
import ExchangeRoutesProfile from "./Components/Exchange/ExchangeRoutesMain/ExchangeRoutesProfile";
import JoinTeam from "./Components/FantacySport/CreateNewTeam/JoinTeam/JoinTeam";
import AppContext from "./utils/context"

function App() {
  const [siedebar, setSidebar] = useState(null);

  return (
    <>
      <AppContext>
        {/* <ScrollToTop /> */}
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/game-details/:id/:eventId" element={<Game_Details />} />
          <Route path="/tournament" element={<AllTournament />} />
          <Route path="/virtualsport" element={<VirtualSport />} />
          <Route path="/firstgame" element={<FirstFootabllgame />} />
          <Route path="/fantacy-sport/*" element={<FantacySportmain />} />
          <Route path="/exchange/*" element={<ExchangeRoutesMain />} />
          <Route path="/exchange-profile/*" element={<ExchangeRoutesProfile />} />
        </Routes>

        {/* <Footer /> */}
      </AppContext>
    </>
  );
}

export default App;
