import React, { useState } from "react";

import './exchangeprofile.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faCalendarDays } from "@fortawesome/free-solid-svg-icons";


const AccountStatement = () => {
  const [show, setShow] = React.useState(true);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  return (
    <>
      {/* start desktop view exchangeprofilemain-sec section  */}
      <section className='exchangeprofilemain-sec'>

        <div className='secttiill'>
          <div className='titlediv'>
            <p className='title'>Account Statement</p>
          </div>
        </div>

        <div className='tablemain-div'>
          <div className='row'>
            <div className='col-lg-3 mb-3'>
              <div className='formgroup'>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  name="deadline"
                  id="deadline"
                  autoComplete="off"
                  className="form-control"
                  placeholderText="Start Date"
                />
              </div>
            </div>

            <div className='col-lg-3 mb-3'>
              <div className='formgroup'>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  name="deadline"
                  id="deadline"
                  autoComplete="off"
                  className="form-control"
                  placeholderText="End Date"
                />
              </div>
            </div>

            <div className='col-lg-3 mb-3'>
              <div className='formgroup'>
                <Form.Select aria-label="Default select example">
                  <option>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </div>
            </div>

            <div className='col-lg-3 mb-3'>
              <div className='formgroup'>
                <button className="submitbtnn">Submit</button>
              </div>
            </div>
          </div>


          <div className="table-div">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Sr.No</th>
                    <th>Credit</th>
                    <th>Debit</th>
                    <th>Balance</th>
                    <th>Remark</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>Date</td>
                    <td>Sr.No</td>
                    <td>Credit</td>
                    <td>Debit</td>
                    <td>Balance</td>
                    <td>Remark</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>



        </div>

      </section>
      {/* end desktop view exchangeprofilemain-sec section  */}

      {/* start exchangeMobView_secion   */}
      <section className="exchangeMobView_secion">

        <div className="toptitlediv">
          <div className="d-flex justify-content-between ">
            <div className="my-auto"><h6 className="profttt"><Link to="/exchange-profile/profile"><FontAwesomeIcon className="backiccc" icon={faAngleLeft} /></Link>Account Statement</h6></div>

            <div className="my-auto">
              <Form.Select aria-label="Default select example">
                <option>All</option>
                <option value="1">Diposite/Withdraw Report</option>
                <option value="2">Game Report</option>
              </Form.Select>
            </div>

            <div className="my-auto"><FontAwesomeIcon className="calendericon" icon={faCalendarDays} /></div>
          </div>

        </div>

        <div className="contentdivv">
          <div className="formdiv">
            <div className="text-center mb-4">
              <h6>Pick Date</h6>
            </div>

            <Form>
              <div className='formgroup'>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  name="deadline"
                  id="deadline"
                  autoComplete="off"
                  className="form-control"
                  placeholderText="Start Date"
                />
              </div>
              <div className='formgroup'>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  name="deadline"
                  id="deadline"
                  autoComplete="off"
                  className="form-control"
                  placeholderText="End Date"
                />
              </div>
              <div className='formgroup text-center'>
                <button className="btn clearbtn">Clear</button>
                <button className="btn submitbtn">Submit</button>
              </div>

            </Form>
          </div>
        </div>

        <div className="statementlist-div">
            
        </div>


      </section>
      {/* end exchangeMobView_secion   */}
    </>
  )
}

export default AccountStatement
