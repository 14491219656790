import Offcanvas from 'react-bootstrap/Offcanvas';
import "./Includedesposit.css"
const Includedesposit = (props) => {

    return (
        <>
            <Offcanvas className="Includedesposit" show={props.show} onHide={props.handleClose} {...props}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>How GST on deposit works?</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='canvasMaincontent'>
                        <div className='canvasCon'>
                            <p><img src={process.env.PUBLIC_URL + "/assets/images/Game_details/infoCircle.png"} className='infocircle' />Starting 01 Oct 2023, 28% GST is being charged by Government on your deposits.</p>
                            <p><img src={process.env.PUBLIC_URL + "/assets/images/Game_details/thumbGreenNew.png"} className='thumbmark' />Promo Cash offer applied!</p>
                        </div>

                        <div className='gstcollection'>
                            <h3>GST Calculation</h3>

                            <div className='contenttwo'>
                                <h2>You Pay</h2>
                                <p>₹100</p>
                                <p>Deposit + GST</p>
                            </div>

                            <div className='boundaryline'></div>
                        </div>

                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default Includedesposit