import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import '../../../FantacySport/Fantacysidebar/Fantacysidebar.css';
import './AddCashSidebar.css';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { addcashdata } from '../../../../utils/apis/common/Common';
import { postData } from '../../../../utils/api'; // Assuming you have a postData function for making API calls
import Includedesposit from '../../../common-components/Modals/Includedesposit/Includedesposit';

library.add(fas);

const cashdata = [
    { eventKey: "link-1", amount: '500', bonus: '₹500' },
    { eventKey: "link-2", amount: '1000', bonus: '₹500' },
    { eventKey: "link-3", amount: '1500', bonus: '₹1000' },
    { eventKey: "link-4", amount: '2000', bonus: '₹1000' },
];

const handlePayment = (amount) => {
    const amountInPaise = amount * 100;
    const options = {
        key: 'rzp_test_zQvL5MqIjnAKdi',
        amount: amountInPaise,
        currency: 'INR',
        name: 'BetZone',
        description: 'Test Transaction',
        image: 'https://your-logo-url.com/logo.png',
        handler: async function (response) {
            console.log(response, "paymentData");
            const paymentDetails = {
                transaction_type: 'UPI',
                transaction_number: response.razorpay_payment_id,
                amount: amount,
            };

            try {
                const res = await postData('/wallet', paymentDetails);
                if (res.success) {
                    console.log('Payment successful:', res.message);
                    window.location = "/";
                } else {
                    console.error('Payment failed:', res.message);
                }
            } catch (error) {
                console.error('Error while saving payment details:', error);
            }
        },
        prefill: {
            name: 'Saurav Khambayate',
            email: 'sauravkhambayate@gmail.com',
            contact: '7676767676',
        },
        notes: {
            address: 'Your Address',
        },
        theme: {
            color: '#F37254',
        },
    };

    console.log("amount", options.amount);
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
    localStorage.setItem("amount", amountInPaise);
    console.log(localStorage.getItem("amount"), "dsdjsds")
};

const AddCashSidebar = () => {
    const [selectedAmount, setSelectedAmount] = useState(500);
    const [selectedBonus, setSelectedBonus] = useState('₹500');
    const [modalShow, setModalShow] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleNavClick = (amount, bonus) => {
        setSelectedAmount(amount);
        setSelectedBonus(bonus);
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        const res = await addcashdata(data);
        if (res?.success) {
            setTimeout(() => {
                window.location = "/";
            }, 2000);
        } else {
            console.log(res?.message);
        }
    };

    return (
        <section className='fantacy-sidebar add-btn-sec'>
            <div className='sidebar-header'>
                <Link to="/fantacy-sport/fantacytab">
                    <FontAwesomeIcon icon="arrow-left" className='bar-icon' />
                </Link>
                <img className='headlogo' src={process.env.PUBLIC_URL + '/assets/images/logo/logo.png'} alt="Logo" />
                <button type="button" className="header_btn">
                    <span className="px-1">Add Cash</span> <span className='plus-div'>+</span>
                </button>
            </div>
            <div className='main-bonus-card'>
                <Nav defaultActiveKey="link-1" as="ul">
                    {cashdata.map((link, index) => (
                        <Nav.Item as="li" key={index}>
                            <Nav.Link eventKey={link.eventKey} onClick={() => handleNavClick(link.amount, link.bonus)}>
                                <p className='title'>₹{link.amount}</p>
                                <p className='sub-title'>{link.bonus}</p>
                            </Nav.Link>
                            {link.eventKey === "link-1" && (
                                <div className='overlay-text'>
                                    <button className='pick-btn'>Top Pick</button>
                                </div>
                            )}
                        </Nav.Item>
                    ))}
                </Nav>

                <Form.Group controlId="validationCustomUsername" className='px-2'>
                    <Form.Label>Enter Amount</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type="number"
                            placeholder="select"
                            value={selectedAmount}
                            onChange={(e) => setSelectedAmount(e.target.value)}
                        />
                        <InputGroup.Text id="inputGroupPrepend">(₹25 to ₹10000)</InputGroup.Text>
                    </InputGroup>
                </Form.Group>
                
                <div className='form-bottom-div px-2'>
                    <p className='bonus-text'>Free Bonus {selectedBonus}</p>
                    <p className='include-text' onClick={handleShow}>Includes Deposit & GST</p>
                </div>

                <Includedesposit show={show} handleClose={handleClose} handleShow={handleShow} placement="bottom" name="bottom" />
                <div className='btn-div text-center'>
                    <button type="button" className='next-btn' onClick={() => handlePayment(selectedAmount)}>NEXT</button>
                </div>
                <div className='secure-div'>
                    <img className='headlogo me-2' src={process.env.PUBLIC_URL + '/assets/images/fantacy/secure.png'} alt="Logo" />
                    <p>100% SECURE</p>
                </div>
            </div>
            <div className='payment-method'>
                <div className='payment-img-div'>
                    <img src={process.env.PUBLIC_URL + '/assets/images/fantacy/paytm.png'} alt="Paytm" />
                    <img src={process.env.PUBLIC_URL + '/assets/images/fantacy/visa.png'} alt="Visa" />
                    <img src={process.env.PUBLIC_URL + '/assets/images/fantacy/mastercard.png'} alt="Mastercard" />
                </div>
                <div className='hfgvvfgvfer-bg'>
                    <div className='bottom-text'>
                        <img className='alert-img' src={process.env.PUBLIC_URL + '/assets/images/fantacy/alert.png'} alt="Alert" />
                        <p>You must be 18+ to play.</p>
                    </div>
                    <p>Andhra Pradesh, Telangana, Sikkim, Nagaland, and Assam players are not allowed to play cash games.</p>
                </div>
            </div>
        </section>
    );
};

export default AddCashSidebar;
