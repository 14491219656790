
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './PlayModal.css'
import { NavLink } from 'react-router-dom';

const PlayModal = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='play-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='button-div'>
                        <NavLink to={props.newPlayLink}>
                            <button className='demo-btn' onClick={props.onHide}>
                                Demo
                            </button>
                        </NavLink>
                        <NavLink to={props.newPlayLink}>
                            <button className='play-btn' onClick={props.onHide}>
                                New Play
                            </button>
                        </NavLink>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PlayModal;
