import React from "react";
import "./Game_Details_Footer.css";
import Footer_Link_Content from "./Footer-Link-Content/Footer_Link_Content";
import Game_Partners_Content from "./Game_Partners_Content/Game_Partners_Content";
import Payment_Methods from "./Payment_Methods/Payment_Methods";
import Footer_Social from "./Footer_Social/Footer_Social";

function Game_Details_Footer() {
  return (
    <>
      <div className="Game_Details_Footer">
        <div className="white-bg">
          <div className="footer-content">
            <Footer_Link_Content />
            <Game_Partners_Content />
            <Payment_Methods />
            <Footer_Social/>
          </div>
        </div>
      </div>
    </>
  );
}

export default Game_Details_Footer;
