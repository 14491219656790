import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link } from "react-router-dom";
import Countdown from "react-countdown";
import { Button, Offcanvas, Table } from "react-bootstrap";
import A_vs_B from "../../common-components/A_vs_B/A_vs_B";
import Nav from "react-bootstrap/Nav";

import Tab from "react-bootstrap/Tab";


import "./PriceBreakup.css";
const PriceBreakup = () => {
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return;
    } else {
      // Render a countdown
      return (
        <span className="timings">
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <section className="pricebrkup-sec">
        <div className="fantacy-sidebar">
          <div className="sidebar-header">
            <Link to="/fantacy-sport/contest">
              <FontAwesomeIcon
                icon="fa-solid fa-arrow-left"
                className="bar-icon"
              />
            </Link>

            <div className="settimeout">
              <p>Contest</p>
              <Countdown date={Date.now() + 500000} renderer={renderer} />
            </div>

            <div className="">
              <Link to="/fantacy-sport/addcash">
                <button type="button" class="header_btn">
                  <span class="px-1">Add Cash</span>{" "}
                  <span className="plus-div">+</span>
                </button>
              </Link>
            </div>
          </div>
          <A_vs_B />

          <div>
            <div className="contestcardmain">
              <div className="constfill">
                Hot Contest
              </div>
              <div className="costprice">
                <p className="pricecost">$ 51,000</p>
                <p>$ 25,000</p>
              </div>
              <div className="joinbuttons">
                <div className="inner-btn-div">
                  <div className="d-flex-div">
                    <img
                      class="teamss"
                      src="/assets/images/fantacy/first.png"
                      alt="Logo"
                    />
                    <p>2,000</p>
                  </div>
                  <div className="winnerss" onClick={handleShow}>
                    <p>
                      Winners : 270{" "}
                      <FontAwesomeIcon
                        icon="fa-solid fa-chevron-down"
                        className="winnergreticn"
                      />
                    </p>
                  </div>
                </div>
                <Link to={"/fantacy-sport/createnewteam"}>
                  <button className="join_btns">Join</button>
                </Link>
              </div>
              <div className="teamjoined mb-3">
                <p>Teams Joined: 7/50</p>
              </div>
              <ProgressBar now={60} />

              <div className="d-flex mt-4">
                <div className="m-letter">M</div>
                <p className="mx-2">You can join this contest with 20 teams</p>
              </div>
            </div>
            <div>
                <Offcanvas
                    show={show}
                    onHide={handleClose}
                    placement="bottom"
                    className="winnersoffacanvas"
                >
                    <Offcanvas.Header className="offcanheaders" closeButton>
                        <Offcanvas.Title className="winningbreakup">
                            Winnings Prize Break-up
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div>
                            <div className="tableheadmain">
                                <div className="tableheads">
                                    <p className="tabletexthead mb-1">Total Winnings</p>
                                    <p className="tableprizs mb-0">$11.04 Lakhs</p>
                                </div>
                                <div className="tableheads">
                                    <p className="tabletexthead  mb-1">Entry Fee</p>
                                    <p className="tableprizs mb-0">$49</p>
                                </div>
                                <div className="tableheads">
                                    <p className="tabletexthead mb-1">Contest Size</p>
                                    <p className="tableprizs mb-0">31,567</p>
                                </div>
                            </div>
                            <Table striped bordered className="offcantable">
                                <tr>
                                    <td className="">Rank</td>
                                    <td>Prize</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>6-7</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>8-10</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>11-15</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>16-20</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>21-30</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>31-40</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>41-50</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>51-75</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>76-125</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>126-200</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>201-301</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>302-503</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>504-1570</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>1571-19460</td>
                                    <td>- $150</td>
                                </tr>
                            </Table>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>

            <div className="tab-container">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="first">
                      <img
                        className="nav-img"
                        src="/assets/images/fantacy/cup.png"
                        alt="Logo"
                      />
                      PRIZE BREAKUP
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">
                      <img
                        className="nav-img"
                        src="/assets/images/fantacy/board.png"
                        alt="Logo"
                      />
                      LEADERBOARD
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="top-row">
                      <div className="row">
                        <div className="col-xxl-5 col-5 text-end">
                          <p>RANK</p>
                        </div>
                        <div className="col-xxl-1 col-1 text-center"></div>
                        <div className="col-xxl-5 col-5">
                          <p>PRIZES</p>
                        </div>
                      </div>
                    </div>
                    <div className="first-row">
                      <div className="row">
                        <div className="col-xxl-5 col-5 text-end">
                          <p>80</p>
                        </div>
                        <div className="col-xxl-1 col-1 text-center">-</div>
                        <div className="col-xxl-5 col-5">
                          <p>78,000</p>
                        </div>
                      </div>
                    </div>
                    <div className="first-row">
                      <div className="row">
                        <div className="col-xxl-5 col-5 text-end">
                          <p>80</p>
                        </div>
                        <div className="col-xxl-1 col-1 text-center">-</div>
                        <div className="col-xxl-5 col-5">
                          <p>786</p>
                        </div>
                      </div>
                    </div>
                    <div className="first-row">
                      <div className="row">
                        <div className="col-xxl-5 col-5 text-end">
                          <p>80</p>
                        </div>
                        <div className="col-xxl-1 col-1 text-center">-</div>
                        <div className="col-xxl-5 col-5">
                          <p>78,000</p>
                        </div>
                      </div>
                    </div>
                    <div className="first-row">
                      <div className="row">
                        <div className="col-xxl-5 col-5 text-end">
                          <p>80</p>
                        </div>
                        <div className="col-xxl-1 col-1 text-center">-</div>
                        <div className="col-xxl-5 col-5">
                          <p>786</p>
                        </div>
                      </div>
                    </div>
                    <div className="note">
                      <p>
                        {" "}
                        <b>Note:</b> In case of a tie or if the contest does not
                        fill up, the actual prizes will be different. As per the
                        government regulations, a tax of 30% will be deducted if
                        net winnings of an individual is more than Rs. 10000.
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div className="sec-tab">
                      <div className="top-row">
                        <div className="row">
                          <div className="col-xxl-4 col-4 ">
                            <p>RANK</p>
                          </div>

                          <div className="col-xxl-8 col-8">
                            <p>PRIZES</p>
                          </div>
                        </div>
                      </div>
                      <div className="first-row">
                        <div className="row">
                          <div className="col-xxl-4 col-4 ">
                            <p>80</p>
                          </div>

                          <div className="col-xxl-8 col-8">
                            <p>alpha8972ga5(T1)</p>
                          </div>
                        </div>
                      </div>
                      <div className="first-row">
                        <div className="row">
                          <div className="col-xxl-4 col-4 ">
                            <p>80</p>
                          </div>

                          <div className="col-xxl-8 col-8">
                            <p>alpha8972ga5(T1)</p>
                          </div>
                        </div>
                      </div>
                      <div className="first-row">
                        <div className="row">
                          <div className="col-xxl-4 col-4 ">
                            <p>80</p>
                          </div>

                          <div className="col-xxl-8 col-8">
                            <p>alpha8972ga5(T1)</p>
                          </div>
                        </div>
                      </div>
                      <div className="first-row">
                        <div className="row">
                          <div className="col-xxl-4 col-4 ">
                            <p>80</p>
                          </div>

                          <div className="col-xxl-8 col-8">
                            <p>alpha8972ga5(T1)</p>
                          </div>
                        </div>
                      </div>
                      <div className="first-row">
                        <div className="row">
                          <div className="col-xxl-4 col-4 ">
                            <p>80</p>
                          </div>

                          <div className="col-xxl-8 col-8">
                            <p>alpha8972ga5(T1)</p>
                          </div>
                        </div>
                      </div>
                      <div className="first-row">
                        <div className="row">
                          <div className="col-xxl-4 col-4 ">
                            <p>80</p>
                          </div>

                          <div className="col-xxl-8 col-8">
                            <p>alpha8972ga5(T1)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PriceBreakup;
