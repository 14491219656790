import React, {useState} from "react";
import {Container} from "react-bootstrap";
import {Pagination, Navigation} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStopwatch20} from "@fortawesome/free-solid-svg-icons";
import ProgressBar from "react-bootstrap/ProgressBar";
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from "recharts";
const GraphTab = () => {
    const leftPercentage = 100;
    const rightPercentage = 63;

    // timer
    const [time, setTime] = React.useState(32);

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            setTime((prevTime) => {
                if (prevTime <= 0) {
                    clearInterval(intervalId);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const formattedTime = `0:${time.toString().padStart(2, "0")}`;

    const data = [
        {
            uv: 20,
            pv: 10,
            amt: 2210,
        },
    ];

    return (
        <>
            <section className="GraphTab">
                <Swiper
                    pagination={{
                        type: "fraction",
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <p className="card-no main">STATISTICS</p>
                        <div className="cards-section">
                            <div className="main-card">
                                <p className="card-no">0</p>
                                <img
                                    src={process.env.PUBLIC_URL + "/assets/images/exchange/livecenter/cards.png"}
                                    className="logo"
                                    alt="Component 3"
                                />
                                <p className="card-no">0</p>
                            </div>
                            <div className="main-card-two">
                                <p className="card-no main">CARDS</p>
                            </div>
                            <div className="main-card">
                                <p className="card-no">0</p>
                                <img
                                    src={process.env.PUBLIC_URL + "/assets/images/exchange/livecenter/cards.png"}
                                    className="logo"
                                    alt="Component 3"
                                />
                                <p className="card-no">0</p>
                            </div>
                        </div>
                        <div className="range-bar">
                            <p className="kicks">CORNER KICKS</p>
                            <div className="range-bar-container">
                                <div className="percentage-text">{leftPercentage}%</div>
                                <div className="bar-container">
                                    <div
                                        className="bar left-bar"
                                        style={{width: `${leftPercentage}%`, marginRight: "4px"}}
                                    />
                                    <div className="bar right-bar" style={{width: `${rightPercentage}%`}} />
                                </div>
                                <div className="percentage-text">{rightPercentage}%</div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <p className="card-no main">STATISTICS</p>
                        <div className="range-bar">
                            <p className="kicks">TIME IN DANGEROUS ATTACK</p>
                            <div className="range-bar-container">
                                <div className="percentage-text">{leftPercentage}%</div>
                                <div className="bar-container">
                                    <div
                                        className="bar left-bar"
                                        style={{width: `${leftPercentage}%`, marginRight: "4px"}}
                                    />
                                    <div className="bar right-bar" style={{width: `${rightPercentage}%`}} />
                                </div>
                                <div className="percentage-text">{rightPercentage}%</div>
                            </div>
                            <div className="timer-main">
                                <div className="timer">
                                    <span className="stop-watch">
                                        <FontAwesomeIcon icon={faStopwatch20} className="icon-watch me-1" />
                                        <div className="watch">{formattedTime}</div>
                                    </span>
                                    <p className="kicks watch">AVG MIN / ATTACK</p>
                                </div>
                                <div className="main">
                                    <div className="graph-range">
                                        <p className="kick">22</p>
                                        <BarChart
                                            width={70}
                                            height={80}
                                            data={data}
                                            margin={{
                                                top: 20,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                            }}
                                        >
                                            <XAxis dataKey="name" />

                                            <Tooltip />
                                            <Legend />
                                            <Bar dataKey="pv" fill="#8884d8" />
                                            <Bar dataKey="uv" fill="#82ca9d" />
                                        </BarChart>
                                        <p className="kick">50</p>
                                    </div>
                                    <p className="kick range-main">DANGEROUS ATTACK</p>
                                </div>

                                <div className="timer two">
                                    <span className="stop-watch">
                                        <FontAwesomeIcon icon={faStopwatch20} className="icon-watch me-1" />
                                        <div className="watch">{formattedTime}</div>
                                    </span>
                                    <p className="kicks watch">AVG MIN / ATTACK</p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <p className="card-no main">STATISTICS</p>
                        <div className="range-bar">
                            <p className="kicks">SUBSTITUTIONS</p>
                            <div className="range-bar-container">
                                <div className="percentage-text">{leftPercentage}</div>
                                <div className="bar-container">
                                    <div
                                        className="bar left-bar"
                                        style={{width: `${leftPercentage}%`, marginRight: "4px"}}
                                    />
                                    <div className="bar right-bar" style={{width: `${rightPercentage}%`}} />
                                </div>
                                <div className="percentage-text">{rightPercentage}</div>
                            </div>
                            <div className="third-range">
                                <p className="kicks">INJURIES</p>
                                <div className="range-bar-container">
                                    <div className="percentage-text">{leftPercentage}</div>
                                    <div className="bar-container">
                                        <div
                                            className="bar left-bar"
                                            style={{width: `${leftPercentage}%`, marginRight: "4px"}}
                                        />
                                        {/* <div className="bar right-bar" style={{width: `${rightPercentage}%`}} /> */}
                                    </div>
                                    <div className="percentage-text">{rightPercentage}</div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </section>
        </>
    );
};

export default GraphTab;
