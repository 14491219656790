import React, {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./ScoreCard.css";
import A_vs_B from "../../../common-components/A_vs_B/A_vs_B";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Accordion from "react-bootstrap/Accordion";
import InfoCanvas from "../../FantacyOfcanvas/InfoCanvas/InfoCanvas";

const ScoreCard = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => {
        setShow(true);
      };

  return (
    <>
      <section className="fantacy-sidebar score-card">
        <div className="sidebar-header">
          <Link to="/fantacy-sport/completed-contest">
            <FontAwesomeIcon
              icon="fa-solid fa-arrow-left"
              className="bar-icon"
            />
          </Link>
          <div className="text-center">
            <p>Scorecard</p>
          </div>
          <Link to="/fantacy-sport/addcash">
            <button type="button" class="header_btn">
              <span class="px-1">Add Cash</span>
              <span className="plus-div">+</span>
            </button>
          </Link>
        </div>
        <A_vs_B />
        <div className="scoree-bord">
          <div className="text-center">
            <p className="ft-text">FT</p>
          </div>
          <div className="row justify-content-center">
            <div className="col-xxl-5 col-xl-5 col-5 py-2">
              <div className="flex-div">
                <p>75'</p>
                <img
                  class="teamss"
                  src="/assets/images/fantacy/yellowcard.png"
                  alt="Logo"
                />
                <div>
                  <h6>Carlos Baleba</h6>
                  <p>Yellow Card</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-5 col-5 dashd-border py-2">
              <div className="flex-div">
                <p>75'</p>
                <img
                  class="teamss"
                  src="/assets/images/fantacy/arrows.png"
                  alt="Logo"
                />
                <div>
                  <h6>Carlos Baleba</h6>
                  <p>Yellow Card</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xxl-5 col-xl-5 col-5  py-2">
              <div className="flex-div">
                <p>75'</p>
                <img
                  class="teamss"
                  src="/assets/images/fantacy/yellowcard.png"
                  alt="Logo"
                />
                <div>
                  <h6>Carlos Baleba</h6>
                  <p>Yellow Card</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-5 col-5 py-2 dashd-border">
              <div className="flex-div">
                <p>75'</p>
                <img
                  class="teamss"
                  src="/assets/images/fantacy/arrows.png"
                  alt="Logo"
                />
                <div>
                  <h6>Carlos Baleba</h6>
                  <p>Yellow Card</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xxl-5 col-xl-5 col-5 py-2">
              <div className="flex-div">
                <p>75'</p>
                <img
                  class="teamss"
                  src="/assets/images/fantacy/yellowcard.png"
                  alt="Logo"
                />
                <div>
                  <h6>Carlos Baleba</h6>
                  <p>Yellow Card</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-5 col-5 dashd-border py-2">
              <div className="flex-div">
                <p>75'</p>
                <img
                  class="teamss"
                  src="/assets/images/fantacy/arrows.png"
                  alt="Logo"
                />
                <div>
                  <h6>Carlos Baleba</h6>
                  <p>Yellow Card</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xxl-5 col-xl-5 col-5 py-2">
              <div className="flex-div">
                <p>75'</p>
                <img
                  class="teamss"
                  src="/assets/images/fantacy/yellowcard.png"
                  alt="Logo"
                />
                <div>
                  <h6>Carlos Baleba</h6>
                  <p>Yellow Card</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-5 col-5 dashd-border py-2">
              <div className="flex-div">
                <p>75'</p>
                <img
                  class="teamss"
                  src="/assets/images/fantacy/arrows.png"
                  alt="Logo"
                />
                <div>
                  <h6>Carlos Baleba</h6>
                  <p>Yellow Card</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tabs-container">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Nav variant="pills" className="">
              <Nav.Item>
                <Nav.Link eventKey="first">BHA</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">MCI</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="first-row">
                  <div class="row">
                    <div class="col-3">
                      <p>Pos.</p>
                    </div>
                    <div class="col-4">
                      <p>Starters</p>
                    </div>
                    <div class="col-1">
                      <p>G</p>
                    </div>
                    <div class="col-1">
                      <p>A</p>
                    </div>
                    <div class="col-1">
                      <p>CS</p>
                    </div>
                    <div class="col-1">
                      <p>Pts</p>
                    </div>
                    <div class="col-1">
                      <p></p>
                    </div>
                  </div>
                </div>
                <div className="sec-row">
                  <div class="row">
                    <div class="col-3">
                      <p>GK</p>
                    </div>
                    <div class="col-4">
                      <p>Ederson</p>
                    </div>
                    <div class="col-1">
                      <p>0</p>
                    </div>
                    <div class="col-1">
                      <p>0</p>
                    </div>
                    <div class="col-1">
                      <p>1</p>
                    </div>
                    <div class="col-1">
                      <p>50</p>
                    </div>
                    <div class="col-1">
                      <p onClick={handleShow}>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-exclamation"
                          className="icon"
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="sec-row">
                  <div class="row">
                    <div class="col-3">
                      <p>GK</p>
                    </div>
                    <div class="col-4">
                      <p>Ederson</p>
                    </div>
                    <div class="col-1">
                      <p>0</p>
                    </div>
                    <div class="col-1">
                      <p>0</p>
                    </div>
                    <div class="col-1">
                      <p>1</p>
                    </div>
                    <div class="col-1">
                      <p>50</p>
                    </div>
                    <div class="col-1">
                      <p>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-exclamation"
                          className="icon"
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="sec-row">
                  <div class="row">
                    <div class="col-3">
                      <p>GK</p>
                    </div>
                    <div class="col-4">
                      <p>Ederson</p>
                    </div>
                    <div class="col-1">
                      <p>0</p>
                    </div>
                    <div class="col-1">
                      <p>0</p>
                    </div>
                    <div class="col-1">
                      <p>1</p>
                    </div>
                    <div class="col-1">
                      <p>50</p>
                    </div>
                    <div class="col-1">
                      <p>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-exclamation"
                          className="icon"
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
              <div className="first-row">
                  <div class="row">
                    <div class="col-3">
                      <p>Pos.</p>
                    </div>
                    <div class="col-4">
                      <p>Starters</p>
                    </div>
                    <div class="col-1">
                      <p>G</p>
                    </div>
                    <div class="col-1">
                      <p>A</p>
                    </div>
                    <div class="col-1">
                      <p>CS</p>
                    </div>
                    <div class="col-1">
                      <p>Pts</p>
                    </div>
                    <div class="col-1">
                      <p></p>
                    </div>
                  </div>
                </div>
                <div className="sec-row">
                  <div class="row">
                    <div class="col-3">
                      <p>GK</p>
                    </div>
                    <div class="col-4">
                      <p>Ederson</p>
                    </div>
                    <div class="col-1">
                      <p>0</p>
                    </div>
                    <div class="col-1">
                      <p>0</p>
                    </div>
                    <div class="col-1">
                      <p>1</p>
                    </div>
                    <div class="col-1">
                      <p>50</p>
                    </div>
                    <div class="col-1">
                      <p>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-exclamation"
                          className="icon"
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="sec-row">
                  <div class="row">
                    <div class="col-3">
                      <p>GK</p>
                    </div>
                    <div class="col-4">
                      <p>Ederson</p>
                    </div>
                    <div class="col-1">
                      <p>0</p>
                    </div>
                    <div class="col-1">
                      <p>0</p>
                    </div>
                    <div class="col-1">
                      <p>1</p>
                    </div>
                    <div class="col-1">
                      <p>50</p>
                    </div>
                    <div class="col-1">
                      <p>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-exclamation"
                          className="icon"
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="sec-row">
                  <div class="row">
                    <div class="col-3">
                      <p>GK</p>
                    </div>
                    <div class="col-4">
                      <p>Ederson</p>
                    </div>
                    <div class="col-1">
                      <p>0</p>
                    </div>
                    <div class="col-1">
                      <p>0</p>
                    </div>
                    <div class="col-1">
                      <p>1</p>
                    </div>
                    <div class="col-1">
                      <p>50</p>
                    </div>
                    <div class="col-1">
                      <p>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-exclamation"
                          className="icon"
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>


          <InfoCanvas show={show} placement="bottom" name="start" setShow={setShow} />
        </div>
      </section>
    </>
  );
};

export default ScoreCard;
