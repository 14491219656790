import React, { useState, useEffect, useContext } from "react";
import './sidebar.css'
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { NavLink } from 'react-router-dom';
import { Context } from "../../../utils/context";
import { getLeagues, getTodayMatch } from "../../../utils/apis/common/Common";
library.add(fas);

const Sidebar = ({ uniLeagues, setSelectedCompetitionId }) => {
  console.log(uniLeagues, "huuhu");


  const { getGameData } = useContext(Context);

  const [competitions, setCompetion] = useState([]);
  const getCompetitions = async () => {
    const res = await getGameData('getSports');
    setCompetion(res);
  }

  useEffect(() => {
    getCompetitions();
  }, []);
  return (
    <>
      <section className='sidebarrrrr-sec'>
        <div className='first-div'>
          <NavLink >
            <div className='event-name'>
              <img className='event-img' src={process.env.PUBLIC_URL + '/assets/images/home/sidebar/fire.png'} alt="Logo" />
              <p>All live</p>
            </div>
          </NavLink>
          <NavLink to="/tournament">
            <div className='event-name'>
              <img className='event-img' src={process.env.PUBLIC_URL + '/assets/images/home/sidebar/football.png'} alt="Logo" />
              <p>FootBall</p>
            </div>
          </NavLink>
          {competitions?.map((value, index) => (
            value?.name !== 'Football' && (
              <NavLink >
                <div className='event-name'>
                  <img className='event-img' src={process.env.PUBLIC_URL + '/assets/images/home/sidebar/fire.png'} alt="Logo" />
                  <p>{value?.name}</p>
                </div>
              </NavLink>
            )

          ))}



        </div>
        <div>
          <h4>FOOTBALL · POPULAR</h4>
          <div className='main-div'>
            {uniLeagues?.map(item => (
              item?.name === 'Football' && (
                item?.children?.map((child) => (
                  <NavLink key={item?.competition?.id} onClick={() => setSelectedCompetitionId(item?.competition?.id)}>
                    <div className='flags-div'>

                      {/* <img className='event-img' src={item?.league?.country_flag} alt="Logo" /> */}
                      <div className='title-icon-div'>
                        <div className="countrytxt">
                          {/* <p className="Countryname">{child?.name}</p> */}
                          {/* <p className='sub-title'>{child?.name}</p> */}
                          <p className='title'>{child?.name}</p>
                        </div>
                        <div>
                          <FontAwesomeIcon icon="fa-solid fa-chevron-right" className='right-icon' />
                        </div>
                      </div>
                    </div>
                  </NavLink>
                ))
              )
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Sidebar