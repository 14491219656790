import React from 'react'
import './ContestCards.css'
import A_vs_B from '../../../../common-components/A_vs_B/A_vs_B'
import ContestJoinMain from '../../../../common-components/ContestJoinMain/ContestJoinMain'
import Add_cash from '../add-cash/Add_cash'
function ContestCards() {
  return (
    <>
      <A_vs_B />
      <ContestJoinMain />
    </>
  )
}

export default ContestCards