import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./PlayGround.css";
const PlayGround = (props) => {
  return (
    <>
      <Modal
        {...props}
        className="ground-model"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Ultimate Team 745 Points
          </Modal.Title>
          <FontAwesomeIcon
            icon="fa-solid fa-circle-xmark"
            className="close-btn"
            onClick={props.onHide}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="blocks">
            <div className="team-name">
              Goalkeeper
            </div>
            <div className="flex-div">
              <div className="main-teams">
                <div className="img-div">
                  <img
                    class="shirt"
                    src="/assets/images/fantacy/tshiry.png"
                    alt="Logo"
                  />
                  <div className="pills">Ederson</div>
                </div>
                <p>50ps</p>
              </div>
              <div className="main-teams">
                <div className="img-div">
                  <img
                    class="shirt"
                    src="/assets/images/fantacy/tshiry.png"
                    alt="Logo"
                  />
                  <div className="pills">Ederson</div>
                </div>
                <p>50ps</p>
              </div>
            </div>
          </div>
          <div className="blocks">
            <div className="team-name">
              Defenders
            </div>
            <div className="flex-div">
              <div className="main-teams">
                <div className="img-div">
                  <img
                    class="shirt"
                    src="/assets/images/fantacy/tshiry.png"
                    alt="Logo"
                  />
                  <div className="pills">Ederson</div>
                </div>
                <p>50ps</p>
              </div>
              <div className="main-teams">
                <div className="img-div">
                  <img
                    class="shirt"
                    src="/assets/images/fantacy/tshiry.png"
                    alt="Logo"
                  />
                  <div className="pills">Ederson</div>
                </div>
                <p>50ps</p>
              </div>
              <div className="main-teams">
                <div className="img-div">
                  <img
                    class="shirt"
                    src="/assets/images/fantacy/tshiry.png"
                    alt="Logo"
                  />
                  <div className="pills">Ederson</div>
                </div>
                <p>50ps</p>
              </div>
            </div>
          </div>

          <div className="blocks">
            <div className="team-name">
              Defenders
            </div>
            <div className="flex-div">
              <div className="main-teams">
                <div className="img-div">
                  <img
                    class="shirt"
                    src="/assets/images/fantacy/tshiry.png"
                    alt="Logo"
                  />
                  <div className="pills">Ederson</div>
                </div>
                <p>50ps</p>
              </div>
              <div className="main-teams">
                <div className="img-div">
                  <img
                    class="shirt"
                    src="/assets/images/fantacy/tshiry.png"
                    alt="Logo"
                  />
                  <div className="pills">Ederson</div>
                </div>
                <p>50ps</p>
              </div>
              <div className="main-teams">
                <div className="img-div">
                  <img
                    class="shirt"
                    src="/assets/images/fantacy/tshiry.png"
                    alt="Logo"
                  />
                  <div className="pills">Ederson</div>
                </div>
                <p>50ps</p>
              </div>
              <div className="main-teams">
                <div className="img-div">
                  <img
                    class="shirt"
                    src="/assets/images/fantacy/tshiry.png"
                    alt="Logo"
                  />
                  <div className="pills">Ederson</div>
                </div>
                <p>50ps</p>
              </div>
              <div className="main-teams">
                <div className="img-div">
                  <img
                    class="shirt"
                    src="/assets/images/fantacy/tshiry.png"
                    alt="Logo"
                  />
                  <div className="pills">Ederson</div>
                </div>
                <p>50ps</p>
              </div>
            </div>
          </div>
        </Modal.Body>
       
      </Modal>
    </>
  );
};

export default PlayGround;
