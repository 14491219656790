import React from 'react'
import './ExchangeRoutesMain.css'
import ExchangeRoutes from '../ExchangeRoutes/ExchangeRoutes'
import ExchangeSidebar from '../ExchangeSidebar/ExchangeSidebar'
import ExchangeBetPlaceSidebar from '../ExchangeSidebar/ExchangeBetPlaceSidebar'
import { Link } from 'react-router-dom'
import Exchangemobmenudiv from './Exchangemobmenudiv'

const ExchangeRoutesMain = () => {
  return (
    <>
      <section className="ex-home-main-section">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-6 p-0 order-2 order-md-1 exchnagefirstbar">
              <div className="ex-sidebar">
                <ExchangeSidebar />
              </div>
            </div>

            <div className="col-xxl-7 col-xl-7 col-lg-7  col-md-12 col-sm-12 order-1 order-md-2 p-0 ">
              <div className="ex-mid-div">
                <ExchangeRoutes />
              </div>

              <Exchangemobmenudiv />

            </div>

            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3  col-sm-6 p-0 order-3 order-md-3 exchnagelastbar">
              <div className="ex-last-div">
                <ExchangeBetPlaceSidebar />
              </div>
            </div>
          </div>

        </div>
      </section >

    </>
  )
}

export default ExchangeRoutesMain