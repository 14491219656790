import React, {useEffect, useRef, useState} from "react";
import "./exchangebetplacesidebar.css";
import {Col, Form, Row} from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFutbol, faTrash} from "@fortawesome/free-solid-svg-icons";
import Tab from "react-bootstrap/Tab";
import GraphTab from "./GraphTab";
import Probability from "./Probability";
import VoiceTab from "./VoiceTab";
import Accordion from "react-bootstrap/Accordion";
import IncreamentDecreament from "../../common-components/IncreamentDecreament/IncreamentDecreament";
import Button from "react-bootstrap/Button";
const ExchangeBetPlaceSidebar = () => {
    const items = [
        {key: "FC Midtjylland", label: "FK Rigas Futbola Skola"},
        {key: "Another action", label: "FC Midtjylland"},
        {key: "Sparta Prague", label: "Sparta Prague"},
        {key: "FC Midtjylland", label: "FK Rigas Futbola Skola"},
        {key: "Another action", label: "FC Midtjylland"},
        {key: "Sparta Prague", label: "Sparta Prague"},
        {key: "FC Midtjylland", label: "FK Rigas Futbola Skola"},
        {key: "Another action", label: "FC Midtjylland"},
        {key: "Sparta Prague", label: "Sparta Prague"},
    ];

    const [selectedItem, setSelectedItem] = useState(items[0]);

    const handleSelect = (eventKey) => {
        const item = items.find((i) => i.key === eventKey);
        setSelectedItem(item);
    };

    const vidRef = useRef();

    useEffect(() => {
        vidRef.current.play();
    }, []);
    return (
        <>
            <section className="ExchangeBetPlaceSidebar">
                <div className="min-heading">
                    <p className="title">Live Centre</p>
                    <div class="wrapper">
                        <div class="corners corners--slanted"></div>
                    </div>
                </div>
                <div className="Tab-section">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row className="main-sec">
                            <Col xxl={4} xl={4} lg={12} md={4}>
                                <Nav variant="pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/images/exchange/livecenter/comp1.png"
                                                }
                                                className="logo"
                                                alt="Component 1"
                                            />
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/images/exchange/livecenter/comp2.png"
                                                }
                                                className="logo"
                                                alt="Component 2"
                                            />
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/images/exchange/livecenter/comp3.png"
                                                }
                                                className="logo"
                                                alt="Component 3"
                                            />
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col xxl={8} xl={8} lg={12} md={8}>
                                <div className="dropdown-main">
                                    <Dropdown onSelect={handleSelect}>
                                        <Dropdown.Toggle
                                            variant="success"
                                            id="dropdown-basic"
                                            className="custom-dropdown-toggle"
                                        >
                                            <div className="names-drop">
                                                <FontAwesomeIcon icon={faFutbol} />
                                                <p className="text">{selectedItem.label}</p>
                                            </div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <div className="scrollbar" id="style-3">
                                                {items.map((item) => (
                                                    <Dropdown.Item key={item.key} eventKey={item.key}>
                                                        <div className="names-drop">
                                                            <FontAwesomeIcon icon={faFutbol} />
                                                            <p className="text">{item.label}</p>
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </Col>
                        </Row>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <div className="main-video">
                                    <video
                                        src="/assets/images/exchange/livecenter/vedio.mp4"
                                        ref={vidRef}
                                        muted
                                        loop
                                        className="video-class-grid"
                                        controls
                                    />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <div className="main-video">
                                    <video
                                        src="/assets/images/exchange/livecenter/vedio.mp4"
                                        ref={vidRef}
                                        muted
                                        loop
                                        className="video-class-grid"
                                        controls
                                    />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <div className="graph">
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                        <Nav variant="pills" className="">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/assets/images/exchange/livecenter/Icon ionic-md-stats.png"
                                                        }
                                                        className="logo"
                                                        alt="Component 1"
                                                    />
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/assets/images/exchange/livecenter/heat to head.png"
                                                        }
                                                        className="logo"
                                                        alt="Component 1"
                                                    />
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third">
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/assets/images/exchange/livecenter/Icon awesome-microphone.png"
                                                        }
                                                        className="logo"
                                                        alt="Component 1"
                                                    />
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>

                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                <GraphTab />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <Probability />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third">
                                                <VoiceTab />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
                <div className="My-Bets">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <span className="main-text">My Bets</span>
                            </Accordion.Header>
                            <Accordion.Body>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <Nav variant="pills" className="">
                                        <Nav.Item className="orange-team">
                                            <Nav.Link eventKey="first" className="orange-team">
                                                Place Back (Bet For)
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="green-team">
                                            <Nav.Link eventKey="second" className="green-team">
                                                Place Lay (Bet Against)
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>

                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <div className="orange-team">
                                                <Accordion
                                                    defaultActiveKey={["0"]}
                                                    alwaysOpen
                                                    className="orange-team mt-2"
                                                >
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>
                                                            <div class="min-heading">
                                                                <p class="title">Back (Bet For)</p>
                                                                <div class="wrapper">
                                                                    <div class="corners corners--slanted"></div>
                                                                </div>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="orange-team-main  first mb-3">
                                                                <p className="text">
                                                                    FK Rigas Futbola Skola – Bodoe/Glimt
                                                                </p>
                                                                <div className="delete-main">
                                                                    <p className="multiply me-3">
                                                                        1x2 <span className="text">Draw</span>
                                                                    </p>
                                                                    <div className="main-icon">
                                                                        <FontAwesomeIcon
                                                                            icon={faTrash}
                                                                            className="icon-delete"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <Row>
                                                                    <Col xxl={5} xl={12} lg={12} md={4}>
                                                                        <Form.Label htmlFor="inputPassword5">
                                                                            BACK
                                                                        </Form.Label>

                                                                        <IncreamentDecreament />
                                                                    </Col>
                                                                    <Col xxl={3} xl={12} lg={12} md={4}>
                                                                        <Form.Label htmlFor="inputPassword5">
                                                                            STAKE
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            id="inputPassword5"
                                                                            aria-describedby="passwordHelpBlock"
                                                                        />
                                                                    </Col>
                                                                    <Col xxl={3} xl={12} lg={12} md={4}>
                                                                        <Form.Label htmlFor="inputPassword5">
                                                                            PROFIT
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            id="inputPassword5"
                                                                            aria-describedby="passwordHelpBlock"
                                                                        />
                                                                    </Col>
                                                                </Row>

                                                                <Row className="payment-bet mt-1">
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary orange">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary orange">200</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary orange">300</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary orange">400</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary orange">1000</Button>
                                                                    </Col>

                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary orange">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary orange">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary orange">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary orange">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary orange">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary orange">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary orange">100</Button>
                                                                    </Col>
                                                                </Row>
                                                                <div className="button-main mt-2 mb-2">
                                                                    <Button variant="primary">Reset</Button>
                                                                    <Button variant="secondary">Submit</Button>
                                                                </div>
                                                            </div>
                                                            <div className="orange-team-main">
                                                                <p className="text">
                                                                    FK Rigas Futbola Skola – Bodoe/Glimt
                                                                </p>
                                                                <div className="delete-main">
                                                                    <p className="multiply me-3">
                                                                        1x2 <span className="text">Draw</span>
                                                                    </p>
                                                                    <div className="main-icon">
                                                                        <FontAwesomeIcon
                                                                            icon={faTrash}
                                                                            className="icon-delete"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <Row>
                                                                    <Col xxl={5} xl={12} lg={12} md={4}>
                                                                        <Form.Label htmlFor="inputPassword5">
                                                                            BACK
                                                                        </Form.Label>

                                                                        <IncreamentDecreament />
                                                                    </Col>
                                                                    <Col xxl={3} xl={12} lg={12} md={4}>
                                                                        <Form.Label htmlFor="inputPassword5">
                                                                            STAKE
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            id="inputPassword5"
                                                                            aria-describedby="passwordHelpBlock"
                                                                        />
                                                                    </Col>
                                                                    <Col xxl={3} xl={12} lg={12} md={4}>
                                                                        <Form.Label htmlFor="inputPassword5">
                                                                            PROFIT
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            id="inputPassword5"
                                                                            aria-describedby="passwordHelpBlock"
                                                                        />
                                                                    </Col>
                                                                </Row>

                                                                <Row className="payment-bet mt-1">
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary orange">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary orange">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary orange">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary orange">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary orange">100</Button>
                                                                    </Col>

                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary orange">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary orange">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary orange">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary orange">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary orange">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary orange">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary orange">100</Button>
                                                                    </Col>
                                                                </Row>
                                                                <div className="button-main mt-2 mb-2">
                                                                    <Button variant="primary">Reset</Button>
                                                                    <Button variant="secondary">Submit</Button>
                                                                </div>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <div className="green-team">
                                                <Accordion
                                                    defaultActiveKey={["0"]}
                                                    alwaysOpen
                                                    className="green-team mt-2"
                                                >
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>
                                                            <div class="min-heading">
                                                                <p class="title">Back (Bet For)</p>
                                                                <div class="wrapper">
                                                                    <div class="corners corners--slanted"></div>
                                                                </div>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="green-team-main  first mb-3">
                                                                <p className="text">
                                                                    FK Rigas Futbola Skola – Bodoe/Glimt
                                                                </p>
                                                                <div className="delete-main">
                                                                    <p className="multiply me-3">
                                                                        1x2 <span className="text">Draw</span>
                                                                    </p>
                                                                    <div className="main-icon">
                                                                        <FontAwesomeIcon
                                                                            icon={faTrash}
                                                                            className="icon-delete"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <Row>
                                                                    <Col xxl={5} xl={12} lg={12} md={4}>
                                                                        <Form.Label htmlFor="inputPassword5">
                                                                            BACK
                                                                        </Form.Label>

                                                                        <IncreamentDecreament />
                                                                    </Col>
                                                                    <Col xxl={3} xl={12} lg={12} md={4}>
                                                                        <Form.Label htmlFor="inputPassword5">
                                                                            STAKE
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            id="inputPassword5"
                                                                            aria-describedby="passwordHelpBlock"
                                                                        />
                                                                    </Col>
                                                                    <Col xxl={3} xl={12} lg={12} md={4}>
                                                                        <Form.Label htmlFor="inputPassword5">
                                                                            PROFIT
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            id="inputPassword5"
                                                                            aria-describedby="passwordHelpBlock"
                                                                        />
                                                                    </Col>
                                                                </Row>

                                                                <Row className="payment-bet mt-1">
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary green">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary green">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary green">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary green">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary green">100</Button>
                                                                    </Col>

                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary green">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary green">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary green">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary green">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary green">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary green">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary green">100</Button>
                                                                    </Col>
                                                                </Row>
                                                                <div className="button-main mt-2 mb-2">
                                                                    <Button variant="primary">Reset</Button>
                                                                    <Button variant="secondary">Submit</Button>
                                                                </div>
                                                            </div>
                                                            <div className="green-team-main">
                                                                <p className="text">
                                                                    FK Rigas Futbola Skola – Bodoe/Glimt
                                                                </p>
                                                                <div className="delete-main">
                                                                    <p className="multiply me-3">
                                                                        1x2 <span className="text">Draw</span>
                                                                    </p>
                                                                    <div className="main-icon">
                                                                        <FontAwesomeIcon
                                                                            icon={faTrash}
                                                                            className="icon-delete"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <Row>
                                                                    <Col xxl={5} xl={12} lg={12} md={4}>
                                                                        <Form.Label htmlFor="inputPassword5">
                                                                            BACK
                                                                        </Form.Label>
                                                                        <div className="green-team">
                                                                            <IncreamentDecreament />
                                                                        </div>
                                                                    </Col>
                                                                    <Col xxl={3} xl={12} lg={12} md={4}>
                                                                        <Form.Label htmlFor="inputPassword5">
                                                                            STAKE
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            id="inputPassword5"
                                                                            aria-describedby="passwordHelpBlock"
                                                                        />
                                                                    </Col>
                                                                    <Col xxl={3} xl={12} lg={12} md={4}>
                                                                        <Form.Label htmlFor="inputPassword5">
                                                                            PROFIT
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            id="inputPassword5"
                                                                            aria-describedby="passwordHelpBlock"
                                                                        />
                                                                    </Col>
                                                                </Row>

                                                                <Row className="payment-bet mt-1">
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary green">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary green">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary green">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary green">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary green">100</Button>
                                                                    </Col>

                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary green">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary green">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary green">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary green">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary green">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary green">100</Button>
                                                                    </Col>
                                                                    <Col xxl={2} xl={3} lg={3} md={2} className="p-1">
                                                                        <Button variant="primary green">100</Button>
                                                                    </Col>
                                                                </Row>
                                                                <div className="button-main mt-2 mb-2">
                                                                    <Button variant="primary">Reset</Button>
                                                                    <Button variant="secondary">Submit</Button>
                                                                </div>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </section>
        </>
    );
};

export default ExchangeBetPlaceSidebar;
