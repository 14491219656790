import React from "react";
import Add_cash from "./add-cash/Add_cash";
import ContestCards from "./ContestCards/ContestCards";
import PrivateContest from "../../ContestPrivate/PrivateContest/PrivateContest";

const Contest_sidebar = () => {
  return (
    <>
      <Add_cash />
      <ContestCards />
    </>
  );
};

export default Contest_sidebar;
