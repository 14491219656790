import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import Countdown from 'react-countdown';

function SelectCapVc() {
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return;
        } else {
            // Render a countdown
            return <span className='timings'>{hours}:{minutes}:{seconds}</span>;
        }
    };
    return (
        <>
            <section className='fantacy-sidebar'>
                <div className='sidebar-header'>
                    <Link to="/fantacy-sport/createnewteam"><FontAwesomeIcon icon="fa-solid fa-arrow-left" className='bar-icon' /></Link>

                    <div className='settimeout'>

                        <Countdown
                            date={Date.now() + 500000}
                            renderer={renderer}
                        />

                    </div>

                    <div className=''>



                    </div>


                    {/* <button type="button" class="header_btn"><span class="px-1">Add Cash</span> <span className='plus-div'>+</span></button> */}
                </div>
            </section>
        </>
    )
}

export default SelectCapVc