import React, { useRef, useState, useEffect, useContext } from 'react';
import Football_cards from '../../common-components/cards/football-card/Football_cards';
import Banner from '../banner/Banner';
import { GetAllHomeBanner } from '../../../utils/apis/master/Master';
import { Context } from '../../../utils/context';

const Mid_cont = ({ setTeamtwo, setTeamOne, setRunnerId, events, lives }) => {

  const { IMG_URL, token, users } = useContext(Context);

  const [firstHomeBanner, setFirstHomeBanner] = useState([]);
  const getFirstHomeBanner = async () => {
    const res = await GetAllHomeBanner("first");
    if (res?.success) {
      setFirstHomeBanner(res?.data?.lists);
    }
  }

  const [secondHomeBanner, setSecondHomeBanner] = useState([]);
  const getSecondHomeBanner = async () => {
    const res = await GetAllHomeBanner("second");
    if (res?.success) {
      setSecondHomeBanner(res?.data?.lists);
    }
  }


  const [thirdHomeBanner, setThirdHomeBanner] = useState([]);
  const getThirdHomeBanner = async () => {
    const res = await GetAllHomeBanner("third");
    if (res?.success) {
      setThirdHomeBanner(res?.data?.lists);
    }
  }

  const [fourthHomeBanner, setFourthHomeBanner] = useState([]);
  const getFourthHomeBanner = async () => {
    const res = await GetAllHomeBanner("fourth");
    if (res?.success) {
      setFourthHomeBanner(res?.data?.lists);
    }
  }

  useEffect(() => {
    getFirstHomeBanner("first");
    getSecondHomeBanner("second");
    getThirdHomeBanner("third");
    getFourthHomeBanner("fourth");
  }, []);


  return (
    <>
      <section className='conttttt-sec'>
        <div className=''>
          <Banner 
            firstHomeBanner={firstHomeBanner} 
            secondHomeBanner={secondHomeBanner}  
            thirdHomeBanner={thirdHomeBanner} 
            fourthHomeBanner={fourthHomeBanner} 
          />
        </div>

        <div className='mt-2 mb-2'>
          <Football_cards lives={lives} setTeamtwo={setTeamtwo} setTeamOne={setTeamOne} setRunnerId={setRunnerId} events={events} />
        </div>
        <div className='mt-2 mb-2'>
          <Football_cards />
        </div>

      </section>
    </>
  )
}

export default Mid_cont