import React from "react";
import { Route, Routes } from "react-router-dom";
import ExchangeHome from "../ExchangeHome/ExchangeHome";
import ExchangeHomeMatch from "../ExchangeHome/ExchangeHomeMatch";
import AccountStatement from "../ExchangeProfile/AccountStatement";



const ExchangeRoutes = () => {
  return (
    <>
        <Routes >
            <Route path="/exchange-home" element={<ExchangeHome/>} />
            <Route path="/exchange-match" element={<ExchangeHomeMatch/>} />
            <Route path="/account-statement" element={<AccountStatement/>} />
           
        </Routes>
    </>
  )
}

export default ExchangeRoutes