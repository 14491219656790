import React, { useContext, useEffect, useState } from "react";
import "./Game_Details_Page.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import Score_Card_tab from "./Score_Card_tab/Score_Card_tab";
import Game_Match_Banner from "./Game_Match_Banner/Game_Match_Banner";
import Match_Screen from "./Match_Screen/Match_Screen";
import Parlays from "./Parlays/Parlays";
import LoginModal from "../../Modals/LoginModal/LoginModal";
import NotiicationModal from "../../Modals/NotiicationModal/NotiicationModal";
import { Context } from "../../../../utils/context";

function Game_Details_Page({ id, eventId }) {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShownoti, setModalShownoti] = React.useState(false);
  const { getGameData } = useContext(Context);
  const [event, setEvent] = useState();
  
  const getEvent = async () => {
    const res = await getGameData(`getEvents?eventPathIds=${id}`);
    // Filter the event with the given id from the res array
    const filteredEvent = res.find(event => event?.id == eventId);

    console.log(filteredEvent, 'pppppppp');
    setEvent(filteredEvent);
  }


  useEffect(() => {
    getEvent();
  }, []);


  return (
    <>
      <div className="Game_Details_Page  Game_Details_Page-tab ">
        <div className="white-bg">
          <div className="container">
            <div className="heading-holder">
              <Link to={"/tournament"}>
                <div className="back-arrow">
                  <FontAwesomeIcon
                    className="back-btn"
                    icon="fa-solid fa-angle-left"
                  />
                </div>
              </Link>

              <div className="text-holder">
                <h3>Football</h3>
                <p>England. Premier League</p>
              </div>

              <div className="start-notify">
                <button onClick={() => setModalShow(true)}>
                  <FontAwesomeIcon
                    className="notify-icon me-3"
                    icon="fa-solid fa-star"
                  />
                </button>

                <button onClick={() => setModalShownoti(true)}>
                  <FontAwesomeIcon
                    className="notify-icon me-3"
                    icon="fa-solid fa-bell"
                  />
                </button>
              </div>
              <LoginModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
              <NotiicationModal
                show={modalShownoti}
                onHide={() => setModalShownoti(false)}
              />
            </div>

            <div className="Breadcrumb-holder">
              <Breadcrumb>
                <Link to={"/"}>
                  <Breadcrumb.Item>
                    <FontAwesomeIcon icon="fa-solid fa-house" />
                  </Breadcrumb.Item>
                </Link>
                <Breadcrumb.Item>Football Betting Odds</Breadcrumb.Item>
                <Breadcrumb.Item>{event?.eventPaths.find(event => event?.id == id)?.description}</Breadcrumb.Item>
                <Breadcrumb.Item active>{event?.opponents?.[0]?.description} - {event?.opponents?.[1]?.description}</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="tab-content-holder">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div>
                  <div>
                    <Nav variant="pills" className="nav-tab-holder mb-1">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Score Card</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second"> Match Screen</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Score Chart</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>

                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <Score_Card_tab event={event} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Match_Screen event={event} />
                    </Tab.Pane>


                    <Tab.Pane eventKey="third">


                      <div class="EC_BC">
                        <iframe src="https://st-cdn001.akamaized.net/parimatch/en/match/48156875" frameborder="0" class="Statistics-styles__frame--2Qf-BmE421-kSFM8" data-id="event-view-statistics-iframe">
                        </iframe>
                        {/* <iframe class="DefaultFrame_frame__6ASkh" title="leap" src="https://aggregation-launch.eva-pm8games.com/v0/casino/game/launch?operator=parimatch&amp;brand=IN&amp;gameId=leap-football-cup-world&amp;channel=desktop&amp;language=en&amp;lobbyUrl=https%3A%2F%2Fpari-match-bet.in%2Fen%2Fcasino%2Fvirtual-sports" frameborder="0" allow="autoplay *; fullscreen *" allowfullscreen="" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe> */}
                      </div>

                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>

        <Game_Match_Banner />

        <Parlays event={event} />



      </div>
    </>
  );
}

export default Game_Details_Page;
