import React from "react";
import "./Game_Match_Banner.css" ;

function Game_Match_Banner() {
  return (
    <>
      <div className="game_match_banner">
        <div className="white-bg">
          <div className="img-text-holder">
            <div className="img-holder">
              <img
                className="banner-img"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/Game_details/match_banner.png"
                }
              />
            </div>
            <div className="text-holder">
              <div>
                <h4>Fortune awaits</h4>

                <p>Seize the moment</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Game_Match_Banner;
