import React from "react";
import "./Footer_Social.css";

function Footer_Social() {
    return (
        <>
            <div className="Footer_Social">
                <div className="Game_Partners_Content">
                    <div className="">
                        <div className="Partners_heading_holder">
                            <h1 className="heading-text">Social networks</h1>
                        </div>

                        <div className="partner-img-holder">
                            <div className="img-holder">
                                <img
                                    className="partner-img"
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/Game_details/facebook.png"
                                    }
                                />
                            </div>

                            <div className="img-holder">
                                <img
                                    className="partner-img"
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/Game_details/telegram.png"
                                    }
                                />
                            </div>

                            <div className="img-holder">
                                <img
                                    className="partner-img"
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/Game_details/instagram.png"
                                    }
                                />
                            </div>

                            <div className="img-holder">
                                <img
                                    className="partner-img"
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/Game_details/youtube.png"
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="">
                        <div className="Partners_heading_holder">
                            <h1 className="heading-text">Licenses</h1>
                        </div>

                        <div className="partner-img-holder">
                            <div className="img-holder">
                                <img
                                    className="partner-img"
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/Game_details/federbet_icon.png"
                                    }
                                />
                            </div>

                            <div className="img-holder">
                                <img
                                    className="partner-img"
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/Game_details/adult.jpg"
                                    }
                                />
                            </div>

                            <div className="img-holder">
                                <img
                                    className="partner-img"
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/Game_details/lic_stop.png"
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer_Social;
