import React, { useState } from "react";
import './ChoosePlayerCard.css'
function ChoosePlayerCard() {
const a="C";
const b="2x";
const c="VC";
const d="1.5x";

    const [colors, setColors]=useState(false);
    const setclick = ()=>{
    setColors(!colors);
    setColors2(false);
    }

    const [colors2, setColors2]=useState(false);
    const setclick2 = ()=>{
    setColors2(!colors2);
    setColors(false);
    }
  return (
    <>
      <section className="Chooseplayercard_section">
        <div className="Chooseplayercard">
          <div className="row m-0">
            <div className="col-3 ">
              <div className="playerlogo">
                <img
                  className="playersimg"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/teams/wkkeeperpng.png"
                  }
                  alt="Logo"
                />
              </div>
            </div>
            <div className="col-9 my-auto">
              <div className="row m-0">
                <div className="col-6 my-auto">
                  <div className="my-1">
                    <p className="abdul">Abdul Manan Ali</p>
                    <p>SAU | 0 Pts</p>
                  </div>
                </div>
                <div className="col-3 my-auto">
                  <div className="playerpercent my-1">
                    <div onClick={setclick} className={colors? "vcbrder blues mx-auto ":"vcbrder whites  mx-auto"}>
                      <p>{colors? "2x":"C"}</p>
                    </div>
                    <p className="percent">2.4%</p>
                  </div>
                </div>
                <div className="col-3 my-auto">
                  <div className="playerpercent my-1">
                    <div onClick={setclick2}  className={colors2? "vcbrder blues mx-auto ":"vcbrder whites  mx-auto"}>
                      <p>{colors2? "1.5x":"VC"}</p>
                    </div>
                    <p className="percent">2.4%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChoosePlayerCard;
