// Succsessfull_Modal.js

import React from "react";
import "./Succsessfull_Modal.css";
import Successfull_Lottie from '../../Animation/Successfull_Lottie/Successfull_Lottie';

import { Modal } from "react-bootstrap";

function Succsessfull_Modal({ show, onHide, smsText }) {
  return (
    <>
      <section className="popup_section">
        <Modal
          show={show}
          onHide={onHide}
          className="Successfull_Modal"
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        // fullscreen={true}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div>
              <div className="lottie-holder">
                <Successfull_Lottie />
              </div>
              <p>{smsText}</p>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
}

export default Succsessfull_Modal;
