import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./AccountOverview.css";

const AccountOverview = () => {
  return (
    <>
      <section className="fantacy-sidebar account-overview-sec">
        <div className="sidebar-header">
          <Link to="/fantacy-sport/myaccount">
            <FontAwesomeIcon
              icon="fa-solid fa-arrow-left"
              className="bar-icon"
            />
          </Link>
          <img
            className="headlogo"
            src={process.env.PUBLIC_URL + "/assets/images/logo/logo.png"}
            alt="Logo"
          />
          <Link to="/fantacy-sport/addcash">
            <button type="button" class="header_btn">
              <span class="px-1">Add Cash</span>{" "}
              <span className="plus-div">+</span>
            </button>
          </Link>
        </div>
        <div className="acc-overview-desc">
          <h1>Account Overview</h1>
          <hr></hr>
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-6">
              <div className="table-div">
                <h6>Cash Account</h6>
                <div className="">
                  
                  <div className="flex-div">
                    <p className="title">Deposit Balance :</p>
                    <p className="price">$0</p>
                  </div>
                  <div className="flex-div">
                    <p className="title">Withdrawable Balance :</p>
                    <p className="price">$0</p>
                  </div>
                  <div className="flex-div">
                    <p className="title">Total Cash Balance :</p>
                    <p className="price">$0</p>
                  </div>
                  <div className="flex-div">
                    <p className="title">Tax Deducted :</p>
                    <p className="price">$0</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-6">
              <div className="table-div">
                <h6>Bonus Account</h6>
                <div className="">
                  
                  <div className="flex-div">
                    <p className="title">Pending Bonus :</p>
                    <p className="price">$0</p>
                  </div>
                  <div className="flex-div">
                    <p className="title">Released Bonus :</p>
                    <p className="price">$0</p>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AccountOverview;
