import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import "./JoinContest.css";
const JoinContest = () => {
  return (
    <>
      <section className="fantacy-sidebar join-contest-sec ">
      
        <div className="sidebar-header">
          <Link to="/fantacy-sport/fantfoot">
            <FontAwesomeIcon
              icon="fa-solid fa-arrow-left"
              className="bar-icon"
            />
          </Link>
          <p>JOIN PRIVATE CONTESTS</p>
          <Link to="/fantacy-sport/addcash">
            <button type="button" class="header_btn">
              <span class="px-1">Add Cash</span>{" "}
              <span className="plus-div">+</span>
            </button>
          </Link>
        </div>
        <div className="form-div">
          <Form>
            <Form.Group className="m-3" controlId="exampleForm.ControlInput1">
              <Form.Control type="email" placeholder="Enter Code Here" />
            </Form.Group>
          </Form>
          <button className="search-btn">Search</button>
          <p>(Enter invite code to join)</p>
        </div>
        <div className="mid-sec-join">
        </div>
      </section>
    </>
  );
};

export default JoinContest;
