import React, { useContext, useEffect, useState } from 'react'
import './TournamentFirstCard.css'
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { Button } from 'react-bootstrap';
import LoginModal from '../../../Modals/LoginModal/LoginModal';
import { Context } from '../../../../../utils/context';
library.add(fas);

const TournamentFirstCard = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const { getGameData ,setSelectedBet,selectedBet } = useContext(Context);

    const [lives, setLive] = useState([]);
    const getCompetitions = async () => {
        const res = await getGameData('getEvents?eventPathIds=240');
        console.log(res,'live Event');
        setLive(res);
    }
    const handleClick = (outcomes, wdw,description) => {
        // const result = selectedBet.filter(data =>    outcomes.filter(data1 => data1.ordinalPosition  !== data.ordinalPosition));

        const result = selectedBet.filter(data => 
            !outcomes.some(data1 => data1.id === data.id)
        );
        wdw.vsdescription = description;
        setSelectedBet([...result,wdw]);
    };


    useEffect(() => {
        getCompetitions();
    }, []);


    return (
        <>
            <section className='tournament-first-card'>
                {lives?.map((value, index) => (
                    
                        <div className='main-sec'>
                            <div className='first-div'>
                                <div className='row'>
                                    <div className='col-xxl-10 col-md-10 col-10'>
                                        <div className='title-div'>
                                            <img className='flag-img' src={process.env.PUBLIC_URL + '/assets/images/home/sidebar/ind.png'} alt="Logo" />
                                            <p>
                                                {value?.eventPaths?.map((eventPath, pathIndex) => (
                                                    <>{eventPath?.description},</>
                                                ))}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-xxl-2 col-md-2 col-2 text-end'>
                                        <FontAwesomeIcon icon="fa-solid fa-star" className='star-icon' />
                                    </div>
                                </div>
                            </div>
                            <div className='sec-div'>
                                <div className='flex-div'>
                                    <p className='text-danger'>{value?.currentPeriod}</p>
                                    <p className='count-text'> 12+</p>
                                </div>
                                <div className='row'>
                                    <div className='col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-4'>
                                        {value?.opponents?.map((opponent, oppoIndex) => (

                                            <>
                                                <Link to={`/game-details/${value?.eventPaths?.[1]?.id}/${value?.id}`}>
                                                    <div className='left-div'>
                                                        <div className='title-div'>
                                                            <img className='flag-img' src={process.env.PUBLIC_URL + '/assets/images/home/sidebar/bal.png'} alt="Logo" />
                                                            <p className='my-0 mx-2'>{opponent?.description}</p>
                                                        </div>
                                                        <p className='my-0 mx-2'>{value?.scores?.score?.[oppoIndex]?.points}</p>
                                                    </div>
                                                </Link>
                                            </>
                                        ))}

                                    </div>
                                    <div className='col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-8'>
                                        <div className='right-div flex-div'>
                                            <Nav variant="pills" className='mt-2'>
                                                <>
                                                    {value?.markets[2]?.outcomes?.map((wdw, wdwindex) => (
                                                        <Nav.Item key={wdwindex}>
                                                            <Nav.Link eventKey={`link-${wdwindex}`} onClick={() => handleClick(value?.markets[2]?.outcomes,wdw , value.description)}>
                                                                {wdw?.consolidatedPrice?.currentPrice?.decimal}
                                                                <br />
                                                                <span>
                                                                    {wdwindex === 0 ? '1' : wdwindex === 1 ? 'x' : wdwindex === 2 ? '2' : ''}
                                                                </span>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    ))}
                                                </>
                                            </Nav>
                                            <div>
                                                <FontAwesomeIcon icon="fa-solid fa-star" className='star-icon' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                ))}

                {/* <div className='main-sec'>
                    <div className='first-div'>
                        <div className='row'>
                            <div className='col-xxl-10 col-md-10 col-10'>
                                <div className='title-div'>
                                    <img className='flag-img' src={process.env.PUBLIC_URL + '/assets/images/home/sidebar/eng.png'} alt="Logo" />
                                    <p>London. Rajasthan. R-League. Division A</p>
                                </div>
                            </div>
                            <div className='col-xxl-2 col-md-2 col-2 text-end'>
                                <FontAwesomeIcon icon="fa-solid fa-star" className='star-icon' />
                            </div>
                        </div>
                    </div>

                    <div className='sec-div'>
                        <div className='flex-div'>
                            <p>Break</p>
                            <p className='count-text'> 12+</p>
                        </div>
                        <div className='row'>
                            <div className='col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-4'>
                                <div className='left-div'>
                                    <div className='title-div'>
                                        <img className='flag-img' src={process.env.PUBLIC_URL + '/assets/images/home/sidebar/bal.png'} alt="Logo" />
                                        <p className='my-0 mx-2'>Mamit DFA</p>
                                    </div>
                                    <p className='my-0 mx-2'>0</p>
                                </div>
                                <div className='left-div'>
                                    <div className='title-div'>
                                        <img className='flag-img' src={process.env.PUBLIC_URL + '/assets/images/home/sidebar/mlsfc.png'} alt="Logo" />
                                        <p className='my-0 mx-2'>MLS FC Lawngtlai</p>
                                    </div>
                                    <p className='my-0 mx-2'>5</p>
                                </div>
                            </div>
                            <div className='col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-8'>
                                <div className='right-div flex-div'>
                                    <Nav variant="pills" className=' mt-2' defaultActiveKey="">
                                        <Nav.Item>
                                            <Nav.Link eventKey="link-0">3.75<br></br><span>1</span></Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="link-1">8.5<br></br><span>1</span></Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="link-2">2.55<br></br><span>1</span></Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <div>
                                        <FontAwesomeIcon icon="fa-solid fa-star" className='star-icon' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='sec-div'>
                        <div className='flex-div'>
                            <p>Break</p>
                            <p className='count-text'> 12+</p>
                        </div>
                        <div className='row'>
                            <div className='col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-4'>
                                <div className='left-div'>
                                    <div className='title-div'>
                                        <img className='flag-img' src={process.env.PUBLIC_URL + '/assets/images/home/sidebar/bal.png'} alt="Logo" />
                                        <p className='my-0 mx-2'>Mamit DFA</p>
                                    </div>
                                    <p className='my-0 mx-2'>0</p>
                                </div>
                                <div className='left-div'>
                                    <div className='title-div'>
                                        <img className='flag-img' src={process.env.PUBLIC_URL + '/assets/images/home/sidebar/mlsfc.png'} alt="Logo" />
                                        <p className='my-0 mx-2'>MLS FC Lawngtlai</p>
                                    </div>
                                    <p className='my-0 mx-2'>5</p>
                                </div>
                            </div>
                            <div className='col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-8'>
                                <div className='right-div flex-div one-tab-div'>
                                    <Nav variant="pills" className=' mt-2' defaultActiveKey="">
                                        <Nav.Item>
                                            <Nav.Link eventKey="link-0">3.75<br></br><span>1</span></Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <div>
                                        <FontAwesomeIcon icon="fa-solid fa-star" className='star-icon' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <LoginModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </section>
            {/* {lives?.sports?.map((tournament, index) => (
                <section key={index} className='tournament-first-card'>
                    <div className='main-sec'>
                        <div className='first-div'>
                            <div className='row'>
                                <div className='col-xxl-10 col-md-10 col-10'>
                                    <div className='title-div'>
                                        <img className='flag-img' src={process.env.PUBLIC_URL + tournament.flagImg} alt="Logo" />
                                        <p>{tournament.title}</p>
                                    </div>
                                </div>
                                <div className='col-xxl-2 col-md-2 col-2 text-end'>
                                    <button onClick={() => setModalShow(true)}>
                                        <FontAwesomeIcon icon={tournament.starIcon} className='star-icon' />
                                    </button>
                                </div>
                            </div>
                        </div>
                        {tournament?.events?.map((detail, index) => (
                            <div key={index} className='sec-div'>
                                <div className='flex-div'>
                                    <p>{detail.label}</p>
                                    <p className='count-text'>{detail.count}</p>
                                </div>
                                <div className='row'>

                                    <div className='col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-12'>
                                        {detail.teams.map((team, index) => (
                                            <div key={index} className='left-div'>
                                                <div className='title-div'>
                                                    <img className='flag-img' src={process.env.PUBLIC_URL + team.flagImg} alt="Logo" />
                                                    <p className='my-0 mx-2'>{team.name}</p>
                                                </div>
                                                <p className='my-0 mx-2'>{team.score}</p>
                                            </div>
                                        ))}
                                    </div>

                                    <div className='col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-12'>
                                        <div className='right-div flex-div '>
                                            <Nav variant="pills" className=' mt-2' defaultActiveKey="">
                                                {detail.tabs.map((tab, index) => (
                                                    <Nav.Item key={index}>
                                                        <Nav.Link eventKey={`link-${index}`}>{tab.odds}<br /><span>{tab.number}</span></Nav.Link>
                                                    </Nav.Item>
                                                ))}
                                            </Nav>
                                            <button onClick={() => setModalShow(true)}>
                                                <FontAwesomeIcon icon={detail.starIcon} className='star-icon' />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    
                </section>
            ))} */}
        </>
    )
}

export default TournamentFirstCard