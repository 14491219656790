import React from "react";
import { Route, Routes } from "react-router-dom";

import AccountStatement from "../ExchangeProfile/AccountStatement";
import ProfitLoss from "../ExchangeProfile/ProfitLoss";
import ChangePassword from "../ExchangeProfile/ChangePassword";
import SecureAuth from "../ExchangeProfile/SecureAuth";
import ButtonValues from "../ExchangeProfile/ButtonValues";
import BetHistory from "../ExchangeProfile/BetHistory";
import UnsetteledBet from "../ExchangeProfile/UnsetteledBet";
import ExchangeMobProfile from "../ExchangeProfile/ExchangeMobProfile";

const ExchangeProfileRoutes = () => {
  return (
    <>
        <Routes >            
            <Route path="/account-statement" element={<AccountStatement/>} />
            <Route path="/profit-loss" element={<ProfitLoss/>} />
            <Route path="/change-password" element={<ChangePassword/>} />
            <Route path="/secure-auth" element={<SecureAuth/>} />
            <Route path="/button-value" element={<ButtonValues/>} />
            <Route path="/bet-history" element={<BetHistory/>} />
            <Route path="/unsetteled-bet" element={<UnsetteledBet/>} />
            <Route path="/profile" element={<ExchangeMobProfile/>} />

        </Routes>
    </>
  )
}

export default ExchangeProfileRoutes