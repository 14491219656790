import React from 'react'
import './Inner_private_contest.css'

import Private_component from './private-contest/Private_component'


const Inner_private_contest = () => {
    return (
        <>
            <section className="fantacy-section">
                <div className="container-fluid p-0">
                    <div className="sectionflex">
                        <div className="sidebar-div">
                            <div className="first-scroll-div">                                
                                <Private_component />
                            </div>
                        </div>
                        <div className="contentmain-div ">
                            <div className="scroll-second-div">
                    
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Inner_private_contest