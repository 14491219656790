import React, {useContext} from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Header.css";
import {Link, NavLink, useNavigate} from "react-router-dom";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {Context} from "../../utils/context";
import Cookies from "js-cookie";
import ExposureModal from "../Exchange/ExchangeCommonComponents/Modals/ExposureModal/ExposureModal";
import Dropdown from "react-bootstrap/Dropdown";
import Rules from "../common-components/Modals/Rules/Rules";

library.add(fas);
function Header() {
    // const { postData, setOtherSignin, setUsertype, setuserId, setSignin } = useContext(Context);
    const {othersignin, setSignin, setUsertype, userId, setuserId, signin} = useContext(Context);
    const navigate = useNavigate();

    const handleLogout = async () => {
        Cookies.remove("arc_security", {path: "/"});
        await setSignin(false);
        await setUsertype("");
        await navigate("/login");
    };

    const [modalShowExpo, setModalShowExpo] = React.useState(false);
    const [modalShowrules, setModalShowRules] = React.useState(false);

    return (
        <>
            <section className="game-header">
                <Navbar expand="lg" className="main-head">
                    <Container fluid>
                        <Navbar.Brand as={Link} to={"/"}>
                            <img
                                className="headlogo"
                                src={process.env.PUBLIC_URL + "/assets/images/logo/logo.png"}
                                alt="Logo"
                            />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav className="m-auto my-2 my-lg-0">
                                <NavLink to="/" className="nav-links">
                                    <FontAwesomeIcon icon="fa-solid fa-tv" className="me-2" /> Live Event
                                </NavLink>

                                <NavLink to="/match" className="nav-links">
                                    <FontAwesomeIcon icon="fa-solid fa-calendar-days" className="me-2" />
                                    Upcoming Match
                                </NavLink>

                                <NavLink to="/virtualsport" className="nav-links">
                                    <FontAwesomeIcon icon="fa-solid fa-baseball" className="me-2" />
                                    Virtual Sports
                                </NavLink>

                                <NavLink to="/exchange/exchange-home" className="nav-links">
                                    <FontAwesomeIcon icon="fa-solid fa-check-to-slot" className="me-2" />
                                    Exchange
                                </NavLink>

                                <NavLink to="/fantacy-sport/fantfoot" className="nav-links">
                                    <FontAwesomeIcon icon="fa-solid fa-futbol" className="me-2" />
                                    Fantasy Sports
                                </NavLink>
                            </Nav>

                            <Form className="d-flex align-items-center">
                                <NavLink>
                                    <div className="main-rules">
                                        <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" className="nav-links" />
                                        <p className="text" onClick={() => setModalShowRules(true)}>Rules</p>
                                    </div>
                                </NavLink>

                                <NavLink className="me-2">
                                    <div>
                                        <p className="exbalncerss">
                                            Balance: <span>55447788</span>
                                        </p>
                                    </div>
                                    <div>
                                        <p className="exbalncerss" type="utton" onClick={() => setModalShowExpo(true)}>
                                            Exposure: <span>0</span>
                                        </p>
                                    </div>
                                </NavLink>

                                {signin ? (
                                    <>
                                        <NavLink to="#">
                                            <button className="login-btn" onClick={handleLogout}>
                                                Logout
                                            </button>
                                        </NavLink>
                                        {/* <NavLink to="/signup"><button className="sign-up-btn">Sign Up</button></NavLink> */}
                                    </>
                                ) : (
                                    <>
                                        <NavLink to="#">
                                            <button type="button" className="login-btn">
                                                Login
                                            </button>
                                        </NavLink>
                                        <NavLink to="#">
                                            <button type="button" className="sign-up-btn">
                                                Sign Up
                                            </button>
                                        </NavLink>
                                    </>
                                )}

                                <NavLink type="button" className="demo18drop">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            Demo 18
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item as={NavLink} to="/exchange-profile/account-statement">
                                                Account Statement
                                            </Dropdown.Item>
                                            <Dropdown.Item as={NavLink} to="/exchange-profile/change-password">
                                                Profit Loss Report
                                            </Dropdown.Item>
                                            <Dropdown.Item as={NavLink} to="/exchange-profile/bet-history">
                                                Bet History
                                            </Dropdown.Item>
                                            <Dropdown.Item as={NavLink} to="/exchange-profile/unsetteled-bet">
                                                Unsetteled Bet
                                            </Dropdown.Item>
                                            <Dropdown.Item as={NavLink} to="/exchange-profile/button-value">
                                                Set Button Values
                                            </Dropdown.Item>
                                            <Dropdown.Item as={NavLink} to="/exchange-profile/secure-auth">
                                                Security Auth Verification
                                            </Dropdown.Item>

                                            <Dropdown.Item as={NavLink} to="/exchange-profile/change-password">
                                                Change Password
                                            </Dropdown.Item>
                                            <Dropdown.Item as={NavLink} to="#">
                                                Sign Out
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <div className="main-sign"></div>
                                </NavLink>
                            </Form>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </section>

            <ExposureModal show={modalShowExpo} onHide={() => setModalShowExpo(false)} />
            <Rules show={modalShowrules} onHide={() => setModalShowRules(false)} />
        </>
    );
}

export default Header;
