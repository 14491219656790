import React, { useState, useEffect } from "react";
import "./CountdownTimer.css";

const CountdownTimer = () => {
  const calculateTimeLeft = () => {
    const difference = +new Date("2024-04-03") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  return (
    <div className="CountdownTimer ">
      <div className="timer-holder">
        <div className="text-holder">
          <div className="day-holder">{timeLeft.days}</div>
          <div className="label-holder">Days</div>
        </div>
        <div className="text-holder">
          <div className="day-holder">{timeLeft.hours}</div>
          <div className="label-holder">Hrs</div>
        </div>
        <div className="text-holder">
          <div className="day-holder">{timeLeft.minutes}</div>
          <div className="label-holder">Mins</div>
        </div>
        <div className="text-holder">
          <div className="day-holder">{timeLeft.seconds}</div>
          <div className="label-holder">Secs</div>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
