import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import "./WithrawCash.css";
const WithrawCash = () => {
  return (
    <>
      <section className="fantacy-sidebar cash-withdraw-sec">
        <div className="sidebar-header">
          <Link to="/fantacy-sport/myaccount">
            <FontAwesomeIcon
              icon="fa-solid fa-arrow-left"
              className="bar-icon"
            />
          </Link>
          <img
            className="headlogo"
            src={process.env.PUBLIC_URL + "/assets/images/logo/logo.png"}
            alt="Logo"
          />
          <Link to="/fantacy-sport/addcash">
            <button type="button" class="header_btn">
              <span class="px-1">Add Cash</span>{" "}
              <span className="plus-div">+</span>
            </button>
          </Link>
        </div>

        <div className="main-card">
          <div className="flex-div">
            <div>
              <p>$0</p>
              <p>Withdrawable Balance</p>
            </div>
            <Link to="/fantacy-sport/withdraw-history">History</Link>
          </div>
          <div className="floating-label">
            <FloatingLabel
              controlId="floatingTextarea"
              label="Enter Amount To Withdraw"
              className="mb-3"
            >
              <Form.Control placeholder="Enter Amount To Withdraw" />
              <FontAwesomeIcon
                icon="fa-solid fa-Londonn-rupee-sign"
                className="rs-icon"
              />
            </FloatingLabel>
            <p className="valid-text">Please enter a valid withdrawal amount</p>
            <span>Govt policy: 30% Tax will apply on 'Net Winnings’</span>{" "}
            <button>Know more</button>
          </div>
        </div>

        <div className="main-card">
          <div className="first-tabs">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Nav variant="pills" className="">
                <Nav.Item>
                  <Nav.Link eventKey="first">UPI</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Bank Account</Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <div className="floating-label">
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="Enter Your UPI Id"
                      className="mb-3"
                    >
                      <Form.Control placeholder="Enter Amount To Withdraw" />
                    </FloatingLabel>
                    <div className="format-div">Format - username@bankname</div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <div className="main-card">
                    <div className="flex-div">
                      <div>
                        <p>Bank Details</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xxl-6 col-xl-6 col-6">
                        <div className="floating-label">
                          <FloatingLabel
                            controlId="floatingTextarea"
                            label="Enter Account Number"
                            className="mb-3"
                          >
                            <Form.Control placeholder="Enter Account Number" />
                          </FloatingLabel>
                          <p className="valid-text">
                            Please enter a valid account Number
                          </p>
                        </div>
                      </div>
                      <div className="col-xxl-6 col-xl-6 col-6">
                        <div className="floating-label">
                          <FloatingLabel
                            controlId="floatingTextarea"
                            label="Re-Enter Account Number"
                            className="mb-3"
                          >
                            <Form.Control placeholder="Re-Enter Account Number" />
                          </FloatingLabel>
                          <p className="valid-text">
                            Please enter a valid account Number
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sec-tab">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="ifsc"
                    >
                      <Nav variant="pills" className="">
                        <Nav.Item>
                          <Nav.Link eventKey="ifsc">Search by IFSC</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="bank">
                            Search by Bank Name
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>

                      <Tab.Content>
                        <Tab.Pane eventKey="ifsc">
                          <div className="row">
                            <div className="col-xxl-8 col-md-8 col-12">
                              <div className="floating-label">
                                <FloatingLabel
                                  controlId="floatingTextarea"
                                  label="IFSC code"
                                  className="mb-3"
                                >
                                  <Form.Control placeholder="Enter Amount To Withdraw" />
                                </FloatingLabel>
                              </div>
                            </div>
                            <div className="col-xxl-4 col-md-4 col-12 m-auto">
                              <button className="search-btn">Search</button>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="bank">
                        <div className="row">
                            <div className="col-xxl-4 col-md-4 col-12">
                              <div className="floating-label">
                                <FloatingLabel
                                  controlId="floatingTextarea"
                                  label="bank Name"
                                  className="mb-3"
                                >
                                  <Form.Control placeholder="Enter Amount To Withdraw" />
                                </FloatingLabel>
                              </div>
                            </div>
                            <div className="col-xxl-4 col-md-4 col-12">
                              <div className="floating-label">
                                <FloatingLabel
                                  controlId="floatingTextarea"
                                  label="Branch Name"
                                  className="mb-3"
                                >
                                  <Form.Control placeholder="Enter Amount To Withdraw" />
                                </FloatingLabel>
                              </div>
                            </div>
                            <div className="col-xxl-4 col-md-4 col-12 m-auto">
                              <button className="search-btn">Search</button>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </section>
    </>
  );
};

export default WithrawCash;
