import React, { useState } from "react";
import "./ContestJoinMain.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import ContestCard from "../ContestCard/ContestCard";
import { Link } from "react-router-dom";

function ContestJoinMain() {
  const [key, setKey] = useState("home");
  return (
    <section className="contestjoinmain">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <div className="constjoinssss">
          <div className="contestjoinmain">
            <div className="row mx-0">
              <div className="col-9">
                <div className="tabsbutton">
                  <Nav variant="pills" className="">
                    <Nav.Item>
                      <Nav.Link eventKey="first">All</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Head to Head</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Popular</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="fourth">Premium</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="fifth">Practice</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="six">Win All</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="seven">+ 5</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
              <div className="col-3 p-0">
                <div className="privatecontest">
                  <Link to={"/fantacy-sport/private"}>
                    <p>Private Contest </p>
                  </Link>
                  <p>
                    <FontAwesomeIcon
                      icon="fa-solid fa-greater-than"
                      className="grateicn"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="entryfee">
            <p>Total Prize</p>
            <p>Entry Fee</p>
          </div>
        </div>
        <div className="tabssection">
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <div>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>

                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="second">
              <div>
              <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>



                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link><Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link><Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link><Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="third">
              <div>
              <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="fourth">
              <div>
              <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>

                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="fifth">
              <div>
                
              <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>

              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="six">
              <div>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="seven">
              <div>
              <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                <Link to="/fantacy-sport/pricebreakup">
                  <ContestCard />
                </Link>
                
              </div>
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </section>
  );
}

export default ContestJoinMain;
