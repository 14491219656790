
import React from 'react';
import './SportCard.css';
import PlayModal from '../../common-components/Modals/PlayModal/PlayModal';

const SportCard = ({ imgUrl, name, newPlayLink }) => {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <section className='sport-card-div'>
            <div className='main-card'>
                <img className='sport-img' src={process.env.PUBLIC_URL + imgUrl} alt="Logo" />
                <div className='overlay-btn'>
                    <button onClick={() => setModalShow(true)}>Play</button>
                </div>
                <div className='overlay-color' />
            </div>
            <div className='sport-name'>
                <h5>{name}</h5>
            </div>
            <PlayModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                newPlayLink={newPlayLink}
                name={name}
            />
        </section>
    );
};

export default SportCard;

