import React, { useState } from "react";
import "./TwoTeamCapvc.css";
import ChoosePlayerCard from "../ChoosePlayerCard/ChoosePlayerCard";
import { Link } from "react-router-dom";
function TwoTeamCapvc() {
   
  return (
    <>
      <section className="twoteamcvc_section">
        <div className="allsect">
          <div className="mainvs">
            <div className="teamss_div ">
              <img
                className="teamss"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/home/sidebar/3537.png"
                }
                alt="Logo"
              />
              <div>
                <h4 className="teamname">MUM </h4>
              </div>
            </div>
            <div className="teamss_div11">
              <img
                className="vsimage"
                src={process.env.PUBLIC_URL + "/assets/images/teams/vs.png"}
                alt="Logo"
              />
            </div>
            <div className="teamss_div">
              <h4 className="teamname">BLR</h4>
              <img
                className="teamss"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/home/sidebar/3573.png"
                }
                alt="Logo"
              />
            </div>
          </div>

          <div className="cvc_main">
            <div className="cvcdiv">
              <div className="captian">
                <h4>C</h4>
              </div>
              <h4>2x Points</h4>
            </div>
            <div className="cvcdiv">
              <div className="captian ">
                <h4>VC</h4>
              </div>
              <h4>1.5x Points</h4>
            </div>
          </div>
          <div className="cvcoptions">
            <div className="row m-0">
              <div className="col-3">
                <div className="playernames">
                  <p>Player</p>
                </div>
              </div>
              <div className="col-9">
                <div className="row m-0">
                  <div className="col-6">
                    <div className="playernames">
                      <p>Points</p>
                    </div>
                  </div>
                  <div className="col-3 ">
                    <div className="playernames playerc">
                      <p>C%</p>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="playernames playerc">
                      <p>VC%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="choosemain">
          <div className="coosetype">
            <p className="choose_p">Wicket Keeper</p>
          </div>
          <div className="chooseplayer">
            <ChoosePlayerCard/>
            <ChoosePlayerCard/>
          </div>

          <div className="coosetype">
            <p className="choose_p">Batsman</p>
          </div>
          <div className="chooseplayer">
          <ChoosePlayerCard/>
          <ChoosePlayerCard/>
          </div>

          <div className="coosetype">
            <p className="choose_p">All Rounder</p>
          </div>
          <div className="chooseplayer">
          <ChoosePlayerCard/>
          <ChoosePlayerCard/>
          </div>

          <div className="coosetype">
            <p className="choose_p">Bowler</p>
          </div>
          <div className="chooseplayer">
          <ChoosePlayerCard/>
          <ChoosePlayerCard/>
          </div>
        </div>

        <div className="nextsbuttons">
          <div className="row mx-0">
            <div className="col-6 mx-auto">
             <div className="nextsmain">
              <button className="nextteam">Add Substitutes</button>
              </div>
            </div>
            <div className="col-6 mx-auto">
            <div className="nextsmain">
              <Link to={"/fantacy-sport/jointeam"}><button className="nexs">Save Team</button></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TwoTeamCapvc;
