
import React from 'react';
import './sportlistcontent.css';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SportCard from '../SportCard/SportCard';

const Sportlistcontent = () => {
    // Sample data for demonstration
    const sportsData = [
        {
            imgUrl: '/assets/images/virtualgame/gameone.jpg',
            name: 'Virtual Football League - England',
            newPlayLink: '/firstgame'
        },
        {
            imgUrl: '/assets/images/virtualgame/gametwo.png',
            name: 'Spain League On Demand',
            newPlayLink: '/secondgame'
        },
        {
            imgUrl: '/assets/images/virtualgame/gamethree.jpg',
            name: 'Virtual Football League - England',
            newPlayLink: '/firstgame'
        },
        {
            imgUrl: '/assets/images/virtualgame/gamefour.png',
            name: 'Spain League On Demand',
            newPlayLink: '/secondgame'
        },

    ];

    return (
        <>
            <section className='Sportlistcontent-section'>
                <div className='searcdivalign'>
                    <div className='searchbar-div '>
                        <Form.Control type='search' placeholder="Search" />
                        <FontAwesomeIcon className='searchfafa' icon="fa-solid fa-magnifying-glass" />
                    </div>
                </div>
                <div className='heading'>
                    <p>Football</p>
                </div>
                <div className="row">
                    {sportsData.map(sport => (
                        <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4' key={sport.name}>
                            <SportCard
                                imgUrl={sport.imgUrl}
                                name={sport.name}
                                newPlayLink={sport.newPlayLink}
                            />
                        </div>
                    ))}
                </div>
            </section>
        </>
    );
};

export default Sportlistcontent;

