import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./WithdrawHistory.css";
const WithdrawHistory = () => {
  return (
    <>
      <section className="fantacy-sidebar withdraw-history">
        <div className="sidebar-header">
          <Link to="/fantacy-sport/fantfoot">
            <FontAwesomeIcon
              icon="fa-solid fa-arrow-left"
              className="bar-icon"
            />
          </Link>
          <img
            className="headlogo"
            src={process.env.PUBLIC_URL + "/assets/images/logo/logo.png"}
            alt="Logo"
          />
          <Link to="/fantacy-sport/addcash">
            <button type="button" class="header_btn">
              <span class="px-1">Add Cash</span>{" "}
              <span className="plus-div">+</span>
            </button>
          </Link>
        </div>

        <div>
          <h1>Withdrawal Status</h1>
          <hr></hr>
          <div className="img-div">
            <img
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/images/fantacy/empty.png"}
              alt="Logo"
            />
          </div>
          <div className="text-sec">
            <h5>Currently, you have no withdrawal request placed.</h5>
            <p>
              You can track the progress of your cash withdrawal requests here
              and also view details of completed requests from past.
            </p>
            
            <Link to="/fantacy-sport/withdraw-cash">
            <button>Place Request</button>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default WithdrawHistory;
