import React, { useState } from 'react';
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./OtherDetails.css";
import InfoMissing from "../../../FantacyModals/InfoMissing/InfoMissing";

const OtherDetails = () => {
    const [modalShow, setModalShow] = React.useState(false);
  const [show, setShow] = useState(false);
  const toggleAlert = () => setShow(!show);
  const closeAlertAfterDelay = () => {
    setTimeout(() => {
      setShow(false);
    }, 2000);
  };
  return (
    <>
      <section className="fantacy-sidebar other-details-sec">
        <div className="sidebar-header">
          <Link to="/fantacy-sport/myaccount">
            <FontAwesomeIcon
              icon="fa-solid fa-arrow-left"
              className="bar-icon"
            />
          </Link>
          <img
            className="headlogo"
            src={process.env.PUBLIC_URL + "/assets/images/logo/logo.png"}
            alt="Logo"
          />
          <Link to="/fantacy-sport/addcash">
            <button type="button" class="header_btn">
              <span class="px-1">Add Cash</span>{" "}
              <span className="plus-div">+</span>
            </button>
          </Link>
        </div>
        <div>
          <h1>Preferences</h1>
          <Alert show={show} variant="success" onClose={toggleAlert}>
            <p>Your preferences will be updated within 48 hours</p>
            <hr />
          </Alert>
          <hr />
          <h6>Contact Preferences</h6>
          <hr />
          <p>My11Circle may contact you by:</p>

          <div className="my-2">
            <Button
              className="alert-btns"
              onClick={() => {
                toggleAlert();
                closeAlertAfterDelay();
              }}
            >
              SMS
            </Button>
            <Button
              className="alert-btns"
              onClick={() => {
                toggleAlert();
                closeAlertAfterDelay();
              }}
            >
              Phone
            </Button>
          </div>
          <h6>Message Preferences</h6>
          <hr></hr>
          <p>
            You can control the communication you wish to receive from us. Given
            below is a set of communication categories. You can change your
            preferences for these categories or subscribe / unsubscribe to
            emails from any of these categories by checking / unchecking the
            relevant box. Additionally, you can also unsubscribe to our other
            promotional emails by clicking on the link that appears at the
            bottom of every promotional email we send
          </p>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th></th>
                <th>SMS</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Newsletters</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Special Offers</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Tournaments/Contests</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <h1>Account deletion</h1>
          <div className="d-flex">
            <p className="click-text">
              To initiate deletion of your account please{" "}
            </p>
            <button className="click-btn " onClick={() => setModalShow(true)}>Click Here</button>
            <InfoMissing
            show={modalShow}
            onHide={() => setModalShow(false)}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default OtherDetails;
