import { useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import "./MyAccount.css";


const MyAccount = () => {
  const [open, setOpen] = useState(true);
  return (
    <>
      <section className="fantacy-sidebar my-account-sec">
        <div className="sidebar-header">
          <Link to="/fantacy-sport/fantfoot">
            <FontAwesomeIcon
              icon="fa-solid fa-arrow-left"
              className="bar-icon"
            />
          </Link>
          <img
            className="headlogo"
            src={process.env.PUBLIC_URL + "/assets/images/logo/logo.png"}
            alt="Logo"
          />
          <Link to="/fantacy-sport/addcash">
            <button type="button" class="header_btn">
              <span class="px-1">Add Cash</span>{" "}
              <span className="plus-div">+</span>
            </button>
          </Link>
        </div>
        <div className="my-acc-des">
          <h1>My Account</h1>

          <Link to="/fantacy-sport/overview">
          <div className="main-flex-div">
            <div className="d-flex-div">
              <img
                className="icon-img"
                src={process.env.PUBLIC_URL + "/assets/images/fantacy/acc1.png"}
                alt="icon-img"
              />
              <p>Account Overview</p>
            </div>
            <FontAwesomeIcon icon="fa-solid fa-angle-right" />
          </div>
          </Link>

          <div>
            <div
              className="main-flex-div"
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
            >
              <div className="d-flex-div">
                <img
                  className="icon-img"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/fantacy/acc4.png"
                  }
                  alt="icon-img"
                />
                <p>Profile</p>
              </div>
              <FontAwesomeIcon icon="fa-solid fa-angle-right" />
            </div>
            <Collapse in={open}>
              <div id="example-collapse-text" className="profile-descriptn">
                <div className="d-flex-div">
                  <img
                    className="icon-img"
                    src={
                      process.env.PUBLIC_URL + "/assets/images/fantacy/acc1.png"
                    }
                    alt="icon-img"
                  />
                  <p>Account Overview</p>
                </div>
                <div className="row ">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="cards-one cards">
                      <div className="d-flex-div">
                        <img
                          className="icon-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/fantacy/phone.png"
                          }
                          alt="icon-img"
                        />
                        <div>
                          <p>Mobile</p>
                          <p>91 XXXXXX8944</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 ">
                    <div className="cards-two cards">
                      <div className="card-flex">
                        <div className="d-flex-div">
                          <img
                            className="icon-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/fantacy/key.png"
                            }
                            alt="icon-img"
                          />
                          <div>
                            <p>Mobile</p>
                            <p>91 XXXXXX8944</p>
                          </div>
                        </div>
                        <Link to="/fantacy-sport/Change-username">Change</Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 ">
                    <div className="cards-three cards">
                      <div className="">
                        <div className="d-flex-div">
                          <img
                            className="icon-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/fantacy/mail.png"
                            }
                            alt="icon-img"
                          />
                          <div>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Control
                                type="email"
                                placeholder="name@example.com"
                              />
                            </Form.Group>
                          </div>
                        </div>
                        <Link className="ms-5" to="/fantacy-sport/add-email">Add Mail</Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="d-flex-div">
                    <img
                      className="icon-img"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/fantacy/acc1.png"
                      }
                      alt="icon-img"
                    />
                    <p>Personal Information</p>
                  </div>
                  <hr></hr>
                  <div className="row">
                    <div className="col-xxl-5 col-xl-5 col-5">
                        <div className="card-flex">
                            <p className="name-text">Name</p>
                            <p className="na-text">:N/A</p>
                        </div>
                        <div className="card-flex">
                            <p className="name-text">Date of Birth</p>
                            <p className="na-text">:N/A</p>
                        </div>
                        <div className="card-flex">
                            <p className="name-text">Pincode</p>
                            <p className="na-text">:N/A</p>
                        </div>
                        <div className="card-flex">
                            <p className="name-text">Location</p>
                            <p className="na-text">:Not Available</p>
                        </div>
                    </div>
                    <div className="col-xxl-7 col-xl-7 col-7 m-auto text-center cards">
                      <Link to="/fantacy-sport/add-adress"> Add Address Details</Link>
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>

          <Link to="/fantacy-sport/withdraw-cash">
          <div className="main-flex-div">
            <div className="d-flex-div">
              <img
                className="icon-img"
                src={process.env.PUBLIC_URL + "/assets/images/fantacy/acc2.png"}
                alt="icon-img"
              />
              <p>Withdraw Cash</p>
            </div>
            <FontAwesomeIcon icon="fa-solid fa-angle-right" />
          </div>
          </Link>
          <Link to="/fantacy-sport/other-details">
          <div className="main-flex-div">
            <div className="d-flex-div">
              <img
                className="icon-img"
                src={process.env.PUBLIC_URL + "/assets/images/fantacy/acc3.png"}
                alt="icon-img"
              />
              <p>Other details</p>
            </div>
            <FontAwesomeIcon icon="fa-solid fa-angle-right" />
          </div>
          </Link>
        </div>
      </section>
    </>
  );
};

export default MyAccount;
