import React, { useContext, useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Form from "react-bootstrap/Form";
import './TournamentSidebar.css'
import { Context } from '../../../../utils/context';
const TournamentSidebar = () => {
    const accordionData = [
        {
            flagImg: process.env.PUBLIC_URL + '/assets/images/home/sidebar/ind.png',
            country: 'London',
            selected: 3,
            key: '3',
            contents: [
                'Professional Development League',
                'Goa. Pro League',
                'London'
            ]
        },
        {
            flagImg: process.env.PUBLIC_URL + '/assets/images/home/sidebar/eng.png',
            country: 'England',
            selected: 2,
            key: '2',
            contents: [
                'Professional Development League',
                'Goa. Pro League'
            ]
        },
        {
            flagImg: process.env.PUBLIC_URL + '/assets/images/home/sidebar/ucl.png',
            country: 'London',
            selected: 1,
            key: '1',
            contents: [

                'Goa. Pro League',

            ]
        },
    ];
    const allEventKeys = ["0", "1"];

    const { getGameData } = useContext(Context);

    const [competitions, setCompetion] = useState([]);
    const getCompetitions = async () => {
        const res = await getGameData('getSports');
        setCompetion(res);
    }

    useEffect(() => {
        getCompetitions();
    }, []);
    return (

        <>
            <section className='tournament-sidebar'>
                <div className='top-section'>
                    <p className='head'>ALL TOURNAMENTS</p>
                    <p className='clear'>Clear</p>
                </div>
                {/* <div className='accordians'>
                    <Accordion defaultActiveKey={allEventKeys} alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <div className='acc-header'>
                                    <div className='img-div'>

                                        <img className='flag-img me-3' src={process.env.PUBLIC_URL + '/assets/images/home/sidebar/ind.png'} alt="Logo" />

                                        <span>London</span>
                                        <span className='top-selected-div'>1</span>
                                    </div>
                                    <p>1</p>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className='acc-content'>
                                    <p className='me-1'>Professional Development League</p>
                                    <Form.Check
                                        inline
                                        className='m-0'
                                    />
                                </div>
                                <div className='acc-content'>
                                    <p className='me-1'>Goa. Pro League</p>
                                    <Form.Check
                                        inline
                                        className='m-0'
                                    />
                                </div>
                                <div className='acc-content'>
                                    <p className='me-1'>Rajasthan. R-League. Division A</p>
                                    <Form.Check
                                        inline
                                        className='m-0'
                                    />
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <div className='acc-header'>
                                    <div className='img-div'>
                                        <img className='flag-img me-3' src={process.env.PUBLIC_URL + '/assets/images/home/sidebar/eng.png'} alt="Logo" />
                                        <span>England</span>
                                        <span className='top-selected-div'>1</span>
                                    </div>
                                    <p>2</p>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className='acc-content'>
                                    <p className='me-1'>Professional Development League</p>
                                    <Form.Check
                                        inline
                                        className='m-0'
                                    />
                                </div>
                                <div className='acc-content'>
                                    <p className='me-1'>Goa. Pro League</p>
                                    <Form.Check
                                        inline
                                        className='m-0'
                                    />
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div> */}
                <div className='accordians'>
                    <Accordion defaultActiveKey={accordionData.map(item => item.key)} alwaysOpen>
                        {competitions?.map(item => (
                            item?.name === 'Football' && (
                                item?.children?.map((content, index) => (
                                <Accordion.Item key={index} eventKey={index}>
                                    <Accordion.Header>
                                        <div className='acc-header'>
                                            <div className='img-div'>
                                                {/* <img className='flag-img me-3' src={item.flagImg} alt="Logo" /> */}
                                                <span>{content?.name}</span>
                                                {/* <span className='top-selected-div'>{item.selected}</span> */}
                                            </div>
                                            <p className='me-2'>{content?.children.length}</p>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    {content?.children?.map((subcontent, subindex) => (
                                            <div key={subindex} className='acc-content'>
                                                <p className='me-1'>{subcontent?.name}</p>
                                                <Form.Check
                                                    inline
                                                    className='m-0'
                                                />
                                            </div>
                                    ))}
                                    </Accordion.Body>
                                </Accordion.Item>
                              ))
                            )

                        ))}
                    </Accordion>
                </div>
            </section>
        </>
    )
}

export default TournamentSidebar