import React from 'react'
import './VirtualSport.css'
import SportSidebar from './SportSidebar/SportSidebar'
import Sportlistcontent from './sportlistcontent/Sportlistcontent'

const VirtualSport = () => {
    return (
        <>
            <section className='virtual-sport-section'>
                <div className='row p-0 me-0'>
                    <div className='col-xxl-2  col-md-3 '>
                        <div className="first-scroll-div p-0">
                            <SportSidebar />
                        </div>
                    </div>
                    <div className='col-xxl-10  col-md-9 '>
                        <div className="first-scroll-div">
                           <Sportlistcontent />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default VirtualSport