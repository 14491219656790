import React from "react";
import A_vs_B from "../../../common-components/A_vs_B/A_vs_B";
import "./PrivateContest.css";
import Add_cash from "../../Contest/contest-sidebar/add-cash/Add_cash";
import { Link } from "react-router-dom";
function PrivateContest() {
  return (
    <>
      <section className="privateconest">
        <div className="privatecontestmain">
          <div>
            <Add_cash />
            <A_vs_B />
          </div>

          <div>
            <Link to="/fantacy-sport/private-contest">
              <img
                className="teamss"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/privatecontest/privatecontest.png"
                }
                alt="Logo"
              />
            </Link>
          </div>

        </div>
      </section>
    </>
  );
}

export default PrivateContest;
