import React, { useState } from "react";
import "./PlayerDetailed.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";

function PlayerDetailed() {
  const [isCrossIcon, setIsCrossIcon] = useState(false);

  const handleClick = () => {
    setIsCrossIcon(!isCrossIcon);
    // Add your onclick logic here
  };

  return (
    <section className="playerdetails_section">
      <div className="row m-0">
        <div className="col-3">
          <div>
            <img
              className="playerimg"
              src={
                process.env.PUBLIC_URL +
                "/assets/images/privatecontest/privatecontest.png"
              }
              alt="Logo"
            />
          </div>
        </div>
        <div className="col-3 my-auto p-0">
          <div className="plays">
            <p>N Pooran</p>
            <p>LSG | 326.0</p>
          </div>
        </div>
        <div className="col-2 my-auto ">
          <div className="plays" >
            <p className="percents">90%</p>
          </div>
        </div>
        <div className="col-2 my-auto p-0">
          <div className="plays">
            <p className="percents">9</p>
          </div>
        </div>
        <div className="col-2 my-auto p-0">
          <div className={isCrossIcon ? "plusicnss mx-auto" : "plusicn mx-auto"} onClick={handleClick}>
          <FontAwesomeIcon icon={isCrossIcon ? faTimes : faPlus} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default PlayerDetailed;
