import React, { useState } from "react";
import "./ContestCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Button, Offcanvas, Table } from "react-bootstrap";
function ContestCard() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <section className="contestcard">
                <div className="contestcardmain">
                    <div className="constfill">
                        <p>2 contests filled</p>
                    </div>
                    <div className="costprice">
                        <p className="pricecost">$ 51,000</p>
                        <p>$ 25,000</p>
                    </div>
                    <div className="joinbuttons">
                        <div className="winnerss" onClick={handleShow}>
                            <p>
                                Winners : 270{" "}
                                <FontAwesomeIcon
                                    icon="fa-solid fa-chevron-down"
                                    className="winnergreticn"
                                />
                            </p>
                        </div>
                        <Link to={"/fantacy-sport/createnewteam"}>
                            <button className="join_btns">Join</button>
                        </Link>
                    </div>
                    <div className="teamjoined">
                        <FontAwesomeIcon
                            icon="fa-solid fa-circle-dollar-to-slot"
                            className="dollericn"
                        />
                        <p>Teams Joined: 7/50</p>
                    </div>
                </div>
                <div className="botttombarss"></div>
            </section>

            <div>
                <Offcanvas
                    show={show}
                    onHide={handleClose}
                    placement="bottom"
                    className="winnersoffacanvas"
                >
                    <Offcanvas.Header className="offcanheaders" closeButton>
                        <Offcanvas.Title className="winningbreakup">
                            Winnings Prize Break-up
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div>
                            <div className="tableheadmain">
                                <div className="tableheads">
                                    <p className="tabletexthead mb-1">Total Winnings</p>
                                    <p className="tableprizs mb-0">$11.04 Lakhs</p>
                                </div>
                                <div className="tableheads">
                                    <p className="tabletexthead  mb-1">Entry Fee</p>
                                    <p className="tableprizs mb-0">$49</p>
                                </div>
                                <div className="tableheads">
                                    <p className="tabletexthead mb-1">Contest Size</p>
                                    <p className="tableprizs mb-0">31,567</p>
                                </div>
                            </div>
                            <Table striped bordered className="offcantable">
                                <tr>
                                    <td className="">Rank</td>
                                    <td>Prize</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>6-7</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>8-10</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>11-15</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>16-20</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>21-30</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>31-40</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>41-50</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>51-75</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>76-125</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>126-200</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>201-301</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>302-503</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>504-1570</td>
                                    <td>- $150</td>
                                </tr>
                                <tr>
                                    <td>1571-19460</td>
                                    <td>- $150</td>
                                </tr>
                            </Table>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </>
    );
}

export default ContestCard;
