import React, { useRef, useState, useContext } from "react";
import "./Banner.css";
import axios from "axios";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import { Context } from "../../../utils/context";

function Banner({
  firstHomeBanner,
  secondHomeBanner,
  thirdHomeBanner,
  fourthHomeBanner,
}) {
  const { IMG_URL, token, users } = useContext(Context);
  const bannerslider = [
    {
      image: "/assets/images/home/football-img/foot1.jpg",
    },
    {
      image: "/assets/images/home/football-img/foot2.jpg",
    },
    {
      image: "/assets/images/home/football-img/foot1.jpg",
    },
    {
      image: "/assets/images/home/football-img/foot2.jpg",
    },
  ];

  const bannerslider2 = [
    {
      image: "/assets/images/home/football-img/banner1.png",
    },
    {
      image: "/assets/images/home/football-img/banner2.png",
    },
    {
      image: "/assets/images/home/football-img/banner3.png",
    },
    {
      image: "/assets/images/home/football-img/banner1.png",
    },
  ];

  const bannerslider3 = [
    {
      image: "/assets/images/home/football-img/banner1.png",
    },
    {
      image: "/assets/images/home/football-img/banner2.png",
    },
    {
      image: "/assets/images/home/football-img/banner3.png",
    },
  ];

  const bannerslider4 = [
    {
      image: "/assets/images/home/football-img/19Banner-1.jpg",
    },
    {
      image: "/assets/images/home/football-img/5Banner-2.jpg",
    },
    {
      image: "/assets/images/home/football-img/94Banner-3.jpg",
    },
    {
      image: "/assets/images/home/football-img/19Banner-1.jpg",
    },
  ];

  return (
    <section className="footbanner-slide-div mt-2">
      <div className="row mt-lg-3 mb-lg-3  mt-md-2 mb-md-2 mb-2">
        <div className="col-md-12">
          <Swiper
            slidesPerView={2.5}
            spaceBetween={10}
            loop={true}
            pagination={{
              clickable: true,
            }}
            // autoplay={{
            //   delay: 2000,
            //   disableOnInteraction: false,
            // }}
            navigation={true}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
          >
            {firstHomeBanner?.map((data, index) => (
              <SwiperSlide>
                <div className="bann-slider banner-first">
                  <img className="image" src={IMG_URL + data?.image} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <div className="row mt-lg-3 mb-lg-3  mt-md-2 mb-md-2  mb-2">
        <div className="col-md-12">
          <Swiper
            slidesPerView={3}
            spaceBetween={10}
            loop={true}
            pagination={{
              clickable: true,
            }}
            // autoplay={{
            //   delay: 2000,
            //   disableOnInteraction: false,
            // }}
            navigation={true}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
          >
            {secondHomeBanner?.map((data, index) => (
              <SwiperSlide>
                <div className="bann-slider banner-second">
                  <img className="image" src={IMG_URL + data?.image} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <div className="row  mt-lg-3 mb-lg-3  mt-md-2 mb-md-2 mb-2">
        <div className="col-md-12">
          <Swiper
            slidesPerView={6}
            spaceBetween={10}
            loop={true}
            pagination={{
              clickable: true,
            }}
            // autoplay={{
            //   delay: 2000,
            //   disableOnInteraction: false,
            // }}
            navigation={true}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
          >
            {thirdHomeBanner?.map((data, index) => (
              <SwiperSlide>
                <div className="bann-slider banner-third">
                  <img className="image" src={IMG_URL + data?.image} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <div className="row mt-lg-3 mb-lg-3  mt-md-2 mb-md-2 ">
        <div className="col-md-12 p-0">
          <Swiper
            slidesPerView={3}
            spaceBetween={10}
            loop={true}
            pagination={{
              clickable: true,
            }}
            // autoplay={{
            //   delay: 2000,
            //   disableOnInteraction: false,
            // }}
            navigation={true}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
          >
            {bannerslider4.map((data, index) => (
              <SwiperSlide>
                <div className="bann-slider banner-second">
                  <img className="image" src={data.image} />
                </div>
              </SwiperSlide>

            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}

export default Banner;
