import React from 'react'
import './ExchangeRoutesMain.css'
// import ExchangeRoutes from '../ExchangeRoutes/ExchangeRoutes'
import ExchangeSidebar from '../ExchangeSidebar/ExchangeSidebar'
import ExchangeProfileRoutes from '../ExchangeRoutes/ExchangeProfileRoutes'
import Exchangemobmenudiv from './Exchangemobmenudiv'

const ExchangeRoutesProfile = () => {
    return (
        <>
            <section className="ex-home-main-section">
                <div className="container-fluid ">
                    <div className="row">
                        <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-6 p-0 order-2 order-md-1 exchnagefirstbar">
                            <div className="ex-sidebar">
                                <ExchangeSidebar />
                            </div>
                        </div>

                        <div className="col-xxl-10 col-xl-10 col-lg-10  col-md-12 col-sm-12 order-1 order-md-2 p-0">
                            <div className="ex-mid-div">
                                <ExchangeProfileRoutes />
                            </div>
                            <Exchangemobmenudiv />
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default ExchangeRoutesProfile