import React from "react";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
const SecureAuth = () => {
    return (
        <>
            <section className="exchangeprofilemain-sec">
                <div className="secttiill">
                    <div className="titlediv">
                        <p className="title">Secure Auth Verification</p>
                    </div>
                </div>
                <div className="Secure-Auth">
                    <div className="disable">
                        <p className="text-secure me-3 ">Secure Auth Verification Status:</p>
                        <Button variant="primary" className="disable">
                            Disabled
                        </Button>
                    </div>
                    <p className="text">Please select below option to enable secure auth verification</p>
                    <div className="secure-tab mt-3">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Nav variant="pills" className="main-tab justify-content-center">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Enable Using Mobile App</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">Enable Using Telegram</Nav.Link>
                                </Nav.Item>
                            </Nav>

                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className="first-content">
                                        <p className="text mt-3">
                                            Please enter below auth code in your 'Secure Auth Verification App'.
                                        </p>
                                        <div className="code-no">
                                            <p className="code-text">457322</p>
                                        </div>
                                        <p className="text">If you haven't downloaded,</p>
                                        <p className="text">
                                            please download 'Secure Auth Verification App' from below link.
                                        </p>
                                        <p className="text mt-5">
                                            Using this app you will receive auth code during login authentication
                                        </p>
                                        <div className="android mt-2">
                                            <div className="logo-android">
                                                <img
                                                    className="logo"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        "/assets/images/exchange/demo18/robot.png"
                                                    }
                                                    alt="Logo"
                                                />
                                                <div className="text">
                                                    <p className="download-one">DOWNLOAD</p>
                                                    <p className="download-two">on the android</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div className="second-tab">
                                        <p className="text mt-5">Please enter your login password to continue</p>
                                        <div className="login-id">
                                            <Form.Control type="text" placeholder="Enter Your Login Password" />
                                            <Button variant="primary" className="id">Get Connection Id</Button>
                                        </div>
                                       
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SecureAuth;
