import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./InfoMissing.css";

const InfoMissing = (props) => {
  return (
    <>
      <section>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="missing-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Information missing
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Following details are required to initiate successful
              refunds/reimbursements prior to data deletion:
            </p>
            <div className="modal-bg">
              <ul>
                <li>
                  KYC is pending. To complete{" "}
                  <button className="click-btn">click here</button>
                </li>
                <li>
                  Bank A/c details are required. To provide{" "}
                  <button className="click-btn">click here</button>
                </li>
                <li>PAN Verification is required after completing KYC</li>
              </ul>
            </div>
          </Modal.Body>
          
        </Modal>
      </section>
    </>
  );
};

export default InfoMissing;
