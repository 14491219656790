import React from 'react'
import { Link } from 'react-router-dom'
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Countdown from 'react-countdown';

const Add_cash = () => {

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return;
        } else {
            // Render a countdown
            return <span>{hours}:{minutes}:{seconds}</span>;
        }
    };
    return (
        <>
            <section className='fantacy-sidebar'>
                <div className='sidebar-header'>

                    <Link to="/fantacy-sport/fantacytab"><FontAwesomeIcon icon="fa-solid fa-arrow-left" className='bar-icon' /></Link>

                    <div className='settimeout'>
                        <p>Contest</p>
                        <Countdown
                            date={Date.now() + 500000}
                            renderer={renderer}
                        />

                    </div>

                    <Link to={"/fantacy-sport/addcash"}>
                        <button type="button" class="header_btn"><span class="px-1">Add Cash</span> <span className='plus-div'>+</span></button>

                    </Link>
                </div>
            </section>

        </>
    )
}

export default Add_cash