import React from "react";
import "./Accordian_Content.css";
import { Accordion } from "react-bootstrap";

function Accordian_Content() {
  return (
    <>
      <div className="Accordian_Content">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header >
            <p className="xs-text mb-0"> Man City - Aston Villa Online Betting: Odds, Forecasts, Online
              Broadcast
              </p>
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <h3 className="subheading-text ">
                  Historical Performance Man City - Aston Villa
                </h3>

                <p className="xs-text">
                  04.04.2024 at 00:45 in the Premier League will be a match
                  between Man City and Aston Villa teams. Here you can see the
                  betting odds for the event, latest performance of both teams.
                </p>
              </div>

              <div>
                <h3 className="subheading-text ">
                  Historical Performance Man City - Aston Villa
                </h3>

                <p className="xs-text">
                  04.04.2024 at 00:45 in the Premier League will be a match
                  between Man City and Aston Villa teams. Here you can see the
                  betting odds for the event, latest performance of both teams.
                </p>
              </div>

              <div>
                <h3 className="subheading-text ">
                  Historical Performance Man City - Aston Villa
                </h3>

                <p className="xs-text">
                  04.04.2024 at 00:45 in the Premier League will be a match
                  between Man City and Aston Villa teams. Here you can see the
                  betting odds for the event, latest performance of both teams.
                </p>
              </div>

              <div>
                <h3 className="subheading-text ">
                  Historical Performance Man City - Aston Villa
                </h3>

                <p className="xs-text">
                  04.04.2024 at 00:45 in the Premier League will be a match
                  between Man City and Aston Villa teams. Here you can see the
                  betting odds for the event, latest performance of both teams.
                </p>
              </div>


            

            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
}

export default Accordian_Content;
