import React from "react";
import "./Score_Card_tab.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Score_Card_tab({event}) {

  console.log(event,'inner');
  return (
    <>
      <div className="Score_Card_tab">
        <div className="Vs_Country_Score">
          <div className="row justify-content-center">

            <div className="col-md-3">
              <div className="img-text-holder">
                <div className="img-holder">
                  <img
                    className="flag-icon"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/Game_details/Newcastle_flag.png"
                    }
                  />
                </div>
                <p>{event?.opponents?.[0]?.description}</p>
              </div>
            </div>


            <div className="col-md-3">
              <div className="img-text-holder">
                <span>{event?.currentPeriod}</span>
                <h4>
                  {/* <FontAwesomeIcon
                    className="bat-ball-icon"
                    icon="fa-solid fa-baseball-bat-ball"
                  /> */}
                  {event?.scores?.score?.[0]?.points}:{event?.scores?.score?.[1]?.points}
                </h4>
                {/* <span>150/4(38.5):157/7</span> */}
              </div>
            </div>

            <div className="col-md-3">
              <div className="img-text-holder">
                <div className="img-holder">
                  <img
                    className="flag-icon"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/Game_details/Everton_flag.png"
                    }
                  />
                </div>

                <p>{event?.opponents?.[1]?.description}</p>
              </div>
            </div>

          </div>
        </div>

        <div className="innings">
          <div className="row">
            <div className="col-md-8">
              <div className="text-holder">
                <h2>INNINGS 2</h2>
              </div>
            </div>

            <div className="col-md-4">
              <div className="score-holder">
                <div className="row">
                  <div className="col-md-4">
                    <div className="text-holder">
                      <h2>1</h2>
                    </div>
                  </div>

                  {/* <div className="col-md-4">
                    <div className="text-holder">
                      <h2>2</h2>
                    </div>
                  </div> */}

                  <div className="col-md-4">
                    <div className="text-holder">
                      <h2>T</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <div className="text-holder">
                <p>{event?.opponents?.[0]?.description}</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="score-holder">
                <div className="row">
                  <div className="col-md-4">
                    <div className="text-holder">
                      <p>{event?.scores?.score?.[0]?.points}</p>
                    </div>
                  </div>

                  {/* <div className="col-md-4">
                    <div className="text-holder">
                      <p>150/4(38.5)</p>
                    </div>
                  </div> */}

                  <div className="col-md-4">
                    <div className="text-holder">
                      <p className="total-score">{event?.scores?.score?.[1]?.points}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <div className="text-holder">
                <p>{event?.opponents?.[1]?.description}</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="score-holder">
                <div className="row">
                  <div className="col-md-4">
                    <div className="text-holder">
                      <p>{event?.scores?.score?.[0]?.points}</p>
                    </div>
                  </div>

                  {/* <div className="col-md-4">
                    <div className="text-holder">
                      <p>157/7</p>
                    </div>
                  </div> */}

                  <div className="col-md-4">
                    <div className="text-holder">
                      <p className="total-score">{event?.scores?.score?.[1]?.points}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Score_Card_tab;
