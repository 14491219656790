import React from "react";
import "./Game_Partners_Content.css";

function Game_Partners_Content() {
    return (
        <>
            <div className="Game_Partners_Content">
                <div className="Partners_heading_holder">
                    <h1 className="heading-text">
                        Trusted Sponsors & Partners Of
                    </h1>
                </div>

                <div className="partner-img-holder">
                    <div className="img-holder">
                        <img
                            className="partner-img"
                            src={
                                process.env.PUBLIC_URL +
                                "/assets/images/Game_details/Partner1.png"
                            }
                        />
                    </div>

                    <div className="img-holder">
                        <img
                            className="partner-img"
                            src={
                                process.env.PUBLIC_URL +
                                "/assets/images/Game_details/Partner2.png"
                            }
                        />
                    </div>

                    <div className="img-holder">
                        <img
                            className="partner-img"
                            src={
                                process.env.PUBLIC_URL +
                                "/assets/images/Game_details/partner4.png"
                            }
                        />
                    </div>

                    <div className="img-holder">
                        <img
                            className="partner-img"
                            src={
                                process.env.PUBLIC_URL +
                                "/assets/images/Game_details/Partner4.png"
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Game_Partners_Content;
