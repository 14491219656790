import React,{ useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import PlayGround from "../../FantacyModals/PlayGround/PlayGround";
import "./OnlyContest.css";
const OnlyContest = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [open, setOpen] = useState(false);
  return (
    <>
      <section className="only-contest fantacy-sidebar">
        <div className="sidebar-header">
          <Link to="/fantacy-sport/completed-contest">
            <FontAwesomeIcon
              icon="fa-solid fa-arrow-left"
              className="bar-icon"
            />
          </Link>
          <div className="text-center">
            <p>Contests</p>
          </div>
          <Link to="/fantacy-sport/addcash">
            <button type="button" class="header_btn">
              <span class="px-1">Add Cash</span>{" "}
              <span className="plus-div">+</span>
            </button>
          </Link>
        </div>
        <div className="vs-section">
          <div className="mainvs">
            <div className="teamss_div ">
              <img
                className="teamss"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/home/sidebar/3537.png"
                }
                alt="Logo"
              />
              <h4 className="teamname">MUM</h4>
            </div>
            <div className="teamss_div11">
              <div className="pills-div">
                <p>111-104</p>
              </div>
              <p className="ft">FT</p>
            </div>
            <div className="teamss_div">
              <h4 className="teamname">BLR</h4>
              <img
                className="teamss"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/home/sidebar/3573.png"
                }
                alt="Logo"
              />
            </div>
          </div>
        </div>

        <div
          className="full-scored-btn"
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
        >
          <span>View Summary</span>
          <FontAwesomeIcon icon="fa-solid fa-arrow-down" />
        </div>
        <Collapse in={open}>
        <div id="example-collapse-text" >
          <div className='summary-sec'>
         <div className='dash-border'>

         </div>
            <Link to="/fantacy-sport/scorecard"><button className='scorecard-btn'>Full ScoreCard</button></Link>
          </div>
        </div>
      </Collapse>

        <div className="view-play-card">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12 text-center left-border">
              <div className="viewplayer-text">
                <Link to="/fantacy-sport/status">View Player Stats</Link>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12 text-center">
              <button className="ulti-btn" onClick={() => setModalShow(true)}>
                Ultimate Team
                <div className="score-text">Score :1089</div>
              </button>
            </div>
          </div>
          <div className="row mt-3 mx-0 p-0">
            <div className="col-xxl-12 col-xl-12 col-12 p-0">
              <div className="winner-sec">
                <div>
                  <p className="title">Total Winnings</p>
                  <p className="numb">$13,620</p>
                </div>
                <div>
                  <p className="title">Total Winnings</p>
                  <p className="numb">$13,620</p>
                </div>
                <div>
                  <p className="title">Total Winnings</p>
                  <p className="numb">$13,620</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-container">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="first">
                  <img
                    className="nav-img"
                    src="/assets/images/fantacy/cup.png"
                    alt="Logo"
                  />
                  PRIZE BREAKUP
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">
                  <img
                    className="nav-img"
                    src="/assets/images/fantacy/board.png"
                    alt="Logo"
                  />
                  LEADERBOARD
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="top-row">
                  <div className="row">
                    <div className="col-xxl-5 col-5 text-end">
                      <p>RANK</p>
                    </div>
                    <div className="col-xxl-1 col-1 text-center"></div>
                    <div className="col-xxl-5 col-5">
                      <p>PRIZES</p>
                    </div>
                  </div>
                </div>
                <div className="first-row">
                  <div className="row">
                    <div className="col-xxl-5 col-5 text-end">
                      <p>80</p>
                    </div>
                    <div className="col-xxl-1 col-1 text-center">-</div>
                    <div className="col-xxl-5 col-5">
                      <p>78,000</p>
                    </div>
                  </div>
                </div>
                <div className="first-row">
                  <div className="row">
                    <div className="col-xxl-5 col-5 text-end">
                      <p>80</p>
                    </div>
                    <div className="col-xxl-1 col-1 text-center">-</div>
                    <div className="col-xxl-5 col-5">
                      <p>786</p>
                    </div>
                  </div>
                </div>
                <div className="first-row">
                  <div className="row">
                    <div className="col-xxl-5 col-5 text-end">
                      <p>80</p>
                    </div>
                    <div className="col-xxl-1 col-1 text-center">-</div>
                    <div className="col-xxl-5 col-5">
                      <p>78,000</p>
                    </div>
                  </div>
                </div>
                <div className="first-row">
                  <div className="row">
                    <div className="col-xxl-5 col-5 text-end">
                      <p>80</p>
                    </div>
                    <div className="col-xxl-1 col-1 text-center">-</div>
                    <div className="col-xxl-5 col-5">
                      <p>786</p>
                    </div>
                  </div>
                </div>
                <div className="note">
                  <p>
                    <b>Note:</b> In case of a tie or if the contest does not
                    fill up, the actual prizes will be different. As per the
                    government regulations, a tax of 30% will be deducted if net
                    winnings of an individual is more than Rs. 10000.
                  </p>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <div className="sec-tab">
                  <div className="top-row">
                    <div className="row">
                      <div className="col-xxl-1 col-1 "></div>
                      <div className="col-xxl-3 col-3 ">
                        <p>RANK</p>
                      </div>
                      <div className="col-xxl-5 col-5">
                        <p>Team</p>
                      </div>
                      <div className="col-xxl-3 col-3">
                        <p>PRIZES</p>
                      </div>
                    </div>
                  </div>
                  <div className="first-row">
                    <div className="row">
                      <div className="col-xxl-1 col-1 ">
                        <img
                          className="nav-img"
                          src="/assets/images/fantacy/medal.png"
                          alt="Logo"
                        />
                      </div>
                      <div className="col-xxl-3 col-3 ">
                        <p>80</p>
                        <p>$67</p>
                      </div>
                      <div className="col-xxl-5 col-5">
                        <p>alpha8972ga5(T1)</p>
                      </div>
                      <div className="col-xxl-3 col-3">
                        <p>765</p>
                      </div>
                    </div>
                  </div>
                  <div className="first-row">
                    <div className="row">
                      <div className="col-xxl-1 col-1 ">
                        <img
                          className="nav-img"
                          src="/assets/images/fantacy/medal.png"
                          alt="Logo"
                        />
                      </div>
                      <div className="col-xxl-3 col-3 ">
                        <p>80</p>
                        <p>$67</p>
                      </div>
                      <div className="col-xxl-5 col-5">
                        <p>alpha8972ga5(T1)</p>
                      </div>
                      <div className="col-xxl-3 col-3">
                        <p>765</p>
                      </div>
                    </div>
                  </div>
                  <div className="first-row">
                    <div className="row">
                      <div className="col-xxl-1 col-1 ">
                        <img
                          className="nav-img"
                          src="/assets/images/fantacy/medal.png"
                          alt="Logo"
                        />
                      </div>
                      <div className="col-xxl-3 col-3 ">
                        <p>80</p>
                        <p>$67</p>
                      </div>
                      <div className="col-xxl-5 col-5">
                        <p>alpha8972ga5(T1)</p>
                      </div>
                      <div className="col-xxl-3 col-3">
                        <p>765</p>
                      </div>
                    </div>
                  </div>
                  <div className="first-row">
                    <div className="row">
                      <div className="col-xxl-1 col-1 ">
                        <img
                          className="nav-img"
                          src="/assets/images/fantacy/medal.png"
                          alt="Logo"
                        />
                      </div>
                      <div className="col-xxl-3 col-3 ">
                        <p>80</p>
                        <p>$67</p>
                      </div>
                      <div className="col-xxl-5 col-5">
                        <p>alpha8972ga5(T1)</p>
                      </div>
                      <div className="col-xxl-3 col-3">
                        <p>765</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>

        <PlayGround show={modalShow} onHide={() => setModalShow(false)} />
      </section>
    </>
  );
};

export default OnlyContest;
