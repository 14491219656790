import React from "react";
import { Route, Routes } from "react-router-dom";
import FantacySport from "../FantacySport";
import ContestPrivate from "../ContestPrivate/ContestPrivate";
import Inner_private_contest from "../ContestPrivate/PrivateContest/Inner-private-contest/Inner_private_contest";
import CreateNewTeam from "../CreateNewTeam/CreateNewTeam";
import Contest_sidebar from "../Contest/contest-sidebar/Contest_sidebar";
import AddCashSidebar from "../AddCash/AddCashSidebar/AddCashSidebar";
import FantacyFootball from "../FantacyFootball/FantacyFootball";
import AccountDetailsSidebar from "../AddCash/AccountDetails/AccountDetailsSidebar/AccountDetailsSidebar";
import PlayerList from "../CreateNewTeam/PlayerList/PlayerList";
import JoinTeam from "../CreateNewTeam/JoinTeam/JoinTeam";
import PriceBreakup from "../PriceBreakup/PriceBreakup";
import MyAccount from "../FantacyOffCanvasPages/MyAccountMain/MyAccount/MyAccount";
import AccountOverview from "../FantacyOffCanvasPages/MyAccountMain/AccountOverview/AccountOverview";
import WithrawCash from "../FantacyOffCanvasPages/MyAccountMain/WithrawCash/WithrawCash";
import OtherDetails from "../FantacyOffCanvasPages/MyAccountMain/OtherDetails/OtherDetails";
import ChangeUsername from "../FantacyOffCanvasPages/MyAccountMain/AccountDetails/ChangeUsername";
import AddEmail from "../FantacyOffCanvasPages/MyAccountMain/AccountDetails/AddEmail";
import AddAdress from "../FantacyOffCanvasPages/MyAccountMain/AddAdress/AddAdress";
import { ViewList } from "@progress/kendo-react-dateinputs";
import ViewTransition from "../FantacyOffCanvasPages/ViewTransitionMain/ViewTransition/ViewTransition";
import JoinContest from "../FantacyOffCanvasPages/JoinContest/JoinContest";
import WithdrawHistory from "../FantacyOffCanvasPages/WithdrawHistory/WithdrawHistory";
import JoinContestComp from "../CompletedTab/JoinContestComp/JoinContestComp";
import ScoreCard from "../CompletedTab/ScoreCard/ScoreCard";
import PlayerStatus from "../CompletedTab/PlayerStatus/PlayerStatus";
import OnlyContest from "../CompletedTab/OnlyContest/OnlyContest";

const FantacyRoutes = () => {
  return (
    <>
      <div style={{ backgroundColor: "#ede6e6" }}>
        <Routes>
          <Route path="/fantacytab" element={<FantacySport />} />
          <Route path="/addcash" element={<AddCashSidebar />} />
          <Route path="/contest" element={<Contest_sidebar />} />
          <Route path="/private" element={<ContestPrivate />} />
          <Route path="/private-contest" element={<Inner_private_contest />} />
          <Route path="/createnewteam" element={<PlayerList />} />
          <Route path="/accountdetails" element={<AccountDetailsSidebar />} />
          <Route path="/fantfoot" element={<FantacyFootball />} />
          <Route path="/jointeam" element={<JoinTeam />} />
          <Route path="/pricebreakup" element={<PriceBreakup />} />

          {/* offcanvasrouts start */}
          <Route path="/myaccount" element={<MyAccount />} />
          <Route path="/overview" element={<AccountOverview />} />
          <Route path="/withdraw-cash" element={<WithrawCash />} />

            <Route path="/other-details" element={<OtherDetails />} />
          <Route path="/Change-username" element={<ChangeUsername />} />
          <Route path="/add-email" element={<AddEmail />} />
          <Route path="/add-adress" element={<AddAdress />} />
          <Route path="/view-transition" element={<ViewTransition />} />
          <Route path="/join-contest" element={<JoinContest />} />
          <Route path="/withdraw-history" element={<WithdrawHistory/>} />

          {/* completed tabs */}
          <Route path="/completed-contest" element={<JoinContestComp/>} />

          <Route path="/scorecard" element={<ScoreCard/>} />
          <Route path="/status" element={<PlayerStatus/>} />
          <Route path="/onlycontests" element={<OnlyContest/>} />
        </Routes>
      </div>
    </>
  );
};

export default FantacyRoutes;
