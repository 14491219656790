import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import "./ViewTransition.css";
const ViewTransition = () => {
  return (
    <>
      <section className="fantacy-sidebar view-transition-sec">
        <div className="sidebar-header">
          <Link to="/fantacy-sport/fantfoot">
            <FontAwesomeIcon
              icon="fa-solid fa-arrow-left"
              className="bar-icon"
            />
          </Link>
          <img
            className="headlogo"
            src={process.env.PUBLIC_URL + "/assets/images/logo/logo.png"}
            alt="Logo"
          />
          <Link to="/fantacy-sport/addcash">
            <button type="button" class="header_btn">
              <span class="px-1">Add Cash</span>{" "}
              <span className="plus-div">+</span>
            </button>
          </Link>
        </div>
        <div>
          <div className="row my-2">
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
              <h1>View Transactions</h1>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
              <div>
                <Link>View Transactions</Link>
                <span className="borders"></span>
                <Link>Download Invoice</Link>
              </div>
            </div>
          </div>

          <div className="px-4">
            <Form.Select aria-label="Default select example">
              <option>Other Transitions</option>
              <option value="1">All transitions</option>
              <option value="2">Bouns</option>
              <option value="3">Cashback</option>
            </Form.Select>
            <div className="dont-trans">
              <p>You do not have any transactions in your account</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewTransition;
