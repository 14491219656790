import React from "react";
import "./PlayerList.css";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import CreateNew from "../CreateNew/CreateNew";
import PickPlayers from "../PickPlayers/PickPlayers";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
function PlayerList() {
  return (
    <section className="playerlist-section">
      <div>
        <CreateNew />
        <div className="mainvs">
          <div className="teamss_div ">
            <img
              className="teamss"
              src={
                process.env.PUBLIC_URL + "/assets/images/home/sidebar/3537.png"
              }
              alt="Logo"
            />
            <div>
              <h4 className="teamname">
                MUM <br />
                <span className="goalcount">0</span>
              </h4>
            </div>
          </div>
          <div className="teamss_div11">
            <img
              className="vsimage"
              src={process.env.PUBLIC_URL + "/assets/images/teams/vs.png"}
              alt="Logo"
            />
          </div>
          <div className="teamss_div">
            <h4 className="teamname">
              BLR <br />
              <span className="goalcount">0</span>
            </h4>
            <img
              className="teamss"
              src={
                process.env.PUBLIC_URL + "/assets/images/home/sidebar/3573.png"
              }
              alt="Logo"
            />
          </div>
        </div>
        <div className="creditleftmain">
          <p className="creditleft">
            Credits Left: <span className="creditleftspan"> 100</span>
          </p>
        </div>
        <div className="playercountdiv mx-auto">
          <div className="playercount">1</div>
          <div className="playercount">2</div>
          <div className="playercount">3</div>
          <div className="playercount">4</div>
          <div className="playercount">5</div>
          <div className="playercount">6</div>
          <div className="playercount">7</div>
          <div className="playercount">8</div>
          <div className="playercount">9</div>
          <div className="playercount">10</div>
          <div className="playercount">11</div>
        </div>
        <div className="playerstabmain">
          <Tab.Container id="left-tabs-example" defaultActiveKey="wk" >
            <Nav variant="pills" className="tabbuttons">
              <div className="row w-100 m-0">
                <div className="col-3 p-0">
                  <Nav.Item>
                    <Nav.Link eventKey="wk" >GK <div className="spanss">0</div></Nav.Link>
                  </Nav.Item>
                </div>
                <div className="col-3 p-0">
                  <Nav.Item>
                    <Nav.Link eventKey="batsman">DEF <div className="spanss">0</div></Nav.Link>
                  </Nav.Item>
                </div>
                <div className="col-3 p-0">
                  <Nav.Item>
                    <Nav.Link eventKey="allrounder">MID <div className="spanss">0</div></Nav.Link>
                  </Nav.Item>
                </div>
                <div className="col-3 p-0">
                  <Nav.Item>
                    <Nav.Link eventKey="bowler">FWD <div className="spanss">0</div></Nav.Link>
                  </Nav.Item>
                </div>
              </div>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="wk"><PickPlayers /></Tab.Pane>
              <Tab.Pane eventKey="batsman"><PickPlayers /></Tab.Pane>
              <Tab.Pane eventKey="allrounder"><PickPlayers /></Tab.Pane>
              <Tab.Pane eventKey="bowler"><PickPlayers /></Tab.Pane>

            </Tab.Content>
          </Tab.Container>


        </div>

        <div className="bottombuttons">
          <div className="row mx-0">
            <div className="col-6 mx-auto">
              <div className="premain">
                <button className="previw">Team Preview</button>
              </div>
            </div>
            <div className="col-6 mx-auto">
              <div className="premain">
                <Link to={"/fantacy-sport/jointeam"}><button className="nexts">Next</button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PlayerList;
