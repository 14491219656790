import React from 'react'
import Fantacysidebar from '../Fantacysidebar/Fantacysidebar'
import FantacyRoutes from '../FantacyRoutes/FantacyRoutes'
import "./FantacySportmain.css"

const FantacySportmain = () => {
    return (
        <>
            <section className='FantacySportmain'>
                <div className='klsksl'>
                    <FantacyRoutes />
                </div>
            </section>
        </>
    )
}

export default FantacySportmain