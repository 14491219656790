import React from "react";
import "./Footer_Link_Content.css"

function Footer_Link_Content() {
    return (
        <>
            <div className="Footer_Link_Content">
                <div className="tournaments-list">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="tournaments-list-content-holder">
                                <h1 className="heading-text">Help</h1>

                                <p className="small-text">Game Rules</p>
                                <p className="small-text">Deposit</p>
                                <p className="small-text">Withdrawal</p>
                                <p className="small-text">Support</p>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="tournaments-list-content-holder">
                                <h1 className="heading-text">Parimatch</h1>

                                <p className="small-text">
                                    {" "}
                                    Terms and Conditions
                                </p>
                                <p className="small-text"> Privacy Policy</p>
                                <p className="small-text"> Public Offers</p>
                                <p className="small-text"> AML/KYC Policy</p>
                                <p className="small-text"> For partners</p>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="tournaments-list-content-holder">
                                <h1 className="heading-text">Info</h1>

                                <p className="small-text">
                                    Responsible gambling
                                </p>
                                <p className="small-text"> Self-exclusion</p>
                                <p className="small-text">
                                    {" "}
                                    Dispute resolution
                                </p>
                                <p className="small-text">
                                    {" "}
                                    Privacy & Management of Personal Data
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer_Link_Content;
