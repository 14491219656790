import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import Accordian_Content from "../common-components/Game_Details/Game_Details_Page/Parlays/Parlays_Tab_Content/Accordian_Content/Accordian_Content";
import Accordian_Que_Content from "../common-components/Game_Details/Game_Details_Page/Parlays/Parlays_Tab_Content/Accordian_Que_Content/Accordian_Que_Content";
import Accordian_Tournaments_Content from "../common-components/Game_Details/Game_Details_Page/Parlays/Parlays_Tab_Content/Accordian_Tournaments_Content/Accordian_Tournaments_Content";
import Game_Details_Footer from "../common-components/Game_Details/Game_Details_Page/Parlays/Parlays_Tab_Content/Game_Details_Footer/Game_Details_Footer";
const Footer = () => {
  return (
    <>
      <div className=" Game_Details_Page ">
        <div className="white-bg">
          <Accordian_Content />
        </div>

        <div className="white-bg">
          <Accordian_Que_Content />
        </div>

        <div className="white-bg">
          <Accordian_Tournaments_Content />

          <Game_Details_Footer />
        </div>
      </div>
    </>
  );
};

export default Footer;
