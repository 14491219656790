import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./JoinContestComp.css";
import UpcomingCard from "../../FantacyCards/UpcomingCard";
import LiveCard from "../../FantacyCards/LiveCard";
import ProgressBar from "react-bootstrap/ProgressBar";
import PlayGround from "../../FantacyModals/PlayGround/PlayGround";
const JoinContestComp = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const livedata = [
    {
      title: "European FootBall – Portugal T10",
      team1: "OE CC",
      logo1: "/assets/images/home/sidebar/3537.png",
      team2: "FR CC",
      logo2: "/assets/images/home/sidebar/3537.png",
      status: "Preparing",
      link: "/fantacy-sport/contest",
    },
  ];
  return (
    <>
      <section className="completed-contest fantacy-sidebar">
        <div className="sidebar-header">
          <Link to="/fantacy-sport/fantfoot">
            <FontAwesomeIcon
              icon="fa-solid fa-arrow-left"
              className="bar-icon"
            />
          </Link>
          <div className="text-center">
            <p>Joined Contests</p>
            <h6>Completed</h6>
          </div>
          <Link to="/fantacy-sport/addcash">
            <button type="button" class="header_btn">
              <span class="px-1">Add Cash</span>{" "}
              <span className="plus-div">+</span>
            </button>
          </Link>
        </div>
        <div className="vs-section">
          <div className="mainvs">
            <div className="teamss_div ">
              <img
                className="teamss"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/home/sidebar/3537.png"
                }
                alt="Logo"
              />
              <h4 className="teamname">MUM</h4>
            </div>
            <div className="teamss_div11">
              <div className="pills-div">
                <p>111-104</p>
              </div>
              <p className="ft">FT</p>
            </div>
            <div className="teamss_div">
              <h4 className="teamname">BLR</h4>
              <img
                className="teamss"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/home/sidebar/3573.png"
                }
                alt="Logo"
              />
            </div>
          </div>
        </div>
        <Link to="/fantacy-sport/scorecard">
          <div className="full-scored-btn">
            <span>Full Scorecard</span>
            <FontAwesomeIcon icon="fa-solid fa-arrow-down" />
          </div>
        </Link>
        <div className="view-play-card">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12 text-center left-border">
              <div className="viewplayer-text">
                <Link to="/fantacy-sport/status">View Player Stats</Link>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12 text-center">
              <button className="ulti-btn" onClick={() => setModalShow(true)}>
                Ultimate Team
                <div className="score-text">Score :1089</div>
              </button>
            </div>
          </div>
          <div className="row mt-3 mx-0 p-0">
            <div className="col-xxl-11 col-xl-11 col-10 p-0">
              <div className="winner-sec">
                <div>
                  <p className="title">Total Winnings</p>
                  <p className="numb">$13,620</p>
                </div>
                <div>
                  <p className="title">Total Winnings</p>
                  <p className="numb">$13,620</p>
                </div>
                <div>
                  <p className="title">Total Winnings</p>
                  <p className="numb">$13,620</p>
                </div>
              </div>
              <div className="miss-opp-div">
                <p>You missed an oppurtunity to win</p>
                <p>$13,620</p>
              </div>
            </div>
            <div className="col-xxl-1 col-xl-1 col-2 p-0">
              <Link to="/fantacy-sport/onlycontests">
              <div className="flag-img-div">
                <img
                  className="flg"
                  src="/assets/images/fantacy/flag-icon.png"
                  alt="Logo"
                />
              </div></Link>
            </div>
          </div>
        </div>
        <div className="join-scroll">
          <div className="join-contest">
            <p>JOIN UPCOMING CONTESTS</p>
          </div>
          <div className="cards-bg">
            <LiveCard livevents={livedata} />
            <div className="hot-contest">
              <div className="contestcardmain">
                <div className="constfill">Hot Contest</div>
                <div className="costprice">
                  <p>$ 25,000</p>
                </div>
                <div className="joinbuttons">
                  <div>
                    <p className="pricecost">$ 51,000</p>
                  </div>
                  <div>
                    <button className="of-canva-btn">
                      650 <FontAwesomeIcon icon="fa-solid fa-arrow-down" />
                    </button>
                  </div>
                  <Link to={"/fantacy-sport/createnewteam"}>
                    <button className="join_btns">Join</button>
                  </Link>
                </div>
                <div className="teamjoined mb-3">
                  <p>Teams Joined: 7/50</p>
                </div>
                <div className="text-end">
                  <p className="date">Teams Joined: 18/2022</p>
                </div>
                <ProgressBar now={60} />
              </div>
            </div>
            <div className="text-end">
              <Link to="/fantacy-sport/contest">
              <button className="view-contentens">View Other Contests</button>
              </Link>
              
            </div>
          </div>
        </div>
        <PlayGround show={modalShow} onHide={() => setModalShow(false)} />
      </section>
    </>
  );
};

export default JoinContestComp;
