import React,{useState, useEffect, useContext} from 'react'
import './TournamentUpcoming.css'
import Accordion from 'react-bootstrap/Accordion';
import Form from "react-bootstrap/Form";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { NavLink } from 'react-router-dom';
import { Context } from '../../../../../utils/context';
library.add(fas);


const TournamentUpcoming = () => {


    
    const { getGameData } = useContext(Context);

    const [competitions, setCompetion] = useState([]);
    const getCompetitions = async () => {
        const res = await getGameData('getSports');
        setCompetion(res);
    }

    useEffect(() => {
        getCompetitions();
    }, []);
    return (
        <>
            <section className='tournament-upcoming'>
                <p className='heading'>ALL UPCOMING</p>
                <div className='accordians'>
                    <Accordion defaultActiveKey='' alwaysOpen>
                    {competitions?.map(item => (
                         item?.name === 'Football' && (
                            item?.children?.map((content, index) => (
                        <Accordion.Item key={index} eventKey={index}>
                            <Accordion.Header>
                                <div className='acc-header'>
                                    <div className='img-div'>
                                        <img className='flag-img me-3' src={process.env.PUBLIC_URL + '/assets/images/home/sidebar/ind.png'} alt="Logo" />
                                        <span>{content?.name}</span>
                                    </div>
                                    <p className='me-2'>{content?.children.length}</p>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                            {content?.children?.map((subcontent, subindex) => (
                                <NavLink>
                                    <div className='acc-content'  key={subindex}>
                                        <p className='me-1'>{subcontent?.name}</p>
                                        <FontAwesomeIcon icon="fa-solid fa-chevron-right" className='arrow' />
                                    </div>
                                </NavLink>
                            ))}
                                

                            </Accordion.Body>
                        </Accordion.Item>
  ))
)
                    ))}

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <div className='acc-header'>
                                    <div className='img-div'>
                                        <img className='flag-img me-3' src={process.env.PUBLIC_URL + '/assets/images/home/sidebar/eng.png'} alt="Logo" />
                                        <span>England</span>
                                    </div>
                                    <p className='me-2'>2</p>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <NavLink>
                                    <div className='acc-content'>
                                        <p className='me-1'>Professional Development League</p>
                                        <FontAwesomeIcon icon="fa-solid fa-chevron-right" className='arrow' />
                                    </div>
                                </NavLink>
                                <NavLink>
                                    <div className='acc-content'>
                                        <p className='me-1'>Goa. Pro League</p>
                                        <FontAwesomeIcon icon="fa-solid fa-chevron-right" className='arrow' />
                                    </div>
                                </NavLink>

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </section>
        </>
    )
}

export default TournamentUpcoming