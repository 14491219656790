import React, { useState, useEffect, useContext } from "react";
import './Football_cards.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { Context } from '../../../../utils/context';
import { getMarketsList, getRunners, getTodayMatch } from '../../../../utils/apis/common/Common';


const Football_cards = ({ lives,setTeamtwo, setTeamOne, setRunnerId, events }) => {
    console.log(events, "eveveve");
    const { IMG_URL, token, users } = useContext(Context);
    const [todayMatch, setTodaysMatch] = useState([]);

    const getmarketdata = async (eventId) => {
        const res = await getMarketsList(eventId);
        console.log("Today Match", res);
        setTodaysMatch(res);
        console.log(res, "matchwin");

    }
    const footballcards = [
        {
            matchname: 'National teams. Test match. 2nd Test',
            contry_img1: '/assets/images/home/sidebar/3573.png',
            contry_name1: 'Newcastle',
            contry_img2: '/assets/images/home/sidebar/3537.png',
            contry_name2: 'Everton',
            match_day: 'Tomorrow',
            match_timimg: '00:00',
            btn1_points: '3.40',
            btnx_points: '2.28',
            btn2_points: '3.10',
        },
        {
            matchname: 'National teams. Test match. 2nd Test',
            contry_img1: '/assets/images/home/sidebar/3573.png',
            contry_name1: 'Newcastle',
            contry_img2: '/assets/images/home/sidebar/3537.png',
            contry_name2: 'Everton',
            match_day: 'Tomorrow',
            match_timimg: '00:00',
            btn1_points: '3.40',
            btnx_points: '2.28',
            btn2_points: '3.10',
        },
        {
            matchname: 'National teams. Test match. 2nd Test',
            contry_img1: '/assets/images/home/sidebar/3573.png',
            contry_name1: 'Newcastle',
            contry_img2: '/assets/images/home/sidebar/3537.png',
            contry_name2: 'Everton',
            match_day: 'Tomorrow',
            match_timimg: '00:00',
            btn1_points: '3.40',
            btnx_points: '2.28',
            btn2_points: '3.10',
        },
        {
            matchname: 'National teams. Test match. 2nd Test',
            contry_img1: '/assets/images/home/sidebar/3573.png',
            contry_name1: 'Newcastle',
            contry_img2: '/assets/images/home/sidebar/3537.png',
            contry_name2: 'Everton',
            match_day: 'Tomorrow',
            match_timimg: '00:00',
            btn1_points: '3.40',
            btnx_points: '2.28',
            btn2_points: '3.10',
        },
        {
            matchname: 'National teams. Test match. 2nd Test',
            contry_img1: '/assets/images/home/sidebar/3573.png',
            contry_name1: 'Newcastle',
            contry_img2: '/assets/images/home/sidebar/3537.png',
            contry_name2: 'Everton',
            match_day: 'Tomorrow',
            match_timimg: '00:00',
            btn1_points: '3.40',
            btnx_points: '2.28',
            btn2_points: '3.10',
        },
        {
            matchname: 'National teams. Test match. 2nd Test',
            contry_img1: '/assets/images/home/sidebar/3573.png',
            contry_name1: 'Newcastle',
            contry_img2: '/assets/images/home/sidebar/3537.png',
            contry_name2: 'Everton',
            match_day: 'Tomorrow',
            match_timimg: '00:00',
            btn1_points: '3.40',
            btnx_points: '2.28',
            btn2_points: '3.10',
        },
        {
            matchname: 'National teams. Test match. 2nd Test',
            contry_img1: '/assets/images/home/sidebar/3573.png',
            contry_name1: 'Newcastle',
            contry_img2: '/assets/images/home/sidebar/3537.png',
            contry_name2: 'Everton',
            match_day: 'Tomorrow',
            match_timimg: '00:00',
            btn1_points: '3.40',
            btnx_points: '2.28',
            btn2_points: '3.10',
        },

    ];
    useEffect(() => {
        if (events) {
            const eventId = events[0]?.event?.id;
            if (eventId) {
                getmarketdata(eventId);
            }
        }
    }, [events]);


    return (
        <>
            <section className='Football_cards_sec'>
                <h6 className='carddd-title'><img className='iccc' src={process.env.PUBLIC_URL + '/assets/images/home/sidebar/football.png'} /> Soccer</h6>

                <div className='card-sliderss'>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        loop={true}
                        pagination={{
                            clickable: false,
                        }}
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"

                        breakpoints={{

                            '768': {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            '991': {
                                slidesPerView: 2,
                                spaceBetween: 5,
                            },
                            '1200': {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            '1400': {
                                slidesPerView: 3,
                                spaceBetween: 10,
                            },
                            '1800': {
                                slidesPerView: 4,
                                spaceBetween: 10,
                            },
                            '2000': {
                                slidesPerView: 4,
                                spaceBetween: 10,
                            },
                            '2500': {
                                slidesPerView: 4,
                                spaceBetween: 10,
                            },
                        }}
                    >
                        {lives?.sports?.map((value, index) => (
                                    value?.name === 'Football' && (

                                    value?.events?.map((event, eventIndex) => (
                            <SwiperSlide key={event?.id}>
                                <Link to="/game-details">
                                    <div className='football-slider-card'>
                                        <p className='matname'>{event?.eventPaths?.[1]?.description}</p>
                                        <div className='namebdd'></div>
                                        <div className="criccheightt">
                                        <div className='row '>
                                            <div className='col-md-4'>
                                                <div className='text-center'>
                                                    <img className='contimggg' src={process.env.PUBLIC_URL + "/assets/images/home/sidebar/3537.png"} />
                                                    <p className='connttname'>{event?.opponents?.[0]?.description}</p>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className='text-center'>
                                                    <p className='matchday'>{event?.currentPeriod}</p>
                                                    <p className='matchtimimg'>{event?.startTime}</p>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className='text-center'>
                                                    <img className='contimggg' src={process.env.PUBLIC_URL + "/assets/images/home/sidebar/3537.png"} />
                                                    <p className='connttname'>{event?.opponents?.[1]?.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <Nav variant="pills" className='justify-content-center mt-2' defaultActiveKey="/home">
                                             {/* {todayMatch?.map((item, idx) => ( 
                                                <Nav.Item 
                                                 key={idx}
                                                >
                                                    <Nav.Link 
                                                     eventKey={item?.marketId} 
                                                     onClick={() => {
                                                     setRunnerId(item?.marketId);
                                                        setTeamOne(event.event.name.split(" v ")[0]); setTeamtwo(event.event.name.split(" v ")[1])
                                                     }}
                                                    >
                                                        <span>1.05</span>
                                                             {Math.round(item?.marketId * 100) / 100} 
                                                            

                                                    </Nav.Link>
                                                </Nav.Item>
                                            ))}  */}


                                            <Nav.Item >
                                                    <Nav.Link >
                                                        <span>1.05</span><br/>
                                                        <sup>1</sup>
                                                    </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item >
                                                    <Nav.Link >
                                                        <span>1.05</span><br/>
                                                        <sup>x</sup>
                                                    </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item >
                                                    <Nav.Link >
                                                        <span>1.05</span><br/>
                                                        <sup>2</sup>
                                                    </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        {/* <Nav variant="pills" className='justify-content-center mt-2' defaultActiveKey="/home">
                                            {pointsArray.map((item, idx) => (
                                                <Nav.Item key={idx}>
                                                    <Nav.Link href="/home">{event[item.points]}<br /><span>{item.label}</span></Nav.Link>
                                                </Nav.Item>
                                            ))}
                                        </Nav> */}
                                    </div>
                                </Link>
                            </SwiperSlide>

                                    ))

                                )
                        ))}
                    </Swiper>
                </div>
            </section>
        </>
    )
}

export default Football_cards