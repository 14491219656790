import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./Sidebaroffcanvas.css";
import { Link } from "react-router-dom";
const Sidebaroffcanvas = (props) => {
  return (
    <>
      <Offcanvas
        className="custom-offcanvas"
        show={props.show}
        onHide={props.setShow}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="sideBarlist">
            <Link to="/fantacy-sport/myaccount">
              <li>My Account</li>
            </Link>
            <Link to="/fantacy-sport/view-transition">
            <li>View Transactions</li>
            </Link>
            
            

            <Link to="/fantacy-sport/withdraw-cash">
              <li>Withdraw</li>
            </Link>
           
            <Link to="/fantacy-sport/withdraw-history">
            <li>Withdraw History</li>
            </Link>
            <li>Bonus Summary</li>
            <li>KYC Verification</li>
           <Link to="/fantacy-sport/join-contest"> <li>Join Private Contest</li></Link>
            <li>Point Contest</li>
            <li>Hot To Play</li>
            <li>Legality</li>
            <li>Terms of Service</li>
            <li>About Us</li>
            <li>Help</li>
            <li>Log Out</li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Sidebaroffcanvas;
