import React from 'react'
import '../FantacyCards/FantacyCards.css'
import { Link } from 'react-router-dom'
const UpcomingCard = ({ events }) => {
    return (
        <>

            {/* <div className='fantacy-cards-main upcoming-card'>
                <div className=' fantacy-cards'>
                    <h6>European FootBall – Portugal T10</h6>
                    <div className='main-flex-div'>
                        <div className='sub-flex-div'>
                            <img className='betting-img me-2' src={process.env.PUBLIC_URL + '/assets/images/home/sidebar/3537.png'} alt="Logo" />
                            <p>OE CC</p>
                        </div>
                        <div>
                            <div className='OE-div'>
                                OE CC bats
                            </div>
                        </div>
                        <div className='sub-flex-div'>
                            <p>FR CC</p>
                            <img className='betting-img ms-2' src={process.env.PUBLIC_URL + '/assets/images/home/sidebar/3537.png'} alt="Logo" />

                        </div>
                    </div>
                    <p>Preparing</p>
                </div>
                <div class="expert-team-container ">
                    <img className='gift-img' src={process.env.PUBLIC_URL + '/assets/images/fantacy/iphone-plus.png'} alt="Logo" />
                    <div class="expert-team">Win iPhone, Smartwatch + $1 Lakh as 1st Prize</div></div>
            </div> */}

            {events.map((event, index) => (

                <div key={index} className='fantacy-cards-main upcoming-card'>
                    <Link to={event.link}>
                        <div className='fantacy-cards'>
                            <h6>{event.title}</h6>
                            <div className='main-flex-div'>
                                <div className='sub-flex-div'>
                                    <img className='betting-img me-2' src={process.env.PUBLIC_URL + event.logo1} alt="Logo" />
                                    <p>{event.team1}</p>
                                </div>
                                <div>
                                    <div className='OE-div'>
                                        {event.team1Description}
                                    </div>
                                </div>
                                <div className='sub-flex-div'>
                                    <p>{event.team2}</p>
                                    <img className='betting-img ms-2' src={process.env.PUBLIC_URL + event.logo2} alt="Logo" />
                                </div>
                            </div>
                            <p>{event.status}</p>
                        </div>
                        <div className="expert-team-container ">
                            <img className='gift-img' src={process.env.PUBLIC_URL + '/assets/images/fantacy/iphone-plus.png'} alt="Logo" />
                            <div className="expert-team">{event.prize}</div>
                        </div>
                    </Link>
                </div>

            ))}

        </>
    )
}

export default UpcomingCard