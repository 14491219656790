import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import "./PlayerStatus.css";
const PlayerStatus = () => {
  return (
    <>
      <section className=" fantacy-sidebar players-status">
        <div className="sidebar-header">
          <Link to="/fantacy-sport/fantfoot">
            <FontAwesomeIcon
              icon="fa-solid fa-arrow-left"
              className="bar-icon"
            />
          </Link>
          <div className="text-center">
            <p>Joined Contests</p>
            <h6>Completed</h6>
          </div>
          <Link to="/fantacy-sport/addcash">
            <button type="button" class="header_btn">
              <span class="px-1">Add Cash</span>{" "}
              <span className="plus-div">+</span>
            </button>
          </Link>
        </div>
        <div className="vs-section">
          <div className="mainvs">
            <div className="teamss_div ">
              <img
                className="teamss"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/home/sidebar/3537.png"
                }
                alt="Logo"
              />
              <h4 className="teamname">MUM</h4>
            </div>
            <div className="teamss_div11">
              <div className="pills-div">
                <p>111-104</p>
              </div>
              <p className="ft">FT</p>
            </div>
            <div className="teamss_div">
              <h4 className="teamname">BLR</h4>
              <img
                className="teamss"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/home/sidebar/3573.png"
                }
                alt="Logo"
              />
            </div>
          </div>
        </div>

        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link eventKey="first">
                Players Name <FontAwesomeIcon icon="fa-solid fa-arrow-down" />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">
                Selected By <FontAwesomeIcon icon="fa-solid fa-arrow-down" />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="three">
                Points <FontAwesomeIcon icon="fa-solid fa-arrow-down" />
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <div className="tab-content-div">
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <div className="row pe-3 ps-3 row-div">
                <div className="col-6 d-flex align-items-center">
                  <div>
                    <img
                      className="tshirt"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/fantacy/tshiry.png"
                      }
                      alt="Logo"
                    />
                  </div>
                  <div className="">
                    <h6 className="">Adam David Lallana</h6>
                    <p>BHA</p>
                  </div>
                </div>
                <div className="col-3 text-center ">7%</div>
                <div className="col-3 text-end ">
                  <div className="">
                    <span className="points">8</span>
                    <FontAwesomeIcon icon="fa-solid fa-star" className="star" />
                  </div>
                </div>
              </div>
              <div className="row pe-3 ps-3 row-div">
                <div className="col-6 d-flex align-items-center">
                  <div>
                    <img
                      className="tshirt"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/fantacy/tshiry.png"
                      }
                      alt="Logo"
                    />
                  </div>
                  <div className="">
                    <h6 className="">Steven Hall</h6>
                    <p>BHA</p>
                  </div>
                </div>
                <div className="col-3 text-center ">7%</div>
                <div className="col-3 text-end ">
                  <div className="">
                    <span className="points">8</span>
                    <FontAwesomeIcon icon="fa-solid fa-star" className="star"/>
                  </div>
                </div>
              </div>
              <div className="row pe-3 ps-3 row-div">
                <div className="col-6 d-flex align-items-center">
                  <div>
                    <img
                      className="tshirt"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/fantacy/tshiry.png"
                      }
                      alt="Logo"
                    />
                  </div>
                  <div className="">
                    <h6 className="">Scott</h6>
                    <p>BHA</p>
                  </div>
                </div>
                <div className="col-3 text-center ">7%</div>
                <div className="col-3 text-end ">
                  <div className="">
                    <span className="points">8</span>
                    <FontAwesomeIcon icon="fa-solid fa-star" className="star" />
                  </div>
                </div>
              </div>
      
            </Tab.Pane>
            <Tab.Pane eventKey="second">
            <div className="row pe-3 ps-3 row-div">
                <div className="col-6 d-flex align-items-center">
                  <div>
                    <img
                      className="tshirt"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/fantacy/tshiry.png"
                      }
                      alt="Logo"
                    />
                  </div>
                  <div className="">
                    <h6 className="">Adam David Lallana</h6>
                    <p>BHA</p>
                  </div>
                </div>
                <div className="col-3 text-center ">7%</div>
                <div className="col-3 text-end ">
                  <div className="">
                    <span className="points">8</span>
                    <FontAwesomeIcon icon="fa-solid fa-star" className="star" />
                  </div>
                </div>
              </div>
              <div className="row pe-3 ps-3 row-div">
                <div className="col-6 d-flex align-items-center">
                  <div>
                    <img
                      className="tshirt"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/fantacy/tshiry.png"
                      }
                      alt="Logo"
                    />
                  </div>
                  <div className="">
                    <h6 className="">Adam David Lallana</h6>
                    <p>BHA</p>
                  </div>
                </div>
                <div className="col-3 text-center ">7%</div>
                <div className="col-3 text-end ">
                  <div className="">
                    <span className="points">8</span>
                    <FontAwesomeIcon icon="fa-solid fa-star" className="star"/>
                  </div>
                </div>
              </div>
              <div className="row pe-3 ps-3 row-div">
                <div className="col-6 d-flex align-items-center">
                  <div>
                    <img
                      className="tshirt"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/fantacy/tshiry.png"
                      }
                      alt="Logo"
                    />
                  </div>
                  <div className="">
                    <h6 className="">Adam David Lallana</h6>
                    <p>BHA</p>
                  </div>
                </div>
                <div className="col-3 text-center ">7%</div>
                <div className="col-3 text-end ">
                  <div className="">
                    <span className="points">8</span>
                    <FontAwesomeIcon icon="fa-solid fa-star" className="star" />
                  </div>
                </div>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="three">
            <div className="row pe-3 ps-3 row-div">
                <div className="col-6 d-flex align-items-center">
                  <div>
                    <img
                      className="tshirt"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/fantacy/tshiry.png"
                      }
                      alt="Logo"
                    />
                  </div>
                  <div className="">
                    <h6 className="">Adam David Lallana</h6>
                    <p>BHA</p>
                  </div>
                </div>
                <div className="col-3 text-center ">7%</div>
                <div className="col-3 text-end ">
                  <div className="">
                    <span className="points">8</span>
                    <FontAwesomeIcon icon="fa-solid fa-star" className="star" />
                  </div>
                </div>
              </div>
              <div className="row pe-3 ps-3 row-div">
                <div className="col-6 d-flex align-items-center">
                  <div>
                    <img
                      className="tshirt"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/fantacy/tshiry.png"
                      }
                      alt="Logo"
                    />
                  </div>
                  <div className="">
                    <h6 className="">Adam David Lallana</h6>
                    <p>BHA</p>
                  </div>
                </div>
                <div className="col-3 text-center ">7%</div>
                <div className="col-3 text-end ">
                  <div className="">
                    <span className="points">8</span>
                    <FontAwesomeIcon icon="fa-solid fa-star" className="star"/>
                  </div>
                </div>
              </div>
              <div className="row pe-3 ps-3 row-div">
                <div className="col-6 d-flex align-items-center">
                  <div>
                    <img
                      className="tshirt"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/fantacy/tshiry.png"
                      }
                      alt="Logo"
                    />
                  </div>
                  <div className="">
                    <h6 className="">Adam David Lallana</h6>
                    <p>BHA</p>
                  </div>
                </div>
                <div className="col-3 text-center ">7%</div>
                <div className="col-3 text-end ">
                  <div className="">
                    <span className="points">8</span>
                    <FontAwesomeIcon icon="fa-solid fa-star" className="star" />
                  </div>
                </div>
              </div>
              <div className="row pe-3 ps-3 row-div">
                <div className="col-6 d-flex align-items-center">
                  <div>
                    <img
                      className="tshirt"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/fantacy/tshiry.png"
                      }
                      alt="Logo"
                    />
                  </div>
                  <div className="">
                    <h6 className="">Adam David Lallana</h6>
                    <p>BHA</p>
                  </div>
                </div>
                <div className="col-3 text-center ">7%</div>
                <div className="col-3 text-end ">
                  <div className="">
                    <span className="points">8</span>
                    <FontAwesomeIcon icon="fa-solid fa-star" className="star" />
                  </div>
                </div>
              </div>
              <div className="row pe-3 ps-3 row-div">
                <div className="col-6 d-flex align-items-center">
                  <div>
                    <img
                      className="tshirt"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/fantacy/tshiry.png"
                      }
                      alt="Logo"
                    />
                  </div>
                  <div className="">
                    <h6 className="">Adam David Lallana</h6>
                    <p>BHA</p>
                  </div>
                </div>
                <div className="col-3 text-center ">7%</div>
                <div className="col-3 text-end ">
                  <div className="">
                    <span className="points">8</span>
                    <FontAwesomeIcon icon="fa-solid fa-star" className="star"/>
                  </div>
                </div>
              </div>
              <div className="row pe-3 ps-3 row-div">
                <div className="col-6 d-flex align-items-center">
                  <div>
                    <img
                      className="tshirt"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/fantacy/tshiry.png"
                      }
                      alt="Logo"
                    />
                  </div>
                  <div className="">
                    <h6 className="">
Ed Turns</h6>
                    <p>BHA</p>
                  </div>
                </div>
                <div className="col-3 text-center ">7%</div>
                <div className="col-3 text-end ">
                  <div className="">
                    <span className="points">8</span>
                    <FontAwesomeIcon icon="fa-solid fa-star" className="star" />
                  </div>
                </div>
              </div>
            </Tab.Pane>
          </Tab.Content>
          </div>
        </Tab.Container>
      </section>
    </>
  );
};

export default PlayerStatus;
