import React from "react";
import "./Payment_Methods.css";

function Payment_Methods() {
    return (
        <>
            <div className="Payment_Methods">
                <div className="Game_Partners_Content">
                    <div className="Partners_heading_holder">
                        <h1 className="heading-text">
                            Available payment methods
                        </h1>
                    </div>

                    <div className="partner-img-holder">
                        <div className="img-holder">
                            <img
                                className="partner-img"
                                src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/Game_details/Partner1.png"
                                }
                            />
                        </div>

                        <div className="img-holder">
                            <img
                                className="partner-img"
                                src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/Game_details/Partner2.png"
                                }
                            />
                        </div>

                        <div className="img-holder">
                            <img
                                className="partner-img"
                                src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/Game_details/Partner3.png"
                                }
                            />
                        </div>

                        <div className="img-holder">
                            <img
                                className="partner-img"
                                src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/Game_details/Partner4.png"
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Payment_Methods;
