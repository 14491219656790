import React from 'react'
import './PickPlayers.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PlayerDetailed from '../PlayerDetailed/PlayerDetailed'


function PickPlayers() {
  return (
   
    <section className='pickplayer_section'>
        <div className='pickedcount'>
           <p className='picked'>Pick 1 - 8 Wicket Keeper</p>
        </div>
        <div className='typelists'>
            <div className='row m-0'>
                <div className='col-3'>
                  <p className='showtype'>Playes <FontAwesomeIcon icon="fa-solid fa-arrow-up-long" className="uparrow" /></p>
                </div>
                <div className='col-3'>
                  <p className='showtype'> Points <FontAwesomeIcon icon="fa-solid fa-arrow-up-long" className="uparrow" /></p>
                </div>
                <div className='col-2 pe-0'>
                   <p className='showtype'>Sel%<FontAwesomeIcon icon="fa-solid fa-arrow-up-long" className="uparrow" /></p>
                </div>
                <div className='col-3'>
                   <p className='showtype'>Credits <FontAwesomeIcon icon="fa-solid fa-arrow-up-long" className="uparrow" /></p>
                </div>
                {/* <div className='col-2'>

                </div> */}

            </div>
        </div>
        <div>
            <PlayerDetailed/>
        </div>
    </section>
  )
}

export default PickPlayers