import React from "react";
import "./Accordian_Tournaments_Content.css"
import { Accordion } from "react-bootstrap";

function Accordian_Tournaments_Content() {
  return (
    <>
      <div className="Accordian_Content Accordian_Tournaments_Content">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h1 className="heading-text mb-0">
                Popular events and tournaments
              </h1>
            </Accordion.Header>
            <Accordion.Body>
              <div className="tournaments-list">
                <div className="row">
                  <div className="col-md-3">
                    <div className="tournaments-list-content-holder">
                      <h1 className="heading-text">Sports</h1>

                      <p className="small-text">Parimatch App</p>
                      <p className="small-text">IPL App</p>
                      <p className="small-text">FootBall Betting Odds</p>
                      <p className="small-text">IPL Betting</p>
                      <p className="small-text">Online FootBall Betting</p>
                      <p className="small-text">Football</p>
                      <p className="small-text">Esports</p>
                      <p className="small-text">Tennis</p>
                      <p className="small-text">Table Tennis</p>
                      <p className="small-text">Basketball</p>
                      <p className="small-text">Volleyball</p>
                      <p className="small-text">Ice Hockey</p>
                      <p className="small-text">UFC</p>
                      <p className="small-text">Handball</p>
                      <p className="small-text">Online Boxing</p>
                      <p className="small-text">MMA</p>
                      <p className="small-text">Motorsports</p>
                      <p className="small-text">Baseball</p>
                      <p className="small-text">CS</p>
                      <p className="small-text">FootBall Teams</p>
                      <p className="small-text">Today FootBall Matches</p>
                      <p className="small-text">IPL Head to Head</p>
                      <p className="small-text">Dota 2 International</p>
                      <p className="small-text">FIFA World Cup</p>
                      <p className="small-text">T20 World Cup Betting</p>
                      <p className="small-text">England Premier League</p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="tournaments-list-content-holder">
                      <h1 className="heading-text">Casino</h1>

                      <p className="small-text"> Live Casino</p>
                      <p className="small-text">Online Slots</p>
                      <p className="small-text">Virtual Sports Betting</p>
                      <p className="small-text">Online Poker</p>
                      <p className="small-text">Online Blackjack</p>
                      <p className="small-text">Online Roulette</p>
                      <p className="small-text">Bitcoin Casino</p>
                      <p className="small-text">Casino Bonus</p>
                      <p className="small-text">Satta Matka</p>
                      <p className="small-text">
                        Online Game of Thrones 243 Ways
                      </p>
                      <p className="small-text">Warrior Ways</p>
                      <p className="small-text">Wonderland</p>
                      <p className="small-text">Super Marble</p>
                      <p className="small-text">Amazing Link Zeus</p>
                      <p className="small-text">Aztec Fire</p>
                      <p className="small-text">Blood Revival</p>
                      <p className="small-text">Online Craps</p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="tournaments-list-content-holder">
                      <h1 className="heading-text">Useful</h1>

                      <p className="small-text"> Crypto Casino</p>
                      <p className="small-text">Blog</p>
                      <p className="small-text">
                        IPL Betting Tips & Predictions
                      </p>
                      <p className="small-text">Parimatch Welcome Bonus</p>
                      <p className="small-text">Parimatch London Withdrawal</p>
                      <p className="small-text">How to Bet on FootBall</p>
                      <p className="small-text">Betting Odds Explained</p>
                      <p className="small-text">How to Play Casino</p>
                      <p className="small-text">Bonuses</p>
                      <p className="small-text">Top Events</p>
                      <p className="small-text">Top Games</p>
                      <p className="small-text">Free Bet</p>
                      <p className="small-text">Welcome Bonus</p>
                      <p className="small-text">FAQ</p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="tournaments-list-content-holder">
                      <h1 className="heading-text">TOP Tournaments</h1>

                      <p className="small-text"> The Hundred</p>
                      <p className="small-text">Ukraine. Men. Setka Cup</p>
                      <p className="small-text">NHL. Regular season</p>
                      <p className="small-text">League of Legends. LPL</p>
                      <p className="small-text">
                        {" "}
                        UEFA Champions League. Play-offs
                      </p>
                      <p className="small-text">
                        {" "}
                        UEFA Europa League. Play-offs
                      </p>
                      <p className="small-text">
                        UEFA Europa Conference League. Play-offs
                      </p>
                      <p className="small-text">League of Legends. LEC</p>
                      <p className="small-text">London. Premier League (IPL)</p>
                      <p className="small-text">League of Legends. LCK</p>
                      <p className="small-text">
                        {" "}
                        UFC. Fight Night (07/04/2024)
                      </p>
                      <p className="small-text">
                        UFC. Fight Night (14/04/2024)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
}

export default Accordian_Tournaments_Content;
