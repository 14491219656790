import React from 'react'
import './SportSidebar.css'
import { NavLink } from 'react-router-dom'
const SportSidebar = () => {
  return (
    <>
      <section className='virtual-sport-sidebar'>
        <div className='border-div'>
          <NavLink className="links" to="/virtualsport">
            <div className='flex-element'>
              <img className='icons' src={process.env.PUBLIC_URL + '/assets/images/virtualgame/fast-games.png'} alt="Logo" />
              <p>Fast Game</p>
            </div>
          </NavLink>
        </div>
        <div  className='px-4 pt-4 pb-2'>
          <p className='title'>Categories</p>
        </div>
        <div className='border-div'>
          <NavLink className="links" to="/allgame">
            <div className='flex-element'>
              <img className='icons' src={process.env.PUBLIC_URL + '/assets/images/virtualgame/all.png'} alt="Logo" />
              <p>All Games</p>
            </div>
          </NavLink>
          <NavLink className="links" to="/virtualsport">
            <div className='flex-element'>
              <img className='icons' src={process.env.PUBLIC_URL + '/assets/images/virtualgame/football.png'} alt="Logo" />
              <p >FootBall</p>
            </div>
          </NavLink>
        </div>

        <div className='providers'>

        </div>
      </section>
    </>
  )
}

export default SportSidebar