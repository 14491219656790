// import React from "react";

// function Footboll_Pitch() {
//   return (
//     <>
//       <div className="Footboll_Pitch"></div>
//     </>
//   );
// }

// export default Footboll_Pitch;

import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import SoccerLineUp from "react-soccer-lineup";
import { nanoid } from "nanoid";
function Footboll_Pitch() {
  const [state, setState] = useState({
    color: "588f58",
    pattern: "lines",
    showHomeTeam: false,
    showAwayTeam: false,

    homeTeamColor: "f08080",
    homeTeamNumberColor: "ffffff",
    homeGoalkeeperColor: "d6cb65",
    homeGoalkeeperNumberColor: "333333",

    awayTeamColor: "add8e6",
    awayTeamNumberColor: "333333",
    awayGoalkeeperColor: "4f6c75",
    awayGoalkeeperNumberColor: "ffffff",
    nameColor: "ffffff",
  });
  const [error, setError] = useState(null);
  const [homeTeam, setHomeTeam] = useState(
    localStorage.getItem("homeTeam") === null
      ? {
          squad: {
            gk: null,
            df: [],
            fw: [],
          },
          style: {
            color: `#${state.homeTeamColor}`,
            numberColor: `#${state.homeTeamNumberColor}`,
            nameColor: `#${state.nameColor}`,
          },
        }
      : JSON.parse(localStorage.getItem("homeTeam"))
  );

  const [awayTeam, setAwayTeam] = useState(
    localStorage.getItem("awayTeam") === null
      ? {
          squad: {
            gk: null,
            df: [],
            fw: [],
          },
          style: {
            color: `#${state.awayTeamColor}`,
            numberColor: `#${state.awayTeamNumberColor}`,
            nameColor: `#${state.nameColor}`,
          },
        }
      : JSON.parse(localStorage.getItem("awayTeam"))
  );
  const [number, setNumber] = useState(1);
  const [playerName, setPlayerName] = useState("");
  const [playerTeam, setPlayerTeam] = useState("homeTeam");
  const [playerPosition, setPlayerPosition] = useState("gk");

  const handlePlayerNameChange = (event) => {
    setPlayerName(event.target.value);
  };

  const handlePlayerTeamChange = (event) => {
    setPlayerTeam(event.target.value);
  };

  const handlePlayerPositionChange = (event) => {
    setPlayerPosition(event.target.value);
  };

  const playerRemove = (team, position, id) => {
    if (team === "homeTeam") {
      if (position === "gk") {
        setHomeTeam((homeTeam) => ({
          ...homeTeam,
          squad: { ...homeTeam.squad, gk: null },
        }));
      } else if (position === "df") {
        setHomeTeam((homeTeam) => ({
          ...homeTeam,
          squad: {
            ...homeTeam.squad,
            df: homeTeam.squad.df.filter((player) => player.id !== id),
          },
        }));
      } else {
        setHomeTeam((homeTeam) => ({
          ...homeTeam,
          squad: {
            ...homeTeam.squad,
            fw: homeTeam.squad.fw.filter((player) => player.id !== id),
          },
        }));
      }
    } else {
      if (position === "gk") {
        setAwayTeam((awayTeam) => ({
          ...awayTeam,
          squad: { ...awayTeam.squad, gk: null },
        }));
      } else if (position === "df") {
        setAwayTeam((awayTeam) => ({
          ...awayTeam,
          squad: {
            ...awayTeam.squad,
            df: awayTeam.squad.df.filter((player) => player.id !== id),
          },
        }));
      } else {
        setAwayTeam((awayTeam) => ({
          ...awayTeam,
          squad: {
            ...awayTeam.squad,
            fw: awayTeam.squad.fw.filter((player) => player.id !== id),
          },
        }));
      }
    }
  };

  const addPlayer = () => {
    setError(null);
    if (playerName === "") {
      setError("Player Name required!");
    } else {
      let playerObj = {
        id: nanoid(),
        number: number,
        name: playerName,
        color:
          playerTeam === "homeTeam"
            ? `#${state.homeTeamColor}`
            : `#${state.awayTeamColor}`,
        numberColor:
          playerTeam === "homeTeam"
            ? `#${state.homeTeamNumberColor}`
            : `#${state.awayTeamNumberColor}`,
        namecolor: `#${state.nameColor}`,
      };
      if (playerTeam === "homeTeam") {
        if (playerPosition === "gk") {
          if (homeTeam.squad.gk !== null) {
            setError("Home Team goal keeper is full!");
          } else {
            playerObj.onClick = () =>
              playerRemove("homeTeam", "gk", playerObj.id);
            setHomeTeam((homeTeam) => ({
              ...homeTeam,
              squad: { ...homeTeam.squad, gk: playerObj },
            }));
          }
        } else if (playerPosition === "df") {
          if (homeTeam.squad.df.length === 3) {
            setError("Home Team defence is full!");
          } else {
            playerObj.onClick = () =>
              playerRemove("homeTeam", "df", playerObj.id);
            setHomeTeam((homeTeam) => ({
              ...homeTeam,
              squad: {
                ...homeTeam.squad,
                df: [...homeTeam.squad.df, playerObj],
              },
            }));
          }
        } else {
          if (homeTeam.squad.fw.length === 3) {
            setError("Home Team forward is full!");
          } else {
            playerObj.onClick = () =>
              playerRemove("homeTeam", "fw", playerObj.id);
            setHomeTeam((homeTeam) => ({
              ...homeTeam,
              squad: {
                ...homeTeam.squad,
                fw: [...homeTeam.squad.fw, playerObj],
              },
            }));
          }
        }
      } else {
        if (playerPosition === "gk") {
          if (awayTeam.squad.gk !== null) {
            setError("Home Team goal keeper is full!");
          } else {
            playerObj.onClick = () =>
              playerRemove("awayTeam", "gk", playerObj.id);
            setAwayTeam((awayTeam) => ({
              ...awayTeam,
              squad: { ...awayTeam.squad, gk: playerObj },
            }));
          }
        } else if (playerPosition === "df") {
          if (awayTeam.squad.df.length === 3) {
            setError("Away Team defence is full!");
          } else {
            playerObj.onClick = () =>
              playerRemove("awayTeam", "df", playerObj.id);
            setAwayTeam((awayTeam) => ({
              ...awayTeam,
              squad: {
                ...awayTeam.squad,
                df: [...awayTeam.squad.df, playerObj],
              },
            }));
          }
        } else {
          if (awayTeam.squad.fw.length === 3) {
            setError("Away Team forward is full!");
          } else {
            playerObj.onClick = () =>
              playerRemove("awayTeam", "fw", playerObj.id);
            setAwayTeam((awayTeam) => ({
              ...awayTeam,
              squad: {
                ...awayTeam.squad,
                fw: [...awayTeam.squad.fw, playerObj],
              },
            }));
          }
        }
      }
    }
    setNumber(number + 1);
  };

  useEffect(() => {
    localStorage.setItem("homeTeam", JSON.stringify(homeTeam));
    localStorage.setItem("awayTeam", JSON.stringify(awayTeam));
  }, [homeTeam, awayTeam]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="col-12"
          style={{
            backgroundColor: "green",
          }}
        >
          <SoccerLineUp
            size={"responsive"}
            color={"green"}
            pattern={"squares"}
            homeTeam={homeTeam}
            awayTeam={awayTeam}
          />
        </div>
      </div>
    </div>
  );
}
export default Footboll_Pitch;
