import React from "react";
import Best_Slip from "../../home/bestslip/Best_Slip";
import TournamentSidebar from "./TournamentSidebar/TournamentSidebar";
import TournamentMiddle from "./TournamentMiddle/TournamentMiddle";

const AllTournament = () => {
  return (
    <>
      <section className="tournament-main-section ">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-sm-12 col-xs-12 p-0 col1">
              <div className="first-scroll-div">
                <TournamentSidebar />
              </div>
            </div>
            <div className="col-xxl-8 col-xl-7 col-lg-7 col-md-6 col-sm-12 col-xs-12 col2">
              <div className="scroll-second-div">
                <TournamentMiddle />
              </div>
            </div>
            <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 p-0 col3">
              <div className="third-scroll-div">
                <Best_Slip />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AllTournament;
