import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../src/utils/api";

export const Per_Page_Dropdown = async (data) => {
  const a = [];
  for (let i = 5; i <= data; i *= 2) {
    a.push(i);
  }
  a.push(data);

  return a;
};

export const RequiredIs = {
  roles: [1, 2, 3, 4, 5, 6, 7],
};

export const Select2Data = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const getDimension = async (file) => {
  let reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        resolve({ width: this.width, height: this.height });
      };
    };
    reader.readAsDataURL(file);
  });
};

export const RegxExpression = {
  name: /[A-Z-a-z ]/,
  email:
    /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z0-9_\-\.]+)\.\2)([A-Za-z0-9_\-\.]+\.)+([A-Za-z]{2,4})$/,
  phone: /[0-9]/,

  ifsc: "^[A-Z]{4}[0][dA-Z]{6}$",
  ifsc_code: /^[A-Z0-9]$/,
  micr: /^[0-9]{9}$/,
  bank_number: /^\d{0,14}$/,
  gst: /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[1-9A-Z]{1}$/,
};


export const mobileRegister = async (data) => {
  try {
    const res = await postData(`/mobileRegister`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
