import React from "react";
import { Link } from "react-router-dom";
import "../AccountDetails/AccountDetails.css";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddEmail = () => {
  return (
    <section className="fantacy-sidebar accound-details">
    <div className="sidebar-header">
      <Link to="/fantacy-sport/myaccount">
        <FontAwesomeIcon
          icon="fa-solid fa-arrow-left"
          className="bar-icon"
        />
      </Link>
      <img
        className="headlogo"
        src={process.env.PUBLIC_URL + "/assets/images/logo/logo.png"}
        alt="Logo"
      />
      <Link to="/fantacy-sport/addcash">
        <button type="button" class="header_btn">
          <span class="px-1">Add Cash</span>{" "}
          <span className="plus-div">+</span>
        </button>
      </Link>
    </div>
    <div>
      <h1>Add Email Address</h1>
      <hr></hr>
  
      <div className="row px-5 py-2">
        <div className="col-xxl-5 col-xl-5 col-md-5 col-12">
          <Form.Label>Email Address : </Form.Label>
        </div>
        <div className="col-xxl-7 col-xl-7 col-md-7 col-12">
          <Form.Control type="email" placeholder="name@example.com"  defaultValue="game9zgaonh6"/>
        </div>
      </div>
      <div className="row px-5 py-2">
        <div className="col-xxl-5 col-xl-5 col-md-5 col-12">
          <Form.Label>Confirm Email Address :</Form.Label>
        </div>
        <div className="col-xxl-7 col-xl-7 col-md-7 col-12">
          <Form.Control type="email"  />
        </div>
      </div>
      
 <div className="text-center mt-3">
 <button>Submit</button>
 </div>
    </div>
  </section>
  )
}

export default AddEmail