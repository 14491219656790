import React from "react";
import "../FantacyCards/FantacyCards.css";
import { Link } from "react-router-dom";
const LiveCard = ({ livevents }) => {
  return (
    <>
      {livevents.map((livevent, index) => (
        <div key={index} className="fantacy-cards-main live-card">
          <Link to={livevent.link}>
            <div className=" fantacy-cards">
              <h6>{livevent.title}</h6>
              <div className="main-flex-div">
                <div className="sub-flex-div">
                  <img
                    className="betting-img me-2"
                    src={process.env.PUBLIC_URL + livevent.logo1}
                    alt="Logo"
                  />
                  <p>{livevent.team1}</p>
                </div>
                <div>
                  <div className="OE-div">Vs</div>
                </div>
                <div className="sub-flex-div">
                  <p>{livevent.team2}</p>
                  <img
                    className="betting-img ms-2"
                    src={process.env.PUBLIC_URL + livevent.logo2}
                    alt="Logo"
                  />
                </div>
              </div>
              <p>{livevent.status}</p>
            </div>
          </Link>
        </div>
      ))}
    </>
  );
};

export default LiveCard;
