import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./InfoCanvas.css";
const InfoCanvas = (props) => {
  return (
    <>
      <section className="score-info-ofcanvas">
        <Offcanvas
          className="info-offcanvas"
          show={props.show}
          onHide={props.setShow}
          {...props}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Ederson</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <p className="gk-text">GK | MCI</p>

            <div className="first-row">
              <div class="row">
                <div class="col-6">
                  <p>Action</p>
                </div>
                <div class="col-3">
                  <p>Stat</p>
                </div>
                <div class="col-3">
                  <p>Points</p>
                </div>
              
              </div>
            </div>
            <div className="sec-row">
              <div class="row">
                <div class="col-6">
                  <p>Passes Completed</p>
                </div>
                <div class="col-3">
                  <p>42</p>
                </div>
                <div class="col-3">
                  <p>0</p>
                </div>
            
              </div>
            </div>
            <div className="sec-row">
              <div class="row">
                <div class="col-6">
                  <p>Passes Completed</p>
                </div>
                <div class="col-3">
                  <p>42</p>
                </div>
                <div class="col-3">
                  <p>0</p>
                </div>
            
              </div>
            </div>
            <div className="sec-row third-row">
              <div class="row">
                <div class="col-6">
                  <p>Total</p>
                </div>
                <div class="col-3">
                  <p></p>
                </div>
                <div class="col-3">
                  <p>0</p>
                </div>
            
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </section>
    </>
  );
};

export default InfoCanvas;
