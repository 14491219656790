import React from "react";
import { Accordion, Nav, Tab } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Parlays.css";

// Import Swiper styles
import "swiper/css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Parlays_Tab_Content from "./Parlays_Tab_Content/Parlays_Tab_Content";
import Footer from "../../../../footer/Footer";
import All_Tab_Content from "./All_Tab_Content/All_Tab_Content";

function Parlays({event}) {
  return (
    <>
      <div className="Parlays">
        <div className="white-bg">
          <div className="tab-content-holder">
            <Tab.Container id="left-tabs-example" defaultActiveKey="All">
              <div>
                <div>
                  <Nav variant="pills" className="nav-tab-holder">
                    <Swiper
                      className="Parlays_swiper"
                      spaceBetween={"0"}
                      slidesPerView={10}
                    >
                      {/* <SwiperSlide>
                        <Nav.Item>
                          <Nav.Link eventKey="Parlays">
                            <FontAwesomeIcon
                              className="ticket-icon"
                              icon="fa-solid fa-ticket"
                            />{" "}
                            Parlays
                          </Nav.Link>
                        </Nav.Item>
                      </SwiperSlide> */}
                      <SwiperSlide>
                        <Nav.Item>
                          <Nav.Link eventKey="All">All</Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>

                      <SwiperSlide>
                        <Nav.Item>
                          <Nav.Link eventKey="second"> Main</Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>

                      <SwiperSlide>
                        <Nav.Item>
                          <Nav.Link eventKey="third">Statistics</Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Nav.Item>
                          <Nav.Link eventKey="fourth">Total</Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Nav.Item>
                          <Nav.Link eventKey="fifth">Handicap</Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>

                      <SwiperSlide>
                        <Nav.Item>
                          <Nav.Link eventKey="sixth">Halves</Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>

                      <SwiperSlide>
                        <Nav.Item>
                          <Nav.Link eventKey="seventh">Combo</Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>

                      <SwiperSlide>
                        <Nav.Item>
                          <Nav.Link eventKey="eighth">Goals</Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>

                      <SwiperSlide>
                        <Nav.Item>
                          <Nav.Link eventKey="ninth">Intervals</Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>

                      <SwiperSlide>
                        <Nav.Item>
                          <Nav.Link eventKey="tenth">Players</Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Nav.Item>
                          <Nav.Link eventKey="eleventh">Corners</Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Nav.Item>
                          <Nav.Link eventKey="twelfth">Yellow cards</Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Nav.Item>
                          <Nav.Link eventKey="fourth">
                            Penalties/Red c…
                          </Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>

                      <SwiperSlide>
                        <Nav.Item>
                          <Nav.Link eventKey="fifteenth">Fouls</Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Nav.Item>
                          <Nav.Link eventKey="Shots_on_target">
                            Shots on target
                          </Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Nav.Item>
                          <Nav.Link eventKey="Shots_all">Shots all</Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>

                      <SwiperSlide>
                        <Nav.Item>
                          <Nav.Link eventKey="Goal_kicks">Goal kicks</Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Nav.Item>
                          <Nav.Link eventKey="Offsides">Offsides</Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Nav.Item>
                          <Nav.Link eventKey="Saves">Saves</Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Nav.Item>
                          <Nav.Link eventKey="Score">Score</Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>
                    </Swiper>
                  </Nav>
                </div>

                <Tab.Content>
                  {/* <Tab.Pane eventKey="Parlays">
                    <Parlays_Tab_Content />
                  </Tab.Pane> */}
                  <Tab.Pane eventKey="All">
                    <All_Tab_Content event={event}/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                  <All_Tab_Content/>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
        </div>

        <Footer/>
      </div>
    </>
  );
}

export default Parlays;
