import React from "react";
import "./Private_component.css";
import Add_cash from "../../../../Contest/contest-sidebar/add-cash/Add_cash";
import A_vs_B from "../../../../../common-components/A_vs_B/A_vs_B";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";

import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import CreateEarnModal from "../../../../FantacyModals/CreateEarnModal/CreateEarnModal";

const Private_component = () => {
    const [modalShow, setModalShow] = React.useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  

  return (
    <>
      <section className="Private_component_seccc">
        <Add_cash />
        {/* <A_vs_B /> */}

        <div className="create-contestdivv">
          <div className="row">
            <div className="col-6">
              <div className="d-flex">
                <img
                  className="icccc"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/icon/download.png"
                  }
                />

                <p className="hgvvrfuvrf">Create Contest & Earn</p>
              </div>
            </div>
            <div className="col-6 text-end">
              <button className="widthdrawbtn">Withdraw</button>
            </div>
          </div>
        </div>

        <div className="createcontesttabsec">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Nav variant="pills" className="">
              <Nav.Item>
                <Nav.Link eventKey="first">Create Contest</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Join Contest</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="first">
                <Form>
                  <div className="row contestdata mb-2">
                    <div className="col-3">
                      <Form.Label>Contest Name:</Form.Label>
                    </div>
                    <div className="col-9">
                      <Form.Control
                        type="text"
                        placeholder="Contest Name"
                        defaultValue="forcepd9ephv's Contest"
                      />
                    </div>
                  </div>
                  <div className="row contestdata mb-2">
                    <div className="col-3">
                      <Form.Label>No. of Teams:</Form.Label>
                    </div>
                    <div className="col-9">
                      <Form.Control
                        type="text"
                        placeholder="10"
                        defaultValue="forcepd9ephv's Contest"
                      />
                    </div>
                  </div>
                  <div className="row contestdata mb-2">
                    <div className="col-3">
                      <Form.Label>Entry Fee:</Form.Label>
                    </div>
                    <div className="col-9">
                      <Form.Control
                        type="text"
                        placeholder="$"
                        defaultValue="forcepd9ephv's Contest"
                      />
                    </div>
                  </div>
                  <div className="row contestdata winnndiv mb-2">
                    <div className="col-3">
                      <Form.Label>No. of Winners:</Form.Label>
                    </div>
                    <div className="col-9">
                      <Form.Control
                        type="text"
                        placeholder=""
                        defaultValue="2"
                      />
                    </div>

                    <div className="row noofwinnn">
                      <div className="col-4 text-center">
                        <span>
                          <img
                            className="iccc"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icon/goldicon.png"
                            }
                          />
                          $96
                        </span>
                      </div>
                      <div className="col-4 text-center">
                        <span>
                          <img
                            className="iccc"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icon/blackicon.png"
                            }
                          />
                          $64
                        </span>
                      </div>
                      <div className="col-4 text-end">
                        <p
                          className="viewpp"
                          type="button"
                          variant="primary"
                          onClick={handleShow}
                        >
                          View
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row contestdata">
                    <div className="col-3">
                      <Form.Label>Allow Multiple team:</Form.Label>
                    </div>
                    <div className="col-9">
                      <Form>
                        {["radio"].map((type) => (
                          <div key={`inline-${type}`} className="mb-3">
                            <Form.Check
                              inline
                              label="Yes"
                              name="group1"
                              type={type}
                              id={`inline-${type}-1`}
                            />
                            <Form.Check
                              inline
                              label="No"
                              name="group1"
                              type={type}
                              id={`inline-${type}-2`}
                            />
                          </div>
                        ))}
                      </Form>
                    </div>
                  </div>
                </Form>

                <div className="bottom-btn">
                  <button className="earn-btn" onClick={() => setModalShow(true)}>
                    
                    <img
                      className="hnd-img"
                      src="/assets/images/fantacy/hand.png"
                    />
                    Create & Earn $20
                  </button>
                </div>
                <CreateEarnModal
                 show={modalShow}
                 onHide={() => setModalShow(false)}/>
                
              </Tab.Pane>

              <Tab.Pane eventKey="second">
                <div className="entercodesearch">
                  <Form.Control />
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>

          <Offcanvas
            className="viewcreatecontestoffcanvas"
            show={show}
            onHide={handleClose}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Prize Break-up</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <p className="hgvegfver">No. of winners: 2</p>
              <p className="hgvegfver">Total Price</p>

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <td>Rank</td>
                    <td>Prize Share</td>
                    <td>Prize</td>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>1</td>
                    <td>60%</td>
                    <td>96</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>60%</td>
                    <td>96</td>
                  </tr>
                </tbody>
              </table>

              <span className="ebububgt">
                The total prize pool and your earnings for hosting the private
                contest may change based on the actual number of teams joining
                the contest.
              </span>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </section>
    </>
  );
};

export default Private_component;
