import React from "react";
import "./A_vs_B.css";
function A_vs_B(propss) {
  return (
    <>
      <section className="vs-section">
        <div className="mainvs">
          <div className="teamss_div ">
            <img
              className="teamss"
              src={
                process.env.PUBLIC_URL + "/assets/images/home/sidebar/3537.png"
              }
              alt="Logo"
            />
            <h4 className="teamname">MUM</h4>
          </div>
          <div className="teamss_div11">
            <img
              className="vsimage"
              src={process.env.PUBLIC_URL + "/assets/images/teams/vs.png"}
              alt="Logo"
            />
          </div>
          <div className="teamss_div">
            <h4 className="teamname">BLR</h4>
            <img
              className="teamss"
              src={
                process.env.PUBLIC_URL + "/assets/images/home/sidebar/3573.png"
              }
              alt="Logo"
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default A_vs_B;
