import React from 'react'
import "./Game_Details.css"
import Sidebar from "../../home/sidebar/Sidebar"
import Best_Slip from '../../home/bestslip/Best_Slip'
import Game_Details_Page from './Game_Details_Page/Game_Details_Page'
import { useParams } from 'react-router-dom'

function Game_Details() {

  const {id, eventId} = useParams();


  
  return (
    <>
      <section className="home-main-section">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-4 p-0">
              <div className="first-scroll-div">
                <Sidebar />
              </div>
            </div>
            <div className="col-xxl-8 col-xl-7 col-lg-7 col-md-12">
              <div className="scroll-second-div">
                <Game_Details_Page id={id}  eventId={eventId}/>
              </div>
            </div>
            <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-12 p-0">
              <div className="third-scroll-div">
                <Best_Slip  id={id}  eventId={eventId}/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Game_Details