import React from 'react'
import './ExchangeRoutesMain.css'
import { Link } from 'react-router-dom'

const Exchangemobmenudiv = () => {
    return (
        <>
            <div className='exchangemobmenudiv'>
                <div className='menudiv'>
                    <div className='menulist'>
                        <div className='nav'>
                            <Link to="#">
                                <img className='menuicon' src={process.env.PUBLIC_URL + '/assets/images/exchange/icons/mobileview_menuicons/toggle.png'} />
                            </Link>
                        </div>
                        <div className='nav'>
                            <Link to="#">
                                <img className='menuicon' src={process.env.PUBLIC_URL + '/assets/images/exchange/icons/mobileview_menuicons/online-streaming.png'} />
                            </Link>
                        </div>
                        <div className='nav'>
                            <Link to="/exchange/exchange-home">
                                <img className='menuiconhome menuicon' src={process.env.PUBLIC_URL + '/assets/images/exchange/icons/mobileview_menuicons/home.png'} />
                            </Link>
                        </div>
                        <div className='nav'>
                            <Link to="#">
                                <img className='menuicon' src={process.env.PUBLIC_URL + '/assets/images/exchange/icons/mobileview_menuicons/ticket.png'} />
                            </Link>
                        </div>
                        <div className='nav'>
                            <Link to="/exchange-profile/profile">
                                <img className='menuicon' src={process.env.PUBLIC_URL + '/assets/images/exchange/icons/mobileview_menuicons/profile.png'} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Exchangemobmenudiv