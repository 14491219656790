import React, { useContext, useState } from "react";
import './ExchangeHome.css'
import { useLocation } from "react-router-dom";
import { Context } from "../../../utils/context";
import ExchangeSidebar from '../ExchangeSidebar/ExchangeSidebar'
import ExchangeBetPlaceSidebar from '../ExchangeSidebar/ExchangeBetPlaceSidebar'
import ExchangeRoutesMain from "../ExchangeRoutesMain/ExchangeRoutesMain";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";


const ExchangeHomeMatch = () => {
    return (
        <>
            <section className="ex-home-main-section">
                <div className="container-fluid p-0">
                    <div className="breadcrumbdiv">
                        <Breadcrumb>
                            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href="#">Football</Breadcrumb.Item>
                            <Breadcrumb.Item href="#">Match</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="gamenamediv ">
                            <p className="gamename"><img className="fotticc" src={process.env.PUBLIC_URL + '/assets/images/exchange/icons/football.png'} />FK Rigas Futbola Skola v Bodoe/Glimt</p>

                        </div>
                        <p className="dateandtime">8/5/2024 <span className="ms-2">12:00:00 PM</span></p>
                    </div>

                    {/* desktop view games-accodion-sect design start */}
                    <div className="games-accodion-sect games-accodion-sect-deskview">

                        {/* match odds div start  */}
                        <div className="matchdetailsdiv">
                            <div className="oddnamediv">
                                <p className="odname">Match Odds</p>
                            </div>
                            <p className="maxmin"><span>Min: 100 </span><span>Max: 50K</span></p>

                            <div className="backlaydiv">
                                <div className="d-flex mainflex ">
                                    <div className="namesecdiv">
                                        <p className="matchname">FK Rigas Futbola Skola</p>
                                        <p className="redno">-2700</p>
                                    </div>
                                    <div className="backlediv">
                                        <div className="yellomain">
                                            <p className="backtitle">BACK</p>
                                            <div className="">
                                                <p className="c1 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c2 nobox">16</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c3 nobox">17</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                        </div>

                                        <div className="greenmain">
                                            <p className="laytitle">LAY</p>
                                            <div className="">
                                                <p className="c3 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c2 nobox">16</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c1 nobox">17</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="backlaydiv">
                                <div className="d-flex mainflex ">
                                    <div className="namesecdiv">
                                        <p className="matchname">FK Rigas Futbola Skola</p>
                                        <p className="greenno">-2700</p>
                                    </div>
                                    <div className="backlediv">
                                        <div className="yellomain">

                                            <div className="">
                                                <p className="c1 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c2 nobox">16</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c3 nobox">17</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                        </div>

                                        <div className="greenmain">

                                            <div className="">
                                                <p className="c3 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c2 nobox">16</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c1 nobox">17</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="backlaydiv">
                                <div className="d-flex mainflex ">
                                    <div className="namesecdiv">
                                        <p className="matchname">FK Rigas Futbola Skola</p>
                                        <p className="greenno">-2700</p>
                                    </div>
                                    <div className="backlediv">
                                        <div className="yellomain">

                                            <div className="">
                                                <p className="c1 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c2 nobox">16</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c3 nobox">17</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                        </div>

                                        <div className="greenmain">

                                            <div className="">
                                                <p className="c3 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c2 nobox">16</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c1 nobox">17</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* match odds div end  */}


                        {/* Bookmarker  div start  */}
                        <div className="matchdetailsdiv">
                            <div className="oddnamediv">
                                <p className="odname">Bookmarker</p>
                            </div>
                            <p className="maxmin"><span>Min: 100 </span><span>Max: 50K</span></p>

                            <div className="backlaydiv">
                                <div className="d-flex mainflex ">
                                    <div className="namesecdiv">
                                        <p className="matchname">FK Rigas Futbola Skola</p>
                                        <p className="blackno">-2700</p>
                                    </div>
                                    <div className="backlediv">
                                        <div className="yellomain">
                                            <p className="backtitle">BACK</p>
                                            <div className="">
                                                <p className="c1 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c2 nobox">16</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c3 nobox">17</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                        </div>

                                        <div className="greenmain">
                                            <p className="laytitle">LAY</p>
                                            <div className="">
                                                <p className="c3 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c2 nobox">16</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c1 nobox">17</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="backlaydiv">
                                <div className="d-flex mainflex ">
                                    <div className="namesecdiv">
                                        <p className="matchname">FK Rigas Futbola Skola</p>
                                        <p className="blackno">-2700</p>
                                    </div>
                                    <div className="backlediv">
                                        <div className="yellomain">

                                            <div className="">
                                                <p className="c1 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c2 nobox">16</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c3 nobox">17</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                        </div>

                                        <div className="greenmain">

                                            <div className="">
                                                <p className="c3 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c2 nobox">16</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c1 nobox">17</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="backlaydiv">
                                <div className="d-flex mainflex ">
                                    <div className="namesecdiv">
                                        <p className="matchname">FK Rigas Futbola Skola</p>
                                        <p className="blackno">-2700</p>
                                    </div>
                                    <div className="backlediv">
                                        <div className="yellomain">

                                            <div className="">
                                                <p className="c1 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c2 nobox">16</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c3 nobox">17</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                        </div>

                                        <div className="greenmain">

                                            <div className="">
                                                <p className="c3 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c2 nobox">16</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c1 nobox">17</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* Bookmarker div end  */}


                        {/* OVER UNDER 15  div start  */}
                        <div className="matchdetailsdiv">
                            <div className="oddnamediv">
                                <p className="odname">OVER UNDER 15</p>
                            </div>
                            <p className="maxmin"><span>Min: 100 </span><span>Max: 50K</span></p>

                            <div className="backlaydiv">
                                <div className="d-flex mainflex ">
                                    <div className="namesecdiv">
                                        <p className="matchname">FK Rigas Futbola Skola</p>
                                        <p className="blackno">-2700</p>
                                    </div>
                                    <div className="backlediv">
                                        <div className="yellomain">
                                            <p className="backtitle">BACK</p>
                                            <div className="">
                                                <p className="c1 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c2 nobox">16</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c3 nobox">17</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                        </div>

                                        <div className="greenmain">
                                            <p className="laytitle">LAY</p>
                                            <div className="">
                                                <p className="c3 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c2 nobox">16</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c1 nobox">17</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="backlaydiv">
                                <div className="d-flex mainflex ">
                                    <div className="namesecdiv">
                                        <p className="matchname">FK Rigas Futbola Skola</p>
                                        <p className="blackno">-2700</p>
                                    </div>
                                    <div className="backlediv">
                                        <div className="yellomain">

                                            <div className="">
                                                <p className="c1 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c2 nobox">16</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c3 nobox">17</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                        </div>

                                        <div className="greenmain">

                                            <div className="">
                                                <p className="c3 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c2 nobox">16</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c1 nobox">17</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="backlaydiv">
                                <div className="d-flex mainflex ">
                                    <div className="namesecdiv">
                                        <p className="matchname">FK Rigas Futbola Skola</p>
                                        <p className="blackno">-2700</p>
                                    </div>
                                    <div className="backlediv">
                                        <div className="yellomain">

                                            <div className="">
                                                <p className="c1 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c2 nobox">16</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c3 nobox">17</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                        </div>

                                        <div className="greenmain">

                                            <div className="">
                                                <p className="c3 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c2 nobox">16</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className="c1 nobox">17</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* OVER UNDER 15 div end  */}


                        <div className="row">
                            <div className="col-md-6 mb-4">
                                {/* 1st Period div start  */}
                                <div className="matchdetailsdiv">
                                    <div className="oddnamediv">
                                        <p className="odname">1st Period Winner</p>
                                    </div>
                                    <p className="maxmin"><span>Min: 100 </span><span>Max: 50K</span></p>

                                    <div className="backlaydiv">
                                        <div className="d-flex mainflex ">
                                            <div className="namesecdiv">
                                                <p className="matchname">FK Rigas Futbola Skola</p>
                                                <p className="blackno">-2700</p>
                                            </div>
                                            <div className="backlediv">
                                                <div className="yellomain">
                                                    <p className="backtitle">BACK</p>
                                                    <div className="">
                                                        <p className="c1 nobox">15.5</p>
                                                        <span className="nopoint">25</span>
                                                    </div>
                                                </div>

                                                <div className="greenmain">
                                                    <p className="laytitle">LAY</p>
                                                    <div className="">
                                                        <p className="c3 nobox">15.5</p>
                                                        <span className="nopoint">25</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="backlaydiv">
                                        <div className="d-flex mainflex ">
                                            <div className="namesecdiv">
                                                <p className="matchname">FK Rigas Futbola Skola</p>
                                                <p className="blackno">-2700</p>
                                            </div>
                                            <div className="backlediv">
                                                <div className="yellomain">

                                                    <div className="">
                                                        <p className="c1 nobox">15.5</p>
                                                        <span className="nopoint">25</span>
                                                    </div>
                                                </div>

                                                <div className="greenmain">

                                                    <div className="">
                                                        <p className="c3 nobox">15.5</p>
                                                        <span className="nopoint">25</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="backlaydiv">
                                        <div className="d-flex mainflex ">
                                            <div className="namesecdiv">
                                                <p className="matchname">FK Rigas Futbola Skola</p>
                                                <p className="blackno">-2700</p>
                                            </div>
                                            <div className="backlediv">
                                                <div className="yellomain">

                                                    <div className="">
                                                        <p className="c1 nobox">15.5</p>
                                                        <span className="nopoint">25</span>
                                                    </div>
                                                </div>

                                                <div className="greenmain">

                                                    <div className="">
                                                        <p className="c3 nobox">15.5</p>
                                                        <span className="nopoint">25</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* 1st Period div end  */}
                            </div>

                            <div className="col-md-6 mb-4">
                                {/* 2nd Period Winner  div start  */}
                                <div className="matchdetailsdiv">
                                    <div className="oddnamediv">
                                        <p className="odname">2nd Period Winner</p>
                                    </div>
                                    <p className="maxmin"><span>Min: 100 </span><span>Max: 50K</span></p>

                                    <div className="backlaydiv">
                                        <div className="d-flex mainflex ">
                                            <div className="namesecdiv">
                                                <p className="matchname">FK Rigas Futbola Skola</p>
                                                <p className="blackno">-2700</p>
                                            </div>
                                            <div className="backlediv">
                                                <div className="yellomain">
                                                    <p className="backtitle">BACK</p>
                                                    <div className="">
                                                        <p className="c1 nobox">15.5</p>
                                                        <span className="nopoint">25</span>
                                                    </div>
                                                </div>

                                                <div className="greenmain">
                                                    <p className="laytitle">LAY</p>
                                                    <div className="">
                                                        <p className="c3 nobox">15.5</p>
                                                        <span className="nopoint">25</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="backlaydiv">
                                        <div className="d-flex mainflex ">
                                            <div className="namesecdiv">
                                                <p className="matchname">FK Rigas Futbola Skola</p>
                                                <p className="blackno">-2700</p>
                                            </div>
                                            <div className="backlediv">
                                                <div className="yellomain">

                                                    <div className="">
                                                        <p className="c1 nobox">15.5</p>
                                                        <span className="nopoint">25</span>
                                                    </div>
                                                </div>

                                                <div className="greenmain">

                                                    <div className="">
                                                        <p className="c3 nobox">15.5</p>
                                                        <span className="nopoint">25</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="backlaydiv">
                                        <div className="d-flex mainflex ">
                                            <div className="namesecdiv">
                                                <p className="matchname">FK Rigas Futbola Skola</p>
                                                <p className="blackno">-2700</p>
                                            </div>
                                            <div className="backlediv">
                                                <div className="yellomain">

                                                    <div className="">
                                                        <p className="c1 nobox">15.5</p>
                                                        <span className="nopoint">25</span>
                                                    </div>
                                                </div>

                                                <div className="greenmain">

                                                    <div className="">
                                                        <p className="c3 nobox">15.5</p>
                                                        <span className="nopoint">25</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* 2nd Period Winner div end  */}
                            </div>

                            <div className="col-md-6 mb-4">
                                {/* Next Goal 2.0  div start  */}
                                <div className="matchdetailsdiv">
                                    <div className="oddnamediv">
                                        <p className="odname">Next Goal 2.0</p>
                                    </div>
                                    <p className="maxmin"><span>Min: 100 </span><span>Max: 50K</span></p>

                                    <div className="backlaydiv">
                                        <div className="d-flex mainflex ">
                                            <div className="namesecdiv">
                                                <p className="matchname">FK Rigas Futbola Skola</p>
                                                <p className="blackno">-2700</p>
                                            </div>
                                            <div className="backlediv">
                                                <div className="yellomain">
                                                    <p className="backtitle">BACK</p>
                                                    <div className="">
                                                        <p className="c1 nobox">15.5</p>
                                                        <span className="nopoint">25</span>
                                                    </div>
                                                </div>

                                                <div className="greenmain">
                                                    <p className="laytitle">LAY</p>
                                                    <div className="">
                                                        <p className="c3 nobox">15.5</p>
                                                        <span className="nopoint">25</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="backlaydiv">
                                        <div className="d-flex mainflex ">
                                            <div className="namesecdiv">
                                                <p className="matchname">FK Rigas Futbola Skola</p>
                                                <p className="blackno">-2700</p>
                                            </div>
                                            <div className="backlediv">
                                                <div className="yellomain">

                                                    <div className="">
                                                        <p className="c1 nobox">15.5</p>
                                                        <span className="nopoint">25</span>
                                                    </div>
                                                </div>

                                                <div className="greenmain">

                                                    <div className="">
                                                        <p className="c3 nobox">15.5</p>
                                                        <span className="nopoint">25</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="backlaydiv">
                                        <div className="d-flex mainflex ">
                                            <div className="namesecdiv">
                                                <p className="matchname">FK Rigas Futbola Skola</p>
                                                <p className="blackno">-2700</p>
                                            </div>
                                            <div className="backlediv">
                                                <div className="yellomain">

                                                    <div className="">
                                                        <p className="c1 nobox">15.5</p>
                                                        <span className="nopoint">25</span>
                                                    </div>
                                                </div>

                                                <div className="greenmain">

                                                    <div className="">
                                                        <p className="c3 nobox">15.5</p>
                                                        <span className="nopoint">25</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* Next Goal 2.0 div end  */}
                            </div>

                            <div className="col-md-6 mb-4">
                                {/* Draw No Bet  div start  */}
                                <div className="matchdetailsdiv">
                                    <div className="oddnamediv">
                                        <p className="odname">Draw No Bet</p>
                                    </div>
                                    <p className="maxmin"><span>Min: 100 </span><span>Max: 50K</span></p>

                                    <div className="backlaydiv">
                                        <div className="d-flex mainflex ">
                                            <div className="namesecdiv">
                                                <p className="matchname">FK Rigas Futbola Skola</p>
                                                <p className="blackno">-2700</p>
                                            </div>
                                            <div className="backlediv">
                                                <div className="yellomain">
                                                    <p className="backtitle">BACK</p>
                                                    <div className="">
                                                        <p className="c1 nobox">15.5</p>
                                                        <span className="nopoint">25</span>
                                                    </div>
                                                </div>

                                                <div className="greenmain">
                                                    <p className="laytitle">LAY</p>
                                                    <div className="">
                                                        <p className="c3 nobox">15.5</p>
                                                        <span className="nopoint">25</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="backlaydiv">
                                        <div className="d-flex mainflex ">
                                            <div className="namesecdiv">
                                                <p className="matchname">FK Rigas Futbola Skola</p>
                                                <p className="blackno">-2700</p>
                                            </div>
                                            <div className="backlediv">
                                                <div className="yellomain">

                                                    <div className="">
                                                        <p className="c1 nobox">15.5</p>
                                                        <span className="nopoint">25</span>
                                                    </div>
                                                </div>

                                                <div className="greenmain">

                                                    <div className="">
                                                        <p className="c3 nobox">15.5</p>
                                                        <span className="nopoint">25</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="backlaydiv">
                                        <div className="d-flex mainflex ">
                                            <div className="namesecdiv">
                                                <p className="matchname">FK Rigas Futbola Skola</p>
                                                <p className="blackno">-2700</p>
                                            </div>
                                            <div className="backlediv">
                                                <div className="yellomain">

                                                    <div className="">
                                                        <p className="c1 nobox">15.5</p>
                                                        <span className="nopoint">25</span>
                                                    </div>
                                                </div>

                                                <div className="greenmain">

                                                    <div className="">
                                                        <p className="c3 nobox">15.5</p>
                                                        <span className="nopoint">25</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* Draw No Bet div end  */}
                            </div>
                        </div>


                        {/* HT / FT  div start  */}
                        <div className="matchdetailsdiv">
                            <div className="oddnamediv">
                                <p className="odname">HT / FT</p>
                            </div>
                            <p className="maxmin"><span>Min: 100 </span><span>Max: 50K</span></p>

                            <div className="backlaydiv">
                                <div className="d-flex mainflex ">
                                    <div className="namesecdiv">
                                        <p className="matchname">FK Rigas Futbola Skola</p>
                                        <p className="blackno">-2700</p>
                                    </div>
                                    <div className="backlediv">
                                        <div className="yellomain">
                                            <div className="">
                                                <p className="c1 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className=" nobox">1/X</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="backlediv">
                                        <div className="yellomain">
                                            <div className="">
                                                <p className="c1 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className=" nobox">1/2</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="backlediv">
                                        <div className="yellomain">
                                            <div className="">
                                                <p className="c1 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="backlaydiv">
                                <div className="d-flex mainflex ">
                                    <div className="namesecdiv">
                                        <p className="matchname">FK Rigas Futbola Skola</p>
                                        <p className="blackno">-2700</p>
                                    </div>
                                    <div className="backlediv">
                                        <div className="yellomain">
                                            <div className="">
                                                <p className="c1 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className=" nobox">1/X</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="backlediv">
                                        <div className="yellomain">
                                            <div className="">
                                                <p className="c1 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className=" nobox">1/2</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="backlediv">
                                        <div className="yellomain">
                                            <div className="">
                                                <p className="c1 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="backlaydiv">
                                <div className="d-flex mainflex ">
                                    <div className="namesecdiv">
                                        <p className="matchname">FK Rigas Futbola Skola</p>
                                        <p className="blackno">-2700</p>
                                    </div>
                                    <div className="backlediv">
                                        <div className="yellomain">
                                            <div className="">
                                                <p className="c1 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className=" nobox">1/X</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="backlediv">
                                        <div className="yellomain">
                                            <div className="">
                                                <p className="c1 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                            <div className="">
                                                <p className=" nobox">1/2</p>
                                                <span className="nopoint">25</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="backlediv">
                                        <div className="yellomain">
                                            <div className="">
                                                <p className="c1 nobox">15.5</p>
                                                <span className="nopoint">25</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* HT / FT div end  */}

                    </div>
                    {/* desktop view games-accodion-sect design end */}


                    <div className="mobviewgameaccosec">
                        <div className="gametitlediv">
                            <h6 className="gamename"><Link className="iconbg" to="#"><FontAwesomeIcon icon={faChevronLeft} /></Link>FK Rigas Futbola Skola</h6>
                        </div>

                        <div className="gameaccco-main">
                            <Accordion defaultActiveKey={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']} alwaysOpen>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Match Odds</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="game-table-div">
                                            <table className="table table-responsive table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Max:50K</th>
                                                        <th className="text-center">Back</th>
                                                        <th className="text-center">Lay</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            <p className="mmtt">FK Rigas Futbola Skola</p>
                                                            <p className="redpoint">-2700</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="backdiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="laydiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                    </tr>

                                                    <tr>
                                                        <th>
                                                            <p className="mmtt">FK Rigas Futbola Skola</p>
                                                            <p className="greenpoint">-2700</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="backdiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="laydiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Bookmaker</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="game-table-div">
                                            <table className="table table-responsive table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Max:50K</th>
                                                        <th className="text-center">Back</th>
                                                        <th className="text-center">Lay</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            <p className="mmtt">FK Rigas Futbola Skola</p>
                                                            <p className="redpoint">-2700</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="backdiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="laydiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                    </tr>

                                                    <tr>
                                                        <th>
                                                            <p className="mmtt">FK Rigas Futbola Skola</p>
                                                            <p className="greenpoint">-2700</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="backdiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="laydiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Over under 15</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="game-table-div">
                                            <table className="table table-responsive table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Max:50K</th>
                                                        <th className="text-center">Back</th>
                                                        <th className="text-center">Lay</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            <p className="mmtt">FK Rigas Futbola Skola</p>
                                                            <p className="redpoint">-2700</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="backdiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="laydiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                    </tr>

                                                    <tr>
                                                        <th>
                                                            <p className="mmtt">FK Rigas Futbola Skola</p>
                                                            <p className="greenpoint">-2700</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="backdiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="laydiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Over under 25</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="game-table-div">
                                            <table className="table table-responsive table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Max:50K</th>
                                                        <th className="text-center">Back</th>
                                                        <th className="text-center">Lay</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            <p className="mmtt">FK Rigas Futbola Skola</p>
                                                            <p className="redpoint">-2700</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="backdiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="laydiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                    </tr>

                                                    <tr>
                                                        <th>
                                                            <p className="mmtt">FK Rigas Futbola Skola</p>
                                                            <p className="greenpoint">-2700</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="backdiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="laydiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>1st Period Winner</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="game-table-div">
                                            <table className="table table-responsive table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Max:50K</th>
                                                        <th className="text-center">Back</th>
                                                        <th className="text-center">Lay</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            <p className="mmtt">FK Rigas Futbola Skola</p>
                                                            <p className="redpoint">-2700</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="backdiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="laydiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                    </tr>

                                                    <tr>
                                                        <th>
                                                            <p className="mmtt">FK Rigas Futbola Skola</p>
                                                            <p className="greenpoint">-2700</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="backdiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="laydiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>2nd Period Winner</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="game-table-div">
                                            <table className="table table-responsive table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Max:50K</th>
                                                        <th className="text-center">Back</th>
                                                        <th className="text-center">Lay</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            <p className="mmtt">FK Rigas Futbola Skola</p>
                                                            <p className="redpoint">-2700</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="backdiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="laydiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                    </tr>

                                                    <tr>
                                                        <th>
                                                            <p className="mmtt">FK Rigas Futbola Skola</p>
                                                            <p className="greenpoint">-2700</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="backdiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="laydiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>Next Goal 2.0</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="game-table-div">
                                            <table className="table table-responsive table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Max:50K</th>
                                                        <th className="text-center">Back</th>
                                                        <th className="text-center">Lay</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            <p className="mmtt">FK Rigas Futbola Skola</p>
                                                            <p className="redpoint">-2700</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="backdiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="laydiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                    </tr>

                                                    <tr>
                                                        <th>
                                                            <p className="mmtt">FK Rigas Futbola Skola</p>
                                                            <p className="greenpoint">-2700</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="backdiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="laydiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>Draw No Bet</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="game-table-div">
                                            <table className="table table-responsive table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Max:50K</th>
                                                        <th className="text-center">Back</th>
                                                        <th className="text-center">Lay</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            <p className="mmtt">FK Rigas Futbola Skola</p>
                                                            <p className="redpoint">-2700</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="backdiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="laydiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                    </tr>

                                                    <tr>
                                                        <th>
                                                            <p className="mmtt">FK Rigas Futbola Skola</p>
                                                            <p className="greenpoint">-2700</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="backdiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="laydiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="8">
                                    <Accordion.Header>Both Teams to Score</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="game-table-div">
                                            <table className="table table-responsive table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Max:50K</th>
                                                        <th className="text-center">Back</th>
                                                        <th className="text-center">Lay</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            <p className="mmtt">FK Rigas Futbola Skola</p>
                                                            <p className="redpoint">-2700</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="backdiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="laydiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                    </tr>

                                                    <tr>
                                                        <th>
                                                            <p className="mmtt">FK Rigas Futbola Skola</p>
                                                            <p className="greenpoint">-2700</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="backdiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="laydiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="9">
                                    <Accordion.Header>Both Teams to Score 1st Half</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="game-table-div">
                                            <table className="table table-responsive table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Max:50K</th>
                                                        <th className="text-center">Back</th>
                                                        <th className="text-center">Lay</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            <p className="mmtt">FK Rigas Futbola Skola</p>
                                                            <p className="redpoint">-2700</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="backdiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="laydiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                    </tr>

                                                    <tr>
                                                        <th>
                                                            <p className="mmtt">FK Rigas Futbola Skola</p>
                                                            <p className="greenpoint">-2700</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="backdiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="laydiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="10">
                                    <Accordion.Header>Both Teams to Score 2nd Half</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="game-table-div">
                                            <table className="table table-responsive table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Max:50K</th>
                                                        <th className="text-center">Back</th>
                                                        <th className="text-center">Lay</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            <p className="mmtt">FK Rigas Futbola Skola</p>
                                                            <p className="redpoint">-2700</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="backdiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="laydiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                    </tr>

                                                    <tr>
                                                        <th>
                                                            <p className="mmtt">FK Rigas Futbola Skola</p>
                                                            <p className="greenpoint">-2700</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="backdiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="laydiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="11">
                                    <Accordion.Header>Goals Odd/Even</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="game-table-div">
                                            <table className="table table-responsive table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Max:50K</th>
                                                        <th className="text-center">Back</th>
                                                        <th className="text-center">Lay</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            <p className="mmtt">FK Rigas Futbola Skola</p>
                                                            <p className="redpoint">-2700</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="backdiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="laydiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                    </tr>

                                                    <tr>
                                                        <th>
                                                            <p className="mmtt">FK Rigas Futbola Skola</p>
                                                            <p className="greenpoint">-2700</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="backdiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                        <th className="text-center">
                                                            <p className="laydiv">17</p>
                                                            <p className="bcaccc">7</p>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="12">
                                    <Accordion.Header>HT/FT</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="game-table-div">
                                            <table className="table table-responsive table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Max:50K</th>
                                                        <th className="text-center"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            <div className="row">
                                                                <div className="col-6 text-center">
                                                                    <p className="mmtt">1/1</p>
                                                                    <p className="redpoint">-2700</p>
                                                                </div>
                                                                <div className="col-6 text-center">
                                                                    <p className="backdiv">17</p>
                                                                    <p className="bcaccc">7</p>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th className="text-center">
                                                            <div className="row">
                                                                <div className="col-6 text-center">
                                                                    <p className="mmtt">1/1</p>
                                                                    <p className="redpoint">-2700</p>
                                                                </div>
                                                                <div className="col-6 text-center">
                                                                    <p className="backdiv">17</p>
                                                                    <p className="bcaccc">7</p>
                                                                </div>
                                                            </div>
                                                        </th>
                                                    </tr>

                                                    <tr>
                                                        <th>
                                                            <div className="row">
                                                                <div className="col-6 text-center">
                                                                    <p className="mmtt">1/1</p>
                                                                    <p className="greenpoint">-2700</p>
                                                                </div>
                                                                <div className="col-6 text-center">
                                                                    <p className="backdiv">17</p>
                                                                    <p className="bcaccc">7</p>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th className="text-center">
                                                            <div className="row">
                                                                <div className="col-6 text-center">
                                                                    <p className="mmtt">1/1</p>
                                                                    <p className="greenpoint">-2700</p>
                                                                </div>
                                                                <div className="col-6 text-center">
                                                                    <p className="backdiv">17</p>
                                                                    <p className="bcaccc">7</p>
                                                                </div>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ExchangeHomeMatch