import React from 'react'
import './exchangeprofile.css'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

const ExchangeMobProfile = () => {
    return (
        <>
            <section className='ExchangeMobProfile'>
                <div className='list'>
                    <Link to="/exchange-profile/account-statement">
                        <h6 className='h6name'>Account Statement <FontAwesomeIcon className='nextico' icon={faChevronRight} /></h6>
                    </Link>
                </div>
                <div className='list'>
                    <Link to="/exchange-profile/profit-loss">
                        <h6 className='h6name'>Profit Loss Report <FontAwesomeIcon className='nextico' icon={faChevronRight} /></h6>
                    </Link>
                </div>
                <div className='list'>
                    <Link to="/exchange-profile/unsetteled-bet">
                        <h6 className='h6name'>Unsetteled Bet <FontAwesomeIcon className='nextico' icon={faChevronRight} /></h6>
                    </Link>
                </div>
                <div className='list'>
                    <Link to="/exchange-profile/button-value">
                        <h6 className='h6name'>Set Button Values <FontAwesomeIcon className='nextico' icon={faChevronRight} /></h6>
                    </Link>
                </div>
                <div className='list'>
                    <Link to="/exchange-profile/secure-auth">
                        <h6 className='h6name'>Security Auth Verification<FontAwesomeIcon className='nextico' icon={faChevronRight} /></h6>
                    </Link>
                </div>
                <div className='list'>
                    <Link to="/exchange-profile/change-password">
                        <h6 className='h6name'>Change Password<FontAwesomeIcon className='nextico' icon={faChevronRight} /></h6>
                    </Link>
                </div>

                <div className='text-center mt-5'>
                    <button className='signoutbttt'>Sign Out</button>
                </div>  
            </section>
        </>
    )
}

export default ExchangeMobProfile