import React from 'react'
import SelectCapVc from './SelectCapVc/SelectCapVc'
import TwoTeamCapvc from './TwoTeamsCapvc/TwoTeamCapvc'

function JoinTeam() {
  return (
    <>
     <section className="fantacy-section">
        <div className="container-fluid p-0">
          <div className="sectionflex">
            <div className="sidebar-div">
              <div className="first-scroll-div">
             
           <SelectCapVc/>
           <TwoTeamCapvc/>

              </div>
            </div>
            <div className="contentmain-div ">
              <div className="scroll-second-div">

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default JoinTeam