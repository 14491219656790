import React from 'react'
import { Modal } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import './NotiicationModal.css'
import '../LoginModal/LoginModal.css'

const NotiicationModal = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='notification-modal login-modal'
            > <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <img className='bell-img' src={process.env.PUBLIC_URL + '/assets/images/virtualgame/bell.png'} alt="Logo" />
                    <h4>Want to stay updated?</h4>
                    <p>Sign up or log in to an existing account and subscribe to favourite events. Any detail and latest update will never pass you by!</p>
                </Modal.Body>
                <Modal.Footer>
                    <NavLink to="/login"><button className='buttons' onClick={props.onHide}> Login</button></NavLink>
                    <NavLink to="/signup"><button className='signbuttons' onClick={props.onHide}>Sign Up</button></NavLink>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default NotiicationModal