import React from 'react'
import '../VirtualSport.css'
const FirstFootabllgame = () => {
    return (
        <>
            <section className='main-game-screen'>
                <div className='game-screen-div'>
                    <iframe class="DefaultFrame_frame__6ASkh" title="leap" src="https://aggregation-launch.eva-pm8games.com/v0/casino/game/launch?operator=parimatch&amp;brand=IN&amp;gameId=leap-football-cup-world&amp;channel=desktop&amp;language=en&amp;lobbyUrl=https%3A%2F%2Fpari-match-bet.in%2Fen%2Fcasino%2Fvirtual-sports" frameborder="0" allow="autoplay *; fullscreen *" allowfullscreen="" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>
                </div>
            </section>
        </>
    )
}

export default FirstFootabllgame