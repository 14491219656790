import React from "react";
import { Accordion } from "react-bootstrap";

function Accordian_Que_Content() {
  return (
    <>
      <div className="Accordian_Content">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <p className="xs-text mb-0">
                ☝ Frequently Asked Questions about Man City vs Aston Villa
                Betting
              </p>
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <h3 className="subheading-text ">
                  What are odds for Man City vs Aston Villa?
                </h3>
                <p className="xs-text">
                  Odds for Man City vs Aston Villa represent the likelihood of a
                  specific event occurring during the England Premier League
                  match between Man City and Aston Villa. They also indicate the
                  potential payout if your bet is successful.
                </p>
              </div>

              <div>
                <h3 className="subheading-text ">
                  What are odds for Man City vs Aston Villa?
                </h3>
                <p className="xs-text">
                  Odds for Man City vs Aston Villa represent the likelihood of a
                  specific event occurring during the England Premier League
                  match between Man City and Aston Villa. They also indicate the
                  potential payout if your bet is successful.
                </p>
              </div>

              <div>
                <h3 className="subheading-text ">
                  What are odds for Man City vs Aston Villa?
                </h3>
                <p className="xs-text">
                  Odds for Man City vs Aston Villa represent the likelihood of a
                  specific event occurring during the England Premier League
                  match between Man City and Aston Villa. They also indicate the
                  potential payout if your bet is successful.
                </p>
              </div>

              <div>
                <h3 className="subheading-text ">
                  What are odds for Man City vs Aston Villa?
                </h3>
                <p className="xs-text">
                  Odds for Man City vs Aston Villa represent the likelihood of a
                  specific event occurring during the England Premier League
                  match between Man City and Aston Villa. They also indicate the
                  potential payout if your bet is successful.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
}

export default Accordian_Que_Content;
