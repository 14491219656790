import React, { useState, useEffect, useContext } from "react";
import "./All_Tab_Content.css";
import Nav from 'react-bootstrap/Nav';
import { Accordion } from "react-bootstrap";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { Context } from "../../../../../../utils/context";
library.add(fas);

function All_Tab_Content({ event }) {

  const [activeTab, setActiveTab] = useState(0);
  const { getGameData, setSelectedBet, selectedBet } = useContext(Context);
  const handleSelect = (selectedIndex) => {
    setActiveTab(selectedIndex);
  };
  const [activeKeys, setActiveKeys] = useState([]);
  const handleClick = (outcomes, wdw, description) => {
    const result = selectedBet.filter(data =>
      !outcomes.some(data1 => data1.id === data.id)
    );
    wdw.vsdescription = description;
    setSelectedBet([...result, wdw]);
  };
  useEffect(() => {
    if (event?.markets) {
      // Initialize active keys to all indices of the markets array
      const keys = event.markets.map((_, index) => String(index));
      setActiveKeys(keys);
    }
  }, [event]);
  return (
    <>
      <div className="All_Tab_Content">
        <div className="Accordian_Content">
          <Accordion defaultActiveKey={activeKeys}>
            {event?.markets?.map((value, index) => (

              <Accordion.Item eventKey={index}>
                <Accordion.Header>
                  <div className="header-sec">
                    <p className="heading-text mb-0 ">{value?.description}</p>
                    <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" className="me-3 excla-icon" />
                  </div>

                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-12">
                      <Nav variant="pills" defaultActiveKey="">
                        {value?.outcomes?.map((val, indexs) => (
                          <Nav.Item key={indexs}>
                            <Nav.Link eventKey={`link-${indexs}`} onClick={() => handleClick(value?.outcomes, val, val.description)}>
                              <span>{val?.description}</span>
                              <br></br>
                              <span className="">
                                {val?.consolidatedPrice?.currentPrice?.decimal}
                              </span>
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </div>
                  </div>
                  {/* <div className='right-div flex-div'>
                  <Nav variant="pills" className=' mt-2' defaultActiveKey="">
                    <Nav.Item>
                      <Nav.Link eventKey="link-0">1.05<br></br><span>MAN CITY OR DRAW</span></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="link-1">1.12<br></br><span>NO DRAW</span></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="link-2">2.55<br></br><span>ASTON VILLA OR DRAW</span></Nav.Link>
                    </Nav.Item>
                  </Nav>

                </div> */}
                </Accordion.Body>
              </Accordion.Item>

            ))}

          </Accordion>
        </div>
      </div>
    </>
  );
}

export default All_Tab_Content;
