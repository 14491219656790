import React, { useState } from "react";

import './exchangeprofile.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Form from 'react-bootstrap/Form';

const UnsetteledBet = () => {
    return (
        <>
            <section className='exchangeprofilemain-sec'>

                <div className='secttiill'>
                    <div className='titlediv'>
                        <p className='title'>Unsetteled Bet</p>
                    </div>
                </div>

                <div className='tablemain-div'>
                    <div className='row'>
                        <Form>
                            {[ 'radio' ].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                    <Form.Check
                                        inline
                                        label="Matched"
                                        name="group1"
                                        type={type}
                                        id={`inline-${type}-1`}
                                    />
                                    <Form.Check
                                        inline
                                        label="Unmatched"
                                        name="group1"
                                        type={type}
                                        id={`inline-${type}-2`}
                                    />
                                    <Form.Check
                                        inline                                        
                                        label="Deleted"
                                        name="group1"
                                        type={type}
                                        id={`inline-${type}-3`}
                                    />
                                </div>
                            ))}
                        </Form>
                    </div>


                    <div className="table-div colortable">
                        <div className="table-responsive">
                            <table className="table table-bordered striped">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Event Name</th>
                                        <th>Nation</th>
                                        <th>Event Type</th>
                                        <th>Market Name</th>
                                        <th>Side</th>
                                        <th>Rate</th>
                                        <th>Amount</th>
                                        <th>Place Date</th>
                                        <th>Match Date</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>Date</td>
                                        <td>Sr.No</td>
                                        <td>Credit</td>
                                        <td>Debit</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                    </tr>

                                    <tr>
                                        <td>Date</td>
                                        <td>Sr.No</td>
                                        <td>Credit</td>
                                        <td>Debit</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                    </tr>

                                    <tr>
                                        <td>Date</td>
                                        <td>Sr.No</td>
                                        <td>Credit</td>
                                        <td>Debit</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                    </tr>

                                    <tr>
                                        <td>Date</td>
                                        <td>Sr.No</td>
                                        <td>Credit</td>
                                        <td>Debit</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                    </tr>

                                    <tr>
                                        <td>Date</td>
                                        <td>Sr.No</td>
                                        <td>Credit</td>
                                        <td>Debit</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                    </tr>

                                    <tr>
                                        <td>Date</td>
                                        <td>Sr.No</td>
                                        <td>Credit</td>
                                        <td>Debit</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>



                </div>

            </section>
        </>
    )
}

export default UnsetteledBet