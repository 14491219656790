import React from 'react'
import './exchangesidebar.css'
import { Link } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion';

const ExchangeSidebar = () => {
  return (
    <>
      <>
        <section className='ExchangeSidebar-main'>
          <div className='footbackdiv'>
            <div className='d-flex'>
              <div className='backdiv'>
                <Link to='#'>
                  <img className='backicn' src={process.env.PUBLIC_URL + '/assets/images/exchange/icons/back.png'} />
                </Link>
              </div>
              <div className='my-auto'>
                <h6 className='footh6'>Football</h6>
              </div>
            </div>
          </div>

          <div className='accodiv'>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Top Competitions</Accordion.Header>
                <Accordion.Body>
                  <div className='linksdiv'>
                    <div className='divli'><Link to="#">World Cup<span> (International)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Nations League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Champions League<span>(International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Europa League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Uefa Europa Conference League <span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">WC Qual, CONMEBOL<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Premier League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">LaLiga<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Bundesliga<span> (Germany)</span></Link></div>
                    <div className='divli'><Link to="#">Serie A <span> (Italy)</span></Link></div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>Top Categories</Accordion.Header>
                <Accordion.Body>
                  <div className='linksdiv'>
                    <div className='divli'><Link to="#">World Cup<span> (International)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Nations League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Champions League<span>(International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Europa League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Uefa Europa Conference League <span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">WC Qual, CONMEBOL<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Premier League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">LaLiga<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Bundesliga<span> (Germany)</span></Link></div>
                    <div className='divli'><Link to="#">Serie A <span> (Italy)</span></Link></div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>International</Accordion.Header>
                <Accordion.Body>
                  <div className='linksdiv'>
                    <div className='divli'><Link to="#">World Cup<span> (International)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Nations League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Champions League<span>(International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Europa League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Uefa Europa Conference League <span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">WC Qual, CONMEBOL<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Premier League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">LaLiga<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Bundesliga<span> (Germany)</span></Link></div>
                    <div className='divli'><Link to="#">Serie A <span> (Italy)</span></Link></div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>International Clubs</Accordion.Header>
                <Accordion.Body>
                  <div className='linksdiv'>
                    <div className='divli'><Link to="#">World Cup<span> (International)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Nations League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Champions League<span>(International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Europa League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Uefa Europa Conference League <span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">WC Qual, CONMEBOL<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Premier League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">LaLiga<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Bundesliga<span> (Germany)</span></Link></div>
                    <div className='divli'><Link to="#">Serie A <span> (Italy)</span></Link></div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>Australia</Accordion.Header>
                <Accordion.Body>
                  <div className='linksdiv'>
                    <div className='divli'><Link to="#">World Cup<span> (International)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Nations League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Champions League<span>(International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Europa League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Uefa Europa Conference League <span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">WC Qual, CONMEBOL<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Premier League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">LaLiga<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Bundesliga<span> (Germany)</span></Link></div>
                    <div className='divli'><Link to="#">Serie A <span> (Italy)</span></Link></div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>


              <Accordion.Item eventKey="5">
                <Accordion.Header>Japan</Accordion.Header>
                <Accordion.Body>
                  <div className='linksdiv'>
                    <div className='divli'><Link to="#">World Cup<span> (International)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Nations League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Champions League<span>(International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Europa League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Uefa Europa Conference League <span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">WC Qual, CONMEBOL<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Premier League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">LaLiga<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Bundesliga<span> (Germany)</span></Link></div>
                    <div className='divli'><Link to="#">Serie A <span> (Italy)</span></Link></div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6">
                <Accordion.Header>Malaysia</Accordion.Header>
                <Accordion.Body>
                  <div className='linksdiv'>
                    <div className='divli'><Link to="#">World Cup<span> (International)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Nations League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Champions League<span>(International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Europa League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Uefa Europa Conference League <span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">WC Qual, CONMEBOL<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Premier League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">LaLiga<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Bundesliga<span> (Germany)</span></Link></div>
                    <div className='divli'><Link to="#">Serie A <span> (Italy)</span></Link></div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="7">
                <Accordion.Header>New Zealand</Accordion.Header>
                <Accordion.Body>
                  <div className='linksdiv'>
                    <div className='divli'><Link to="#">World Cup<span> (International)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Nations League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Champions League<span>(International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Europa League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Uefa Europa Conference League <span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">WC Qual, CONMEBOL<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Premier League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">LaLiga<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Bundesliga<span> (Germany)</span></Link></div>
                    <div className='divli'><Link to="#">Serie A <span> (Italy)</span></Link></div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="8">
                <Accordion.Header>All Categories</Accordion.Header>
                <Accordion.Body>
                  <div className='linksdiv'>
                    <div className='divli'><Link to="#">World Cup<span> (International)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Nations League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Champions League<span>(International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">UEFA Europa League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Uefa Europa Conference League <span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">WC Qual, CONMEBOL<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Premier League<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">LaLiga<span> (International Clubs)</span></Link></div>
                    <div className='divli'><Link to="#">Bundesliga<span> (Germany)</span></Link></div>
                    <div className='divli'><Link to="#">Serie A <span> (Italy)</span></Link></div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

            </Accordion>
          </div>
        </section>
      </>
    </>
  )
}

export default ExchangeSidebar