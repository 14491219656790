import React from 'react'
import PlayerList from './PlayerList/PlayerList'

function CreateNewTeam() {
  return (
    <section className="fantacy-section">
        <div className="container-fluid p-0">
          <div className="sectionflex">
            <div className="sidebar-div">
              <div className="first-scroll-div">
             
              <PlayerList/>

              </div>
            </div>
            <div className="contentmain-div ">
              <div className="scroll-second-div">

              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default CreateNewTeam