import React from "react";
import "./Match_Screen.css";
import CountdownTimer from "./CountdownTimer/CountdownTimer";
import Footboll_Pitch from "./Footboll_Pitch/Footboll_Pitch";

function Match_Screen({event}) {
  return (
    <>
      <div className="Match_Screen">
        <div className="screen-text-holder">
          <div className="row">
            <div className="col-md-8">
              <div className="img-text-holder">
                <div className="img-holder">
                  <img
                    className="flag-icon"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/Game_details/Newcastle_flag.png"
                    }
                  />
                </div>
                <p>{event?.opponents?.[0]?.description}</p>
              </div>

              <div className="img-text-holder">
                <div className="img-holder">
                  <img
                    className="flag-icon"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/Game_details/Everton_flag.png"
                    }
                  />
                </div>
                <p>{event?.opponents?.[1]?.description}</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="CountdownTimer-holder">
                <CountdownTimer />
              </div>
            </div>
          </div>

          <Footboll_Pitch/>
        </div>
      </div>
    </>
  );
}

export default Match_Screen;
