import React, { useState } from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import "./AccountDetailsSidebar.css";
import BankName from '../../../../common-components/Modals/BankName/BankName';
const AccountDetailsSidebar = () => {
    const [selectedItem, setSelectedItem] = useState('0');
    const [modalShow, setModalShow] = React.useState(false);
    const handleSelect = (eventKey) => {
        setSelectedItem(eventKey === selectedItem ? null : eventKey);
    };

    const handleRadioChange = (eventKey) => {
        setSelectedItem(eventKey);
    };
    return (
        <>
            <section className='fantacy-sidebar add-btn-sec AccountDetailsSidebar'>
                <div className='sidebar-header'>

                    <Link to="/fantacy-sport/addcash"><FontAwesomeIcon icon="fa-solid fa-arrow-left" className='bar-icon' /></Link>
                    <img className='headlogo' src={process.env.PUBLIC_URL + '/assets/images/logo/logo.png'} alt="Logo" />
                    <button type="button" class="header_btn"><span class="px-1">Add Cash</span> <span className='plus-div'>+</span></button>
                </div>


                <div className='main-bonus-card'>

                    <Accordion activeKey={selectedItem} onSelect={handleSelect}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header className='headerButton'>
                                <div>
                                    <input
                                        type="radio"
                                        id="radio1"
                                        name="accordionRadio"
                                        checked={selectedItem === '0'}
                                        onChange={() => handleRadioChange('0')}
                                        className='me-2'
                                    />
                                    <label className='creditcardtxt' htmlFor="radio1">Credit Card</label>

                                </div>

                            </Accordion.Header>
                            <Accordion.Body>
                                <div className='accountDetailsform'>
                                    <Form>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label> card number</Form.Label>
                                            <Form.Control type="email" placeholder="name@example.com" />
                                        </Form.Group>

                                        <div className='row'>
                                            <div className='row'>
                                                <div className='col-lg-4'>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>Expiry Date</Form.Label>
                                                        <Form.Control type="email" placeholder="MM/YY" />
                                                    </Form.Group>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>CVV</Form.Label>
                                                        <Form.Control type="email" />
                                                    </Form.Group>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>Enter Name</Form.Label>
                                                        <Form.Control type="email" />
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>

                                    </Form>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header className='headerButton'>
                                <div>
                                    <input
                                        type="radio"
                                        id="radio1"
                                        name="accordionRadio"
                                        checked={selectedItem === '1'}
                                        onChange={() => handleRadioChange('1')}
                                        className='me-2'
                                    />
                                    <label className='creditcardtxt' htmlFor="radio1">Debit Card</label>

                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className='accountDetailsform'>
                                    <Form>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>card number</Form.Label>
                                            <Form.Control type="email" placeholder="name@example.com" />
                                        </Form.Group>

                                        <div className='row'>
                                            <div className='row'>
                                                <div className='col-lg-4'>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>Expiry Date</Form.Label>
                                                        <Form.Control type="email" placeholder="name@example.com" />
                                                    </Form.Group>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>CVV</Form.Label>
                                                        <Form.Control type="email" />
                                                    </Form.Group>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>Enter Name</Form.Label>
                                                        <Form.Control type="email" />
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>

                                    </Form>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header className='headerButton'>
                                <div>
                                    <input
                                        type="radio"
                                        id="radio1"
                                        name="accordionRadio"
                                        checked={selectedItem === '3'}
                                        onChange={() => handleRadioChange('3')}
                                        className='me-2'
                                    />
                                    <label className='creditcardtxt' htmlFor="radio1">Net Banking</label>

                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className='accountDetailsform'>
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                        <Row>
                                            <Col sm={5}>
                                                <Nav variant="pills" className="flex-row">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="first">
                                                            <div>
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/fantacy/sbi-logo.png"} className='sbilogoimg' />
                                                                <p>State Bank of London</p>
                                                            </div>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="second">
                                                            <div>
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/fantacy/sbi-logo.png"} className='sbilogoimg' />
                                                                <p>State Bank of London</p>
                                                            </div>
                                                        </Nav.Link>
                                                    </Nav.Item>

                                                    {/* <Nav.Item>
                                                        <Nav.Link eventKey="third">
                                                            <div>
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/fantacy/sbi-logo.png"} className='sbilogoimg' />
                                                                <p>State Bank of London</p>
                                                            </div>
                                                        </Nav.Link>
                                                    </Nav.Item> */}
                                                </Nav>
                                            </Col>
                                            <Col sm={7}>
                                                <Tab.Content className='contentchange' onClick={() => setModalShow(true)}>
                                                    <Tab.Pane className='tabpanescontent' eventKey="first">State Bank of London</Tab.Pane>
                                                    <Tab.Pane className='tabpanescontent' eventKey="second">State Bank of London</Tab.Pane>
                                                    {/* <Tab.Pane className='tabpanescontent' eventKey="third">Third tab content</Tab.Pane> */}
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </div>

                                <BankName
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <div className='btn-div text-center'>
                        <Link>
                            <button type="button" className='next-btn'>CONTINUE</button>

                        </Link>
                    </div>


                </div>
                <div className='payment-method'>
                    <div className='payment-img-div'>
                        <img src={process.env.PUBLIC_URL + '/assets/images/fantacy/paytm.png'} alt="Logo" />
                        <img src={process.env.PUBLIC_URL + '/assets/images/fantacy/visa.png'} alt="Logo" />
                        <img src={process.env.PUBLIC_URL + '/assets/images/fantacy/mastercard.png'} alt="Logo" />
                    </div>

                    <div className='hfgvvfgvfer-bg'>
                        <div className='bottom-text'>
                            <img className='alert-img' src={process.env.PUBLIC_URL + '/assets/images/fantacy/alert.png'} alt="Logo" />
                            <p>You must be 18+ to play.</p>

                        </div>
                        <p>Andhra Pradesh, Telangana, Sikkim, Nagaland and Assam players are not allowed to play cash games.</p>
                    </div>
                </div>
            </section >
        </>
    )
}

export default AccountDetailsSidebar