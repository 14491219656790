import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './LoginModal.css'
import { NavLink } from 'react-router-dom';

const LoginModal = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='login-modal'
            >
                <Modal.Body>
                    <h4>To add a championship, an event or a team to favorites, please log in</h4>
                    <p>
                        If you don`t have an account, please sign up
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <NavLink to="/signup"><button className='signup_btn buttons' onClick={props.onHide}>Sign Up</button></NavLink>
                    <NavLink to="/login"><button className='login_btn buttons' onClick={props.onHide}>Log in</button></NavLink>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default LoginModal