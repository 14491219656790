import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './CreateEarnModal.css'

const CreateEarnModal = (props) => {
  return (
    <section>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="earn-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Terms and Conditions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <p>
          By clicking on ‘I Agree’ button you are accepting to the T&C set by My11circle for hosting a Private contest
          </p>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={props.onHide}>Close</Button> */}
          <button className="later-btn" onClick={props.onHide}> Maybe later</button>
          <button className="agree-btn" onClick={props.onHide}> I Agree</button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default CreateEarnModal;
